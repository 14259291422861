<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row class="fill-height align-center">
          <v-col lg="3" md="3" sm="4" xs="12" class="d-flex justify-center">
            <v-btn
              outlined
              color="primary"
              @click="dialog = true"
              :disabled="profile.role == 'Instructor'"
              v-if="
                profile.role === 'Admin' ||
                profile.role === 'Manager' ||
                profile.role === 'Consultant'
              "
            >
              Schedule New Class
            </v-btn>
          </v-col>
          <v-col lg="1" md="2" sm="4" xs="6" class="d-flex justify-center">
            <v-btn outlined color="grey darken-2" @click="setToday">
              Today
            </v-btn>
          </v-col>
          <v-col lg="2" md="2" sm="4" xs="6" class="d-flex justify-center">
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
          </v-col>
          <v-col lg="2" md="2" sm="6" xs="6" class="d-flex justify-center">
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-col>
          <v-col lg="2" md="2" sm="6" xs="6" class="d-flex justify-center">
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog" persistent max-width="600px"
        ><v-card>
          <v-form @submit.prevent="addEvent" id="class-form" ref="form">
            <v-card-title>
              <span class="headline">New Class</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="ma-1">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="name"
                      label="Class Name"
                      required
                      autocomplete="off"
                      dense
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <!-- <v-text-field
                        v-model="instructor"
                        label="Instructor"
                        required
                        autocomplete="off"
                        dense
                      ></v-text-field> -->
                    <v-autocomplete
                      v-model="instructor"
                      :items="instructorsFromDB"
                      dense
                      label="Instructor"
                      clearable
                      item-text="name"
                      item-value="docID"
                      :rules="nameRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="details"
                      label="Description"
                      required
                      autocomplete="off"
                      dense
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" class="pb-0">
                      <v-text-field
                        v-model="duration"
                        label="Duration"
                        outlined
                        required
                        dense
                      ></v-text-field>
                    </v-col> -->
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :return-value.sync="date1"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="Start Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mt-0"
                          :rules="nameRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date1" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu1 = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="updateStartDate()">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time1"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startTime"
                          label="Start Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mt-0"
                          :rules="nameRules"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu3"
                        v-model="time1"
                        full-width
                        @click:minute="updateStartTime()"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="date2"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="End Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="nameRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date2" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="updateEndDate()">
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-menu
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time2"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endTime"
                          label="End Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="nameRules"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu4"
                        v-model="time2"
                        full-width
                        @click:minute="updateEndTime()"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <!-- <v-text-field
                        v-model="hourRate"
                        label="Hour Rate"
                        autocomplete="off"
                        dense
                      ></v-text-field> -->
                    <v-select
                      v-model="hourRateType"
                      :items="hourRateTypes"
                      label="Select Instructor Hour Rate"
                      required
                      :rules="nameRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-select
                      v-model="selectedGroupName"
                      :items="items"
                      label="Select A Group"
                      @change="onSelectGroup(selectedGroupName)"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" v-if="students.length != 0">
                    <v-row v-for="(student, k) in students" :key="k">
                      <v-col cols="5" sm="5" class="pt-0 pb-0">
                        <v-autocomplete
                          v-model="students[k].name"
                          :items="studentsFromDB"
                          item-text="fullAndEnglishName"
                          item-value="name"
                          clearable
                          label="Student Name"
                          class="ma-0"
                          :rules="nameRules"
                          @change="autoFillDefaultHourFee(students[k].name, k)"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4" sm="4">
                        <v-text-field
                          v-model="students[k].hourFee"
                          label="Student Fee"
                          id="fee-input"
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" sm="1">
                        <v-btn
                          fab
                          outlined
                          x-small
                          elevation="2"
                          color="primary"
                          right
                          v-show="k == students.length - 1"
                          @click="addStudent(k)"
                          class="ml-2"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="1" sm="1">
                        <v-btn
                          fab
                          outlined
                          x-small
                          elevation="2"
                          color="primary"
                          right
                          v-show="k || (!k && students.length > 1)"
                          @click="removeStudent(k)"
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                color="blue darken-1"
                @click="clearClassForm()"
                class="mr-3"
              >
                Close
              </v-btn>
              <v-btn
                outlined
                type="submit"
                color="primary"
                @click="dialog = false"
              >
                Schedule
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-sheet height="900">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="profile.role == 'Instructor' ? instructorViewEvents : events"
          :event-color="getEventColor"
          :type="type"
          :first-interval="7"
          :interval-count="17"
          :weekdays="weekdays"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        >
          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="white" min-width="400px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                @click="deleteEvent(selectedEvent.id)"
                icon
                :disabled="profile.role == 'Instructor'"
                v-if="
                  profile.role === 'Admin' ||
                  profile.role === 'Manager' ||
                  profile.role === 'Consultant'
                "
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <form
                v-if="currentlyEditing !== selectedEvent.id"
                class="d-flex flex-start flex-column"
              >
                <v-row>
                  <v-col cols="3" md="3">
                    <span class="grey--text">Instructor</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <span class="indigo--text text--darken-4">{{
                      selectedEvent.instructor
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" md="3">
                    <span class="grey--text">Details</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <span class="indigo--text text--darken-4">{{
                      selectedEvent.details
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" md="3">
                    <span class="grey--text">Start Time</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <span class="indigo--text text--darken-4">{{
                      selectedEvent.start
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" md="3">
                    <span class="grey--text">End Time</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <span class="indigo--text text--darken-4">{{
                      selectedEvent.end
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" md="3">
                    <span class="grey--text">Duration</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <span class="indigo--text text--darken-4">{{
                      selectedEvent.duration + " hours"
                    }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" md="3">
                    <span class="grey--text">Class Type</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <span class="indigo--text text--darken-4">{{
                      selectedEvent.classType
                    }}</span>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    profile.role === 'Admin' ||
                    profile.role === 'Manager' ||
                    profile.role === 'Consultant'
                  "
                >
                  <v-col cols="3" md="3">
                    <span class="grey--text">Students</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <span
                      class="indigo--text text--darken-4 mr-2"
                      v-for="(student, k) in selectedEvent.students"
                      :key="k"
                      ><span v-if="student.englishName"
                        >{{
                          student.name +
                          " (" +
                          student.englishName +
                          ") " +
                          ": " +
                          student.hourFee
                        }}
                      </span>
                      <span v-else
                        >{{ student.name + " : " + student.hourFee }}
                      </span>
                    </span>
                  </v-col>
                </v-row>
              </form>
              <form v-else class="d-flex flex-start flex-column">
                <v-row class="d-flex flex-row align-center">
                  <v-col cols="3" md="3">
                    <span class="grey--text">Start Date</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <vs-input type="date" v-model="selectedEvent.startDate" />
                  </v-col>
                </v-row>
                <v-row class="d-flex flex-row align-center">
                  <v-col cols="3" md="3">
                    <span class="grey--text">Start Time</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <vs-input type="time" v-model="selectedEvent.startTime" />
                  </v-col>
                </v-row>
                <v-row class="d-flex flex-row align-center">
                  <v-col cols="3" md="3">
                    <span class="grey--text">End Date</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <vs-input type="date" v-model="selectedEvent.endDate" />
                  </v-col>
                </v-row>
                <v-row class="d-flex flex-row align-center">
                  <v-col cols="3" md="3">
                    <span class="grey--text">End Time</span>
                  </v-col>
                  <v-col cols="9" md="9">
                    <vs-input type="time" v-model="selectedEvent.endTime" />
                  </v-col>
                </v-row>
              </form>
            </v-card-text>
            <v-card-actions
              v-if="
                profile.role === 'Admin' ||
                profile.role === 'Manager' ||
                profile.role === 'Consultant'
              "
            >
              <v-btn text color="secondary" @click="cancelEvent()">
                Cancel
              </v-btn>
              <v-btn
                text
                :color="selectedEvent.color"
                v-if="currentlyEditing !== selectedEvent.id"
                @click.prevent="editEvent(selectedEvent)"
                :disabled="profile.role == 'Instructor'"
              >
                Edit
              </v-btn>
              <v-btn
                text
                :color="selectedEvent.color"
                v-else
                @click.prevent="updateEvent(selectedEvent)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-card>
  </v-container>
</template>

<script>
import { db } from "../plugins/firebase.js";
import firebase from "firebase/app";
export default {
  name: "Calendar",
  data: () => ({
    focus: "",
    type: "week",
    typeToLabel: {
      month: "month",
      week: "week",
      day: "day",
    },
    ready: false,
    name: null,
    details: null,
    start: null,
    end: null,
    startTime: null,
    endTime: null,
    startDate: null,
    endDate: null,
    duration: null,
    hourRateType: null,
    instructor: null,
    instructorNames: [],
    students: [
      {
        studentName: "",
        fee: "",
      },
    ],
    studentsFromDB: [],
    instructorsFromDB: [],
    currentlyEditing: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    instructorViewEvents: [],
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    colors: [
      "light-blue lighten-2",
      "deep-purple lighten-2",
      "pink lighten-2",
      "green lighten-2",
      "purple lighten-2",
      "deep-purple lighten-1",
      "indigo lighten-1",
      "indigo lighten-2",
      "blue lighten-1",
      "light-blue lighten-1",
      "light-blue lighten-2",
      "cyan lighten-1",
      "teal lighten-1",
      "teal lighten-4",
      "green lighten-1",
      "light-green lighten-1",
      "lime lighten-1",
      "yellow lighten-1",
      "orange lighten-2",
      "brown lighten-3",
      "blue-grey lighten-4",
      "grey lighten-2",
      "red darken-1",
    ],
    dialog: false,
    date1: new Date().toISOString().substr(0, 10),
    menu1: false,
    date2: new Date().toISOString().substr(0, 10),
    menu2: false,
    time1: null,
    menu3: false,
    time2: null,
    menu4: false,
    selectedGroupName: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    groups: [],
    profile: {
      firstName: null,
      lastName: null,
      role: null,
    },
    hourRateTypes: ["Default Rate", "Small Group", "Large Group"],
    nameRules: [(v) => !!v || "Required"],
    timeDiff: 259200000,
  }),
  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
  },
  mounted() {
    this.getInstructors();
    this.getEvents();
    this.$refs.calendar.checkChange();
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
    this.getClassrooms();
    this.getStudents();
    // this.getProfile();
  },
  methods: {
    async getEvents() {
      let snapshot = await db.collection("classes").get();
      let events = [];
      snapshot.forEach((doc) => {
        let eventData = {};
        eventData.id = doc.id;
        eventData.name = doc.data().className;
        eventData.details = doc.data().description;
        eventData.start = doc.data().startTime;
        eventData.startDate = doc.data().startTime.substr(0, 10);
        eventData.startTime = doc.data().startTime.substr(11);
        eventData.end = doc.data().endTime;
        eventData.endDate = doc.data().endTime.substr(0, 10);
        eventData.endTime = doc.data().endTime.substr(11);
        // eventData.color = this.colors[this.rnd(0, this.colors.length - 1)];
        let instructorByID = this.getInsutructorByID(
          doc.data().instructorDocID
        );
        // console.log("instructorByID ", instructorByID);
        if (instructorByID) {
          eventData.color = instructorByID.color;
        }
        eventData.instructor = doc.data().instructor;
        eventData.duration = doc.data().duration;
        eventData.classType = doc.data().classType;
        eventData.students = doc.data().students;
        eventData.createdBy = doc.data().createdBy;
        if (instructorByID) {
          events.push(eventData);
        }
      });

      // get current user profile
      const user = firebase.auth().currentUser;
      let docRef = await db.collection("profiles").doc(user.uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.profile = doc.data();
            // console.log("profile ", this.profile);
            let name = this.profile.firstName + " " + this.profile.lastName;
            // if role is Instructor, then assign filter events to instructor view
            if (this.profile.role === "Instructor") {
              let classArray = events.filter((singleClass) => {
                return singleClass.instructor === name;
              });
              this.instructorViewEvents = classArray;
              // console.log("class for ", name, " : ", this.instructorViewEvents);
            } else {
              // if other roles, then get all events
              this.events = events;
              // console.log("all events: ", this.events);
            }
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    async getClassrooms() {
      let snapshot = await db.collection("classrooms").get();
      let groupsArray = [];
      let itemsArray = [];
      snapshot.forEach((doc) => {
        let group = {};
        group.docID = doc.id;
        group.name = doc.data().groupName;
        itemsArray.push(group.name);
        group.students = doc.data().students;
        groupsArray.push(group);
      });
      // // console.log("groupsArray", groupsArray);
      this.groups = groupsArray;
      this.items = itemsArray;
    },
    async getStudents() {
      let snapshot = await db.collection("students").get();
      let studentsArray = [];
      snapshot.forEach((doc) => {
        if (doc.data().registrationStatus) {
          let student = {};
          student.docID = doc.id;
          student.name = doc.data().studentName;
          if (doc.data().englishName) {
            student.fullAndEnglishName =
              student.name + " (" + doc.data().englishName + ")";
          } else {
            student.fullAndEnglishName = student.name;
          }
          student.englishName = doc.data().englishName
            ? doc.data().englishName
            : "";
          // student.email = doc.data().email;
          // student.phone = doc.data().phone;
          student.hourFee = doc.data().hourFee;
          student.classRecords = doc.data().classRecords;
          student.balance = doc.data().balance;
          studentsArray.push(student);
        }
      });
      this.studentsFromDB = studentsArray;
      // console.log("students from db:", this.studentsFromDB);
    },
    async getInstructors() {
      let snapshot = await db.collection("instructors").get();
      let instructorsArray = [];
      let instructorNamesArray = [];
      snapshot.forEach((doc) => {
        if (doc.data().registrationStatus) {
          let instructor = {};
          instructor.docID = doc.id;
          instructor.name = doc.data().instructorName;
          instructorNamesArray.push(instructor.name);
          // instructor.email = doc.data().email;
          // instructor.phone = doc.data().phone;
          // instructor.hourFee = doc.data().hourFee;
          instructor.defaultRate = doc.data().defaultRate;
          instructor.smallGroup = doc.data().smallGroup;
          instructor.largeGroup = doc.data().largeGroup;
          instructor.classRecords = doc.data().classRecords;
          instructor.salary = doc.data().salary;
          instructor.color = doc.data().color;
          instructorsArray.push(instructor);
        }
      });
      this.instructorNames = instructorNamesArray;
      this.instructorsFromDB = instructorsArray;
      // console.log("instructors from db:", this.instructorsFromDB);
    },
    // async getProfile() {
    //   const user = firebase.auth().currentUser;
    //   let docRef = await db.collection("profiles").doc(user.uid);
    //   docRef
    //     .get()
    //     .then((doc) => {
    //       if (doc.exists) {
    //         this.profile = doc.data();
    //         // console.log("Document data:", doc.data());
    //       } else {
    //         console.log("No such document!");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("Error getting document:", error);
    //     });
    // },
    getHourDiff(startDate, startTime, endDate, endTime) {
      let starttt = new Date(startDate + " " + startTime);
      let enddd = new Date(endDate + " " + endTime);
      let secondDiff = Math.abs(starttt.getTime() - enddd.getTime()) / 1000;
      let hourDiff = secondDiff / 3600;
      return hourDiff;
    },
    getInsutructorByName() {
      let instructorByName = this.instructorsFromDB.find((ins) => {
        return ins.name === this.instructor;
      });
      return instructorByName;
    },
    getInsutructorByID(instructorDocID) {
      let instructorByID = this.instructorsFromDB.find((ins) => {
        return ins.docID === instructorDocID;
      });
      return instructorByID;
    },
    getStudentsFromInput() {
      let finalStudents = [];
      for (var i = 0; i < this.students.length; i++) {
        if (this.students[i].docID) {
          let filteredStudentByID = this.studentsFromDB.find((obj) => {
            return obj.docID === this.students[i].docID;
          });
          if (filteredStudentByID) {
            if (this.students[i].hourFee) {
              filteredStudentByID.hourFee = this.students[i].hourFee;
            }
            finalStudents.push(filteredStudentByID);
          } else {
            alert(
              this.students[i].name +
                " is not found. \nSo " +
                this.students[i].name +
                " is skipped."
            );
          }
        } else {
          let filteredStudentByName = this.studentsFromDB.find((obj) => {
            return obj.name === this.students[i].name;
          });
          if (filteredStudentByName) {
            if (this.students[i].hourFee) {
              filteredStudentByName.hourFee = this.students[i].hourFee;
            }
            finalStudents.push(filteredStudentByName);
          } else {
            alert(
              this.students[i].name +
                " is not found. \nSo " +
                this.students[i].name +
                " is skipped."
            );
          }
        }
      }
      // console.log("finalStudents ", finalStudents);
      let stduentsForNewClass = [];
      for (var j = 0; j < finalStudents.length; j++) {
        let studentForNewClass = {
          docID: finalStudents[j].docID,
          name: finalStudents[j].name,
          englishName: finalStudents[j].englishName,
          hourFee: finalStudents[j].hourFee,
        };
        stduentsForNewClass.push(studentForNewClass);
      }
      let studentsList = {
        finalStudents: finalStudents,
        stduentsForNewClass: stduentsForNewClass,
      };
      // console.log("studentsList ", studentsList);
      return studentsList;
    },
    async updateInstructorClassRecordsAndSalary(
      instructor,
      classDoc,
      stduentsForNewClass,
      hourDiff,
      name,
      hourRate,
      startDate,
      startTime,
      endDate,
      endTime
    ) {
      // compute instructor fee for newly added class
      // let classFee = instructor.hourFee * hourDiff;
      let classFee = hourRate * hourDiff;
      // console.log("updateInstructorClassRecordsAndSalary class fee ", classFee);
      let classRecordsArray = [];
      // get latest classRecords
      if (instructor.classRecords) {
        classRecordsArray = instructor.classRecords;
      }
      // console.log(
      //   "updateInstructorClassRecordsAndSalary classarray1 ",
      //   classRecordsArray
      // );
      // create class record object for instructor
      let classRecordForInstructor = {
        className: name,
        classDocID: classDoc.id,
        startTime: startDate + " " + startTime,
        endTime: endDate + " " + endTime,
        duration: hourDiff,
        students: stduentsForNewClass,
        createTime: new Date().toString(),
      };
      classRecordsArray.push(classRecordForInstructor);
      // console.log(
      //   "updateInstructorClassRecordsAndSalary classarray2 ",
      //   classRecordsArray
      // );
      let instructorSalary = instructor.salary + classFee;
      // console.log(
      //   "updateInstructorClassRecordsAndSalary instructorSalary ",
      //   instructorSalary
      // );
      // update classRecords and salary for instructor
      await db.collection("instructors").doc(instructor.docID).update({
        classRecords: classRecordsArray,
        salary: instructorSalary,
      });
      // this.getInstructors();
    },
    async updateStudentClassRecordsAndBalance(
      finalStudents,
      hourDiff,
      classDoc,
      instructor,
      name,
      startDate,
      startTime,
      endDate,
      endTime
    ) {
      // traverse all students in finalStudents after filtering from inputs
      for (var i = 0; i < finalStudents.length; i++) {
        let classFee = finalStudents[i].hourFee * hourDiff;
        let classRecordsArray = [];
        // get latest classRecords for student
        if (finalStudents[i].classRecords) {
          classRecordsArray = finalStudents[i].classRecords;
        }
        // console.log(
        //   "updateStudentClassRecordsAndBalance classarray1 ",
        //   classRecordsArray
        // );
        // create class record object for student
        let classRecordForStudent = {
          className: name,
          classDocID: classDoc.id,
          startTime: startDate + " " + startTime,
          endTime: endDate + " " + endTime,
          duration: hourDiff,
          instructor: instructor.name,
          instructorDocID: instructor.docID,
          createTime: new Date().toString(),
        };
        classRecordsArray.push(classRecordForStudent);
        // console.log(
        //   "updateStudentClassRecordsAndBalance classarray2 ",
        //   classRecordsArray
        // );
        // compute student balance
        let studentBalance = finalStudents[i].balance - classFee;
        // update classRecords and salary for instructor
        await db.collection("students").doc(finalStudents[i].docID).update({
          classRecords: classRecordsArray,
          balance: studentBalance,
        });
        // this.getStudents();
      }
    },
    async addBalanceRecordsForInstructor(
      instructor,
      hourDiff,
      hourRate,
      classDoc,
      name
    ) {
      // get instructor balance record by id
      let instructorBalanceRecord = await db
        .collection("instructorBalanceRecords")
        .doc(instructor.docID)
        .get();
      let instructorBalanceRecordArray = [];
      // let classFee = instructor.hourFee * hourDiff;
      let classFee = hourRate * hourDiff;
      // get latest instructorBalanceRecords
      if (
        instructorBalanceRecord.data() &&
        instructorBalanceRecord.data().instructorBalanceRecords
      ) {
        instructorBalanceRecordArray =
          instructorBalanceRecord.data().instructorBalanceRecords;
      }
      // console.log(
      //   "addBalanceRecordsForInstructor instructorBalanceRecord1 ",
      //   instructorBalanceRecord
      // );
      // create balance record object for instructor
      let balanceRecordForInstructor = {
        createTime: new Date().toString(),
        amount: classFee,
        detail: name + " scheduled",
        instructorName: instructor.name,
        classDocID: classDoc.id,
        paymentMethod: "N/A",
        createdBy: this.profile.firstName + " " + this.profile.lastName,
      };
      instructorBalanceRecordArray.push(balanceRecordForInstructor);
      // console.log(
      //   "addBalanceRecordsForInstructor instructorBalanceRecord2 ",
      //   instructorBalanceRecord
      // );
      // console.log(
      //   "instructorBalanceRecordArray ",
      //   instructorBalanceRecordArray
      // );
      // create instructor balanceRecord
      await db
        .collection("instructorBalanceRecords")
        .doc(instructor.docID)
        .set({ instructorBalanceRecords: instructorBalanceRecordArray });
      this.getInstructors();
    },
    async addBalanceRecordsForStudents(
      finalStudents,
      hourDiff,
      classDoc,
      name
    ) {
      // traverse all students in finalStudents after filtering from inputs
      for (var i = 0; i < finalStudents.length; i++) {
        // get student balance record by id
        let studentBalanceRecord = await db
          .collection("studentBalanceRecords")
          .doc(finalStudents[i].docID)
          .get();
        // // console.log("studentBalanceRecord ", studentBalanceRecord);
        let studentBalanceRecordArray = [];
        let classFee = finalStudents[i].hourFee * hourDiff;
        // get latest instructorBalanceRecords
        if (
          studentBalanceRecord.data() &&
          studentBalanceRecord.data().studentBalanceRecords
        ) {
          studentBalanceRecordArray =
            studentBalanceRecord.data().studentBalanceRecords;
        }
        // console.log(
        //   "addBalanceRecordsForStudents studentBalanceRecord1 ",
        //   studentBalanceRecord
        // );
        // create balance record object for instructor
        let balanceRecordForStudent = {
          createTime: new Date().toString(),
          amount: -classFee,
          detail: name + " scheduled",
          studentName: finalStudents[i].name,
          classDocID: classDoc.id,
          paymentMethod: "N/A",
          createdBy: this.profile.firstName + " " + this.profile.lastName,
        };
        studentBalanceRecordArray.push(balanceRecordForStudent);
        // console.log(
        //   "addBalanceRecordsForStudents studentBalanceRecord2 ",
        //   studentBalanceRecord
        // );
        // create instructor balanceRecord
        await db
          .collection("studentBalanceRecords")
          .doc(finalStudents[i].docID)
          .set({ studentBalanceRecords: studentBalanceRecordArray });
        this.getStudents();
      }
    },
    async addEvent() {
      if (
        this.name &&
        this.startDate &&
        this.endDate &&
        this.startTime &&
        this.endTime &&
        this.instructor &&
        this.students &&
        this.hourRateType
      ) {
        // console.log("addEvent instructor", this.instructor);
        // get hourDiff
        let hourDiff = this.getHourDiff(
          this.startDate,
          this.startTime,
          this.endDate,
          this.endTime
        );
        // get instructor by name
        let instructorByID = this.getInsutructorByID(this.instructor);
        // console.log("addEvent instructorByID ", instructorByID);
        // get students from input
        let studentsFromInput = this.getStudentsFromInput();
        // console.log("addEvent input students ", studentsFromInput);
        let stduentsForNewClass = studentsFromInput.stduentsForNewClass;
        // console.log("addEvent students for new class ", stduentsForNewClass);
        let finalStudents = studentsFromInput.finalStudents;
        // console.log("addEvent final students ", finalStudents);
        if (instructorByID) {
          // console.log("addEvent instructorByID ", instructorByID);
          // console.log("addEvent hourrate type ", this.hourRateType);
          let hourRate = null;
          if (this.hourRateType === "Default Rate") {
            hourRate = instructorByID.defaultRate;
          } else if (this.hourRateType === "Small Group") {
            hourRate = instructorByID.smallGroup;
          } else {
            hourRate = instructorByID.largeGroup;
          }
          // console.log("addEvent hourate ", hourRate);
          // add new class
          let classDoc = await db.collection("classes").add({
            className: this.name,
            description: this.details,
            startTime: this.startDate + " " + this.startTime,
            endTime: this.endDate + " " + this.endTime,
            duration: hourDiff,
            classType: this.hourRateType,
            instructor: instructorByID.name,
            instructorDocID: instructorByID.docID,
            students: stduentsForNewClass,
            createTime: new Date().toString(),
          });
          // console.log("addEvent classdoc", classDoc.id);
          // update classRecords for instructor and pay instructor
          await this.updateInstructorClassRecordsAndSalary(
            instructorByID,
            classDoc,
            stduentsForNewClass,
            hourDiff,
            this.name,
            hourRate,
            this.startDate,
            this.startTime,
            this.endDate,
            this.endTime
          );
          await this.addBalanceRecordsForInstructor(
            instructorByID,
            hourDiff,
            hourRate,
            classDoc,
            this.name
          );
          await this.updateStudentClassRecordsAndBalance(
            finalStudents,
            hourDiff,
            classDoc,
            instructorByID,
            this.name,
            this.startDate,
            this.startTime,
            this.endDate,
            this.endTime
          );
          await this.addBalanceRecordsForStudents(
            finalStudents,
            hourDiff,
            classDoc,
            this.name
          );
        } else {
          alert(this.instructor + " is not found.");
        }
      } else {
        alert(
          "You must enter event name, instructor, students, start, and end time"
        );
      }
      // console.log("addEvents this students ", this.students);
      // (this.name = ""),
      //   (this.details = ""),
      //   (this.startDate = ""),
      //   (this.endDate = ""),
      //   (this.startTime = ""),
      //   (this.endTime = ""),
      //   (this.instructor = ""),
      //   (this.hourRateType = ""),
      //   (this.selectedGroupName = null),
      //   (this.students = [
      //     {
      //       studentName: "",
      //       fee: "",
      //     },
      //   ]);
      this.getEvents();
    },
    clearClassForm() {
      (this.name = ""),
        (this.details = ""),
        (this.startDate = ""),
        (this.endDate = ""),
        (this.startTime = ""),
        (this.endTime = ""),
        (this.instructor = ""),
        (this.hourRateType = ""),
        (this.selectedGroupName = null),
        (this.students = [
          {
            studentName: "",
            fee: "",
          },
        ]);
      this.dialog = false;
      this.$refs.form.reset();
    },
    async updateEvent(ev) {
      // // console.log("ev ", ev);
      // get modifed time
      let startTime = ev.startDate + " " + ev.startTime;
      let endTime = ev.endDate + " " + ev.endTime;
      // compute hour difference of modified time
      let hourDiff = this.getHourDiff(
        ev.startDate,
        ev.startTime,
        ev.endDate,
        ev.endTime
      );
      // console.log("updateEvent duration ", hourDiff);
      // get class document before delete the doc
      let classDoc = await db
        .collection("classes")
        .doc(this.currentlyEditing)
        .get();
      // update local class document data
      let classData = classDoc.data();
      //  make sure the past classes cannot be modified
      let currentTime = new Date();
      let diff = Math.abs(new Date(classData.startTime) - currentTime);
      // if (currentTime < new Date(classData.startTime) || diff < this.timeDiff) {
      // // if (currentTime > new Date(classData.startTime)) {
      //   alert(
      //     "This class was already over 72 hours. \nYou cannot modify its start time and end time. "
      //   );
      //   this.getEvents();
      // }
      if (currentTime < new Date(classData.startTime) || diff < this.timeDiff) {
        classData.duration = hourDiff;
        classData.startTime = startTime;
        classData.endTime = endTime;
        classData.updateTime = new Date().toString();
        // console.log("updateEvent classdata ", classData);
        // console.log("debug0");
        // delete this class
        await this.deleteEvent(this.currentlyEditing);
        // get instructor of the class
        let instructorByID = this.getInsutructorByID(classData.instructorDocID);
        // console.log("updateEvent instructorByID ", instructorByID);
        // get hourRate based on classType
        let hourRate = null;
        if (classData.classType === "Default Rate") {
          hourRate = instructorByID.defaultRate;
        } else if (classData.classType === "Small Group") {
          hourRate = instructorByID.smallGroup;
        } else {
          hourRate = instructorByID.largeGroup;
        }
        // console.log("updateEvent hour rate ", hourRate);
        // get final students from the class
        let finalStudents = [];
        for (var i = 0; i < classData.students.length; i++) {
          let studentByID = this.studentsFromDB.find((obj) => {
            return obj.docID === classData.students[i].docID;
          });
          studentByID.hourFee = classData.students[i].hourFee;
          finalStudents.push(studentByID);
        }
        // console.log("updateEvent final students ", finalStudents);
        // add this class with modified information
        let newClassDoc = await db.collection("classes").add(classData);
        await this.updateInstructorClassRecordsAndSalary(
          instructorByID,
          newClassDoc,
          classData.students,
          hourDiff,
          classData.className,
          hourRate,
          ev.startDate,
          ev.startTime,
          ev.endDate,
          ev.endTime
        );
        await this.addBalanceRecordsForInstructor(
          instructorByID,
          hourDiff,
          hourRate,
          newClassDoc,
          classData.className
        );
        await this.updateStudentClassRecordsAndBalance(
          finalStudents,
          hourDiff,
          newClassDoc,
          instructorByID,
          classData.className,
          ev.startDate,
          ev.startTime,
          ev.endDate,
          ev.endTime
        );
        await this.addBalanceRecordsForStudents(
          finalStudents,
          hourDiff,
          newClassDoc,
          classData.className
        );
        this.selectedOpen = false;
        this.currentlyEditing = null;
        this.getEvents();
      } else {
        alert(
          "This class was already over 72 hours. \nYou cannot modify its start time and end time. "
        );
        this.getEvents();
      }
    },
    async deleteEvent(ev) {
      // console.log("debug1");
      // get class by id
      let classDoc = await db.collection("classes").doc(ev).get();
      let classDocID = classDoc.id;
      let classData = classDoc.data();
      // console.log("deleteEvent class info ", classData);
      if (classDoc && classData.startTime) {
        let currentTime = new Date();
        let startTime = new Date(classData.startTime);
        let diff = Math.abs(startTime - currentTime);
        // console.log("deleteEvent class id ", classDocID);
        // class does not start yet
        if (startTime > currentTime || diff < this.timeDiff) {
          // if (startTime > currentTime) {
          try {
            // instructor gets charged back and this classrecord gets removed for instructor
            await this.removeClassRecordAndChargeInstructor(
              classData,
              classDocID
            );
            // this balance record gets removed for instructor
            await this.removeBalanceRecordForInstructor(classData, classDocID);
            // students get refund back and this classrecord gets removed for students
            await this.removeClassRecordAndRefundStudents(
              classData,
              classDocID
            );
            // this balance record gets removed for students
            await this.removeBalanceRecordForStudents(classData, classDocID);
          } catch (error) {
            console.log(
              "Error happens since instructor or students that registered for this class has been deleted, but this class will still be deleted."
            );
            // console.log("The error: ", error);
          }
        } else {
          alert(
            "The class was already over 72 hours and this class will be deleted, but it will not affect payment history."
          );
        }
      }
      await db.collection("classes").doc(ev).delete();
      this.selectedOpen = false;
      this.getEvents();
    },
    async removeClassRecordAndChargeInstructor(classData, classDocID) {
      // get instructor for the class to be deleted
      let instructorDoc = await db
        .collection("instructors")
        .doc(classData.instructorDocID)
        .get();
      // console.log("instructor data ", instructorDoc.data());
      // get classRecords for this instructor
      let classRecordsArray = [];
      let filteredClassRecordsArray = [];
      // make sure instructor exits
      if (instructorDoc) {
        classRecordsArray = instructorDoc.data().classRecords;
        // console.log(
        //   "removeClassRecordAndChargeInstructor classRecordsArray ",
        //   classRecordsArray
        // );
        // filter out the class to be deleted by classDocID
        filteredClassRecordsArray = classRecordsArray.filter((cls) => {
          return cls.classDocID != classDocID;
        });
        // console.log(
        //   "removeClassRecordAndChargeInstructor filteredClassRecordsArray ",
        //   filteredClassRecordsArray
        // );
        // compute class fee for instructor
        let hourFee = null;
        if (classData.classType === "Default Rate") {
          hourFee = instructorDoc.data().defaultRate;
        } else if (classData.classType === "Small Group") {
          hourFee = instructorDoc.data().smallGroup;
        } else {
          hourFee = instructorDoc.data().largeGroup;
        }
        let classFee = hourFee * classData.duration;
        let newSalary = instructorDoc.data().salary - classFee;
        // console.log(
        //   "removeClassRecordAndChargeInstructor newSalary ",
        //   newSalary
        // );
        // update classRecords and charge back instructor classFee
        await db
          .collection("instructors")
          .doc(classData.instructorDocID)
          .update({
            classRecords: filteredClassRecordsArray,
            salary: newSalary,
          });
        this.getInstructors();
      } else {
        alert("Cannot find document for " + classData.instructor);
      }
    },
    async removeBalanceRecordForInstructor(classData, classDocID) {
      // get instructor balance record by id using class to be deleted
      let instructorBalanceRecord = await db
        .collection("instructorBalanceRecords")
        .doc(classData.instructorDocID)
        .get();
      // get balanceRecords for this instructor
      let instructorBalanceRecordsArray = [];
      // make sure instructorBalanceRecord doc exits
      if (instructorBalanceRecord) {
        // console.log(
        //   "removeBalanceRecordForInstructor instructorBalanceRecord ",
        //   instructorBalanceRecord.data()
        // );
        instructorBalanceRecordsArray =
          instructorBalanceRecord.data().instructorBalanceRecords;
        // console.log(
        //   "removeBalanceRecordForInstructor instructorBalanceRecordArray ",
        //   instructorBalanceRecordsArray
        // );
        // filter out the balanceRecord to be deleted by classDocID
        let filteredInstructorBalanceRecordArray =
          instructorBalanceRecordsArray.filter((clsRecord) => {
            return clsRecord.classDocID != classDocID;
          });
        // console.log(
        //   "removeBalanceRecordForInstructor filteredInstructorBalanceRecordArray ",
        //   filteredInstructorBalanceRecordArray
        // );
        // update balanceRecords for instructor
        await db
          .collection("instructorBalanceRecords")
          .doc(classData.instructorDocID)
          .update({
            instructorBalanceRecords: filteredInstructorBalanceRecordArray,
          });
        // this.getInstructors();
      } else {
        alert("Cannot find balanceRecords for ", classData.instructor);
      }
    },
    async removeClassRecordAndRefundStudents(classData, classDocID) {
      // get students from the class to be deleted
      let studentList = classData.students;
      // console.log(
      //   "removeClassRecordAndRefundStudents studentList ",
      //   studentList
      // );
      for (var i = 0; i < studentList.length; i++) {
        // get student from DB by id
        let studentFromDB = await db
          .collection("students")
          .doc(studentList[i].docID)
          .get();
        // get classRecords for this instructor
        let classRecordsArray = [];
        let filteredClassRecordsArray = [];
        // make sure instructor exits
        if (studentFromDB) {
          classRecordsArray = studentFromDB.data().classRecords;
          // console.log(
          //   "removeClassRecordAndRefundStudents classRecordsArray ",
          //   classRecordsArray
          // );
          // filter out the class to be deleted by classDocID
          filteredClassRecordsArray = classRecordsArray.filter((cls) => {
            return cls.classDocID != classDocID;
          });
          // console.log(
          //   "removeClassRecordAndRefundStudents filteredClassRecordsArray ",
          //   filteredClassRecordsArray
          // );
          // compute new balance for student
          let classFee = classData.duration * studentList[i].hourFee;
          let newBalance = studentFromDB.data().balance + classFee;
          // console.log(
          //   "removeClassRecordAndRefundStudents newBalance ",
          //   newBalance
          // );
          // update classRecords and charge back instructor classFee
          await db.collection("students").doc(studentList[i].docID).update({
            classRecords: filteredClassRecordsArray,
            balance: newBalance,
          });
          this.getStudents();
        } else {
          alert(
            studentList[i].name +
              " document cannot find. \n" +
              studentList[i].name +
              " gets skipped."
          );
          continue;
        }
      }
    },
    async removeBalanceRecordForStudents(classData, classDocID) {
      // get students from the class to be deleted
      let studentList = classData.students;
      // console.log("removeBalanceRecordForStudents studentList ", studentList);
      for (var i = 0; i < studentList.length; i++) {
        // get student balance record by id in studentList
        let studentBalanceRecord = await db
          .collection("studentBalanceRecords")
          .doc(studentList[i].docID)
          .get();
        // get balanceRecords for this student
        let studentBalanceRecordsArray = [];
        // make sure this student balance record exits
        if (studentBalanceRecord) {
          // console.log(
          //   "removeBalanceRecordForStudents studentBalanceRecord ",
          //   studentBalanceRecord.data()
          // );
          studentBalanceRecordsArray =
            studentBalanceRecord.data().studentBalanceRecords;
          // console.log(
          //   "removeBalanceRecordForStudents studentBalanceRecordsArray ",
          //   studentBalanceRecordsArray
          // );
          // filter out the balanceRecord to be deleted by classDocID
          let filteredStudentBalanceRecordsArray =
            studentBalanceRecordsArray.filter((clsRecord) => {
              return clsRecord.classDocID != classDocID;
            });
          // console.log(
          //   "removeBalanceRecordForStudents filteredStudentBalanceRecordsArray ",
          //   filteredStudentBalanceRecordsArray
          // );
          // update balanceRecords for student
          await db
            .collection("studentBalanceRecords")
            .doc(studentList[i].docID)
            .update({
              studentBalanceRecords: filteredStudentBalanceRecordsArray,
            });
          // this.getStudents();
        } else {
          alert(
            studentList[i].name +
              " balance record document cannot find. \n" +
              studentList[i].name +
              " gets skipped."
          );
          continue;
        }
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    cancelEvent() {
      this.selectedOpen = false;
      this.currentlyEditing = null;
    },
    editEvent(ev) {
      // console.log(ev);
      // console.log(ev.id);
      this.currentlyEditing = ev.id;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        // // console.log(event.id);
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    addStudent() {
      this.students.push({ studentName: "", fee: "" });
      // console.log(this.students);
    },
    removeStudent(index) {
      this.students.splice(index, 1);
      // console.log(this.students);
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    updateStartDate() {
      this.$refs.menu1.save(this.date1);
      this.startDate = this.date1;
      // console.log(this.startDate);
      this.endDate = this.startDate;
      // console.log("enddate is the same? ", this.endDate);
    },
    updateEndDate() {
      this.$refs.menu2.save(this.date2);
      this.endDate = this.date2;
      // console.log(this.endDate);
    },
    updateStartTime() {
      this.$refs.menu3.save(this.time1);
      this.startTime = this.time1;
      // console.log(this.startTime);
    },
    updateEndTime() {
      this.$refs.menu4.save(this.time2);
      this.endTime = this.time2;
      // console.log(this.endTime);
    },
    onSelectGroup(selectedGroupName) {
      // console.log(selectedGroupName, " is selected");
      let selectedGroup = this.groups.find((group) => {
        return group.name === selectedGroupName;
      });
      // console.log("selected group is ", selectedGroup);
      // console.log("selected students are ", selectedGroup.students);
      this.students = selectedGroup.students;
    },
    autoFillDefaultHourFee(name, k) {
      let studentByName = this.studentsFromDB.find((student) => {
        return student.name === name;
      });
      this.students[k].hourFee = studentByName.hourFee;
    },
    // getInstructorView() {
    //   let name = this.profile.firstName + " " + this.profile.lastName;
    //   if (this.profile.role === "Instructor") {
    //     let classArray = this.events.filter((singleClass) => {
    //       return singleClass.instructor === name;
    //     });
    //     this.instructorViewEvents = classArray;
    //     console.log("class for ", name, " : ", this.instructorViewEvents);
    //   }
    // },
  },
};
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

#class-form .v-text-field__details {
  margin-bottom: 0;
}
</style>

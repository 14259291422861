<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row class="align-center">
          <v-col cols="12" lg="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="pa-0 mt-0"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="6"
            xs="6"
            class="d-flex justify-center"
          >
            <v-dialog v-model="dialog" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  New Class Feedback
                </v-btn>
              </template>
              <v-card>
                <v-card-title> Class Feedback </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="log.student"
                          label="Student Name*"
                          persistent-hint
                          outlined
                          required
                          :rules="nameRules"
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="log.instructor"
                          label="Instructor Name*"
                          outlined
                          required
                          persistent-hint
                          :rules="nameRules"
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="log.className"
                          label="Class Name*"
                          persistent-hint
                          outlined
                          required
                          :rules="nameRules"
                          autocomplete="off"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="log.classDate"
                              label="Date of Class*"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              required
                              :rules="nameRules"
                              autocomplete="off"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="log.classDate"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="12" class="pl-0 pr-0">
                        <template>
                          <v-container fluid>
                            <v-textarea
                              v-model="log.lectureContent"
                              outlined
                              clearable
                              clear-icon="mdi-close-circle"
                              name="input-7-2"
                              label="Lecture Content*"
                              auto-grow
                              :rules="nameRules"
                              autocomplete="off"
                            ></v-textarea>
                          </v-container>
                        </template>
                      </v-col>
                      <v-col cols="12" sm="12" class="pl-0 pr-0">
                        <template>
                          <v-container fluid>
                            <v-textarea
                              v-model="log.studentPerformance"
                              outlined
                              clearable
                              clear-icon="mdi-close-circle"
                              name="input-7-2"
                              label="Student Performance*"
                              auto-grow
                              :rules="nameRules"
                              autocomplete="off"
                            ></v-textarea>
                          </v-container>
                        </template>
                      </v-col>
                      <v-col cols="12" sm="12" class="pl-0 pr-0">
                        <template>
                          <v-container fluid>
                            <v-textarea
                              v-model="log.assignmentFeedback"
                              outlined
                              clearable
                              clear-icon="mdi-close-circle"
                              name="input-7-2"
                              label="Assignment Feedback*"
                              auto-grow
                              :rules="nameRules"
                              autocomplete="off"
                            ></v-textarea>
                          </v-container>
                        </template>
                      </v-col>
                    </v-row>
                    <small>*indicates required field</small>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="addLog">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            lg="4"
            md="4"
            sm="6"
            xs="6"
            class="d-flex justify-center"
          >
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="deleteSelectedLogs"
              style="width: 204.61px"
            >
              Delete Selected
            </v-btn>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteLogConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeleteSelected" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDeleteSelected"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteSelectedLogsConfirmed"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="logHeaders"
        :search="search"
        :items="logs"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon>
            <router-link :to="{ name: 'logPage', query: { id: item.id } }"
              ><v-icon small class="mr-2"> mdi-pencil </v-icon></router-link
            >
          </v-btn>
          <v-btn icon>
            <v-icon color="primary" small @click="deleteLog(item)">
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { db } from "../plugins/firebase.js";
import firebase from "firebase/app";
export default {
  data: () => ({
    search: "",
    attrs: "",
    on: "",
    selected: [],
    dialog: false,
    dialogDelete: false,
    dialogDeleteSelected: false,
    dialogEdit: false,
    menu: false,
    modal: false,
    menu2: false,
    logHeaders: [
      {
        text: "Instructor",
        align: "start",
        value: "instructor",
      },
      { text: "Student", value: "student" },
      { text: "Class", value: "className" },
      { text: "Date", value: "classDate" },
      { text: "Actions", value: "actions", filterable: false, sortable: false },
    ],

    log: {
      assignmentFeedback: "",
      className: "",
      classDate: "",
      instructor: "",
      lectureContent: "",
      student: "",
      studentPerformance: "",
      uid: "",
    },
    defaultLog: {
      assignmentFeedback: "",
      className: "",
      classDate: "",
      instructor: "",
      lectureContent: "",
      student: "",
      studentPerformance: "",
      uid: "",
    },
    logToBeDeleted: null,
    // instructors: [],
    logs: [],
    // instructorsSelected: [],
    logsSelected: [],
    // defaultPassword: "levelup",
    role: null,
    nameRules: [(v) => !!v || "Required"],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      // this.getInstructor();
      this.getLogs();
    },

    async getLogs() {
      let currentUser = firebase.auth().currentUser;
      let profile = db.collection("profiles").doc(currentUser.uid);
      await profile.get().then((doc) => {
        this.role = doc.data().role;
      });
      let logsDB = await db.collection("logs").get();
      let logsList = [];
      if (
        this.role == "Admin" ||
        this.role == "Manager" ||
        this.role == "Consultant"
      ) {
        logsDB.forEach((doc) => {
          let log = {};
          log.id = doc.id;
          log.instructor = doc.data().instructor;
          log.student = doc.data().student;
          log.className = doc.data().className;
          log.classDate = doc.data().classDate;
          logsList.push(log);
        });
      } else {
        logsDB.forEach((doc) => {
          if (doc.data().uid == currentUser.uid) {
            let log = {};
            log.id = doc.id;
            log.instructor = doc.data().instructor;
            log.student = doc.data().student;
            log.className = doc.data().className;
            log.classDate = doc.data().classDate;
            logsList.push(log);
          }
        });
      }
      this.logs = logsList;
      // console.log("All Instructors: ", this.logs);
    },

    async addLog() {
      let currentUser = firebase.auth().currentUser;
      this.log.uid = currentUser.uid;
      if (
        this.log.instructor &&
        this.log.className &&
        this.log.classDate &&
        this.log.student &&
        this.log.assignmentFeedback &&
        this.log.lectureContent &&
        this.log.studentPerformance
      ) {
        await db.collection("logs").add(this.log);
      } else {
        alert("You must enter all required fields.");
      }
      this.close();
    },

    // deleteInstructor(instructor) {
    //   this.instructorToBeDeleted = instructor;
    //   this.dialogDelete = true;
    // },

    deleteLog(log) {
      this.logToBeDeleted = log;
      this.dialogDelete = true;
    },

    async deleteLogConfirm() {
      await db.collection("logs").doc(this.logToBeDeleted.id).delete();
      this.closeDelete();
    },

    deleteSelectedLogs() {
      this.dialogDeleteSelected = true;
    },

    async deleteSelectedLogsConfirmed() {
      this.selected.forEach((selectedLog) => {
        db.collection("logs").doc(selectedLog.id).delete();
        //firebase.auth().deleteUser(selectedInstructor.id);
        this.getLogs();
      });
      this.closeDeleteSelected();
    },

    close() {
      this.dialog = false;
      this.initialize();
    },

    closeDeleteSelected() {
      this.dialogDeleteSelected = false;
      this.initialize();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.studentToBeDeleted = null;
      this.initialize();
    },
  },
};
</script>

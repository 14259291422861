<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>
        <v-row>
          <v-col cols="12" lg="4" md="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="pa-0"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="6" class="d-flex justify-center">
            <v-select
              v-model="registrationStatusSelected"
              :items="registrationStatusOptions"
              color="primary"
              label="Status"
              style="max-width: 170.95px; height: 40px"
              dense
              outlined
              @change="switchRegistrationStatus"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="6" class="d-flex justify-center">
            <v-btn
              color="primary"
              @click="switchStatus"
              class="mb-2"
              style="width: 170.95px"
              v-if="role == 'Admin'"
            >
              Change Status
            </v-btn>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="6" class="d-flex justify-center">
            <v-dialog v-model="dialog" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  style="width: 170.95px"
                >
                  New Instructor
                </v-btn>
              </template>
              <v-card>
                <v-card-title> Instructor Information </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="instructorFirstName"
                          label="First name*"
                          persistent-hint
                          required
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="instructor.englishName"
                          label="English name"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="instructorLastName"
                          label="Last name*"
                          persistent-hint
                          required
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="instructor.birthday"
                              label="Date of Birth"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="instructor.birthday"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="instructor.sin"
                          label="Social Insurance Number"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="instructor.phone"
                          label="Phone Number*"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="instructor.email"
                          label="Email*"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="instructor.address"
                          label="Address"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="instructor.color"
                          :items="colorsNotUsed"
                          :menu-props="{ maxHeight: '200' }"
                          label="Select Color*"
                          required
                          :rules="nameRules"
                        >
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item
                              v-on="on"
                              v-bind="attrs"
                              #default="{ active }"
                              :class="item"
                            >
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                 <span class="white--text">{{ item }}</span>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="instructor.degree"
                          :items="[
                            'Associate Degree',
                            'Bachelor’s Degree',
                            'Master‘s Degree',
                            'Doctoral Degree',
                            'Others',
                          ]"
                          label="Degree*"
                          required
                          :rules="nameRules"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="instructor.school"
                          label="Graduation School"
                          persistent-hint
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="addInstructor"
                    v-if="role == 'Admin'"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="6" class="d-flex justify-center">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="deleteSelectedInstructors"
            >
              Delete Selected
            </v-btn>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteInstructorConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeleteSelected" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDeleteSelected"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteSelectedInstructorsConfirmed"
                    v-if="role == 'Admin'"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        v-model="selected"
        :headers="instructorHeaders"
        :search="search"
        :items="instructorsShowed"
        item-key="id"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon>
            <router-link
              :to="{ name: 'instructorPage', query: { id: item.id } }"
              ><v-icon small class="mr-2"> mdi-pencil </v-icon></router-link
            >
          </v-btn>
          <v-btn icon>
            <v-icon
              color="primary"
              small
              @click="deleteInstructor(item)"
              v-if="role == 'Admin'"
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { db } from "../plugins/firebase.js";
import firebase from "firebase/app";
import "firebase/functions";
export default {
  data: () => ({
    search: "",
    attrs: "",
    on: "",
    selected: [],
    dialog: false,
    dialogDelete: false,
    dialogDeleteSelected: false,
    dialogEdit: false,
    menu: false,
    modal: false,
    menu2: false,
    role: null,
    instructorHeaders: [
      {
        text: "Name",
        align: "start",
        value: "instructorName",
      },
      { text: "Phone", value: "phone" },
      { text: "Email", value: "email", filterable: false },
      { text: "SIN", value: "sin", filterable: false },
      { text: "Actions", value: "actions", filterable: false, sortable: false },
    ],
    instructor: {
      address: "",
      birthday: "",
      degree: "",
      email: "",
      englishName: "",
      // hourFee: 0,
      defaultRate: 0,
      smallGroup: 0,
      largeGroup: 0,
      classRecords: [],
      instructorName: "",
      phone: "",
      salary: 0,
      school: "",
      sin: "",
      subjects: [],
      registrationStatus: true,
      color: "",
    },
    defaultInstructor: {
      address: "",
      birthday: "",
      degree: "",
      email: "",
      englishName: "",
      defaultRate: 0,
      smallGroup: 0,
      largeGroup: 0,
      classRecords: [],
      instructorName: "",
      phone: "",
      salary: 0,
      school: "",
      sin: "",
      subjects: [],
      registrationStatus: true,
    },
    instructorFirstName: "",
    instructorLastName: "",
    instructorToBeDeleted: null,
    instructors: [],
    registeredInstructors: [],
    unregisteredInstructors: [],
    instructorsShowed: [],
    instructorsSelected: [],
    registrationStatusSelected: "In Service",
    registrationStatusOptions: ["In Service", "Resigned", "All"],
    defaultPassword: process.env.VUE_APP_DEFAULT_PASSWORD_INSTRUCTOR,
    nameRules: [(v) => !!v || "Required"],
    colors: [
      "red lighten-1",
      "red lighten-2",
      "red lighten-3",
      "red lighten-4",
      "red lighten-5",
      "pink lighten-1",
      "pink lighten-2",
      "pink lighten-3",
      "pink lighten-4",
      "pink lighten-5",
      "purple lighten-1",
      "purple lighten-2",
      "purple lighten-3",
      "purple lighten-4",
      "purple lighten-5",
      "deep-purple lighten-1",
      "deep-purple lighten-2",
      "deep-purple lighten-3",
      "deep-purple lighten-4",
      "deep-purple lighten-5",
      "blue lighten-1",
      "blue lighten-2",
      "blue lighten-3",
      "blue lighten-4",
      "blue lighten-5",
      "green lighten-1",
      "green lighten-2",
      "green lighten-3",
      "green lighten-4",
      "green lighten-5",
      "teal lighten-1",
      "teal lighten-2",
      "teal lighten-3",
      "teal lighten-4",
      "teal lighten-5",
      "lime lighten-1",
      "lime lighten-2",
      "lime lighten-3",
      "lime lighten-4",
      "lime lighten-5",
      "orange darken-1",
      "orange darken-2",
      "orange darken-3",
      "orange darken-4",
      "blue-grey darken-1",
      "blue-grey darken-4",
      "grey darken-3"
    ],
    colorsNotUsed: [],
  }),
  // computed: {
  //   formTitle() {
  //     return this.editedIndex === -1 ? "New Item" : "Edit Item";
  //   },
  // },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
    // console.log("my role is", this.role);
  },
  methods: {
    initialize() {
      this.instructor = this.defaultInstructor;
      this.selected = [];
      this.getInstructor();
    },
    async getInstructor() {
      // this.role = "Instructor";
      let currentUser = firebase.auth().currentUser;
      let profile = db.collection("profiles").doc(currentUser.uid);
      await profile.get().then((doc) => {
        this.role = doc.data().role;
      });
      let instructorDB = await db.collection("instructors").get();
      let instructorsList = [];
      let registeredInstructors = [];
      let unregisteredInstructors = [];
      if (
        this.role == "Admin" ||
        this.role == "Manager" ||
        this.role == "Consultant"
      ) {
        instructorDB.forEach((doc) => {
          let instructor = {};
          instructor.id = doc.id;
          instructor.instructorName = doc.data().instructorName;
          instructor.phone = this.formatPhoneNumber(doc.data().phone);
          instructor.email = doc.data().email;
          instructor.sin = doc.data().sin;
          instructor.registrationStatus = doc.data().registrationStatus;
          instructor.color = doc.data().color;
          this.colors = this.colors.filter(
            (color) => color != instructor.color
          );
          if (instructor.registrationStatus) {
            registeredInstructors.push(instructor);
          } else {
            unregisteredInstructors.push(instructor);
          }
          instructorsList.push(instructor);
        });
      } else {
        instructorDB.forEach((doc) => {
          if (doc.id == currentUser.uid) {
            let instructor = {};
            instructor.id = doc.id;
            instructor.instructorName = doc.data().instructorName;
            instructor.phone = this.formatPhoneNumber(doc.data().phone);
            instructor.email = doc.data().email;
            instructor.sin = doc.data().sin;
            instructor.color = doc.data().color;
            instructor.registrationStatus = doc.data().registrationStatus;
            if (instructor.registrationStatus) {
              registeredInstructors.push(instructor);
            } else {
              unregisteredInstructors.push(instructor);
            }
            instructorsList.push(instructor);
          }
        });
      }
      this.instructors = instructorsList;
      this.instructorsShowed = registeredInstructors;
      this.registeredInstructors = registeredInstructors;
      this.unregisteredInstructors = unregisteredInstructors;
      // console.log("All Instructors: ", this.instructors);
      this.colorsNotUsed = this.colors;
      // console.log("filtered colors: ", this.colorsNotUsed);
    },
    async addInstructor() {
      if (
        this.instructorFirstName &&
        this.instructorLastName &&
        this.instructor.email &&
        this.instructor.phone &&
        this.instructor.degree &&
        this.instructor.color
      ) {
        let instructorUserID = null;
        // api
        let password = this.defaultPassword;
        var addUserSetRole = firebase
          .functions()
          .httpsCallable("createUserAndSetRole");
        var data = {
          email: this.instructor.email,
          password: password,
          firstName: this.instructorFirstName,
          lastName: this.instructorLastName,
          role: "Instructor",
          address: this.instructor.address,
          mobile: this.instructor.phone,
          birthday: this.instructor.birthday,
          color: this.instructor.color,
        };
        await addUserSetRole(data)
          .then(function (result) {
            // console.log(result.data);
            instructorUserID = result.data;
          })
          .catch(function (error) {
            console.log(error);
          });
        // api
        this.instructor.instructorName =
          this.instructorFirstName + " " + this.instructorLastName;
        // console.log("1", instructorUserID);
        await db
          .collection("instructors")
          .doc(instructorUserID)
          .set(this.instructor);
        // console.log("2", instructorUserID);
        await db
          .collection("instructorBalanceRecords")
          .doc(instructorUserID)
          .set({
            instructorBlanceRecords: [],
          });
      } else {
        alert("You must enter required fields.");
      }
      this.close();
      this.initialize();
    },
    deleteInstructor(instructor) {
      this.instructorToBeDeleted = instructor;
      this.dialogDelete = true;
    },
    async deleteInstructorConfirm() {
      // console.log("Instructor to be deleted", this.instructorToBeDeleted);
      await db
        .collection("instructors")
        .doc(this.instructorToBeDeleted.id)
        .delete();
      await db
        .collection("instructorBalanceRecords")
        .doc(this.instructorToBeDeleted.id)
        .delete();
      await db
        .collection("profiles")
        .doc(this.instructorToBeDeleted.id)
        .delete();
      //await firebase.auth().delete(this.instructorToBeDeleted.id);
      this.closeDelete();
    },
    deleteSelectedInstructors() {
      this.dialogDeleteSelected = true;
    },
    async deleteSelectedInstructorsConfirmed() {
      this.selected.forEach((selectedInstructor) => {
        db.collection("instructors").doc(selectedInstructor.id).delete();
        db.collection("instructorBalanceRecords")
          .doc(selectedInstructor.id)
          .delete();
        db.collection("profiles").doc(selectedInstructor.id).delete();
        //firebase.auth().deleteUser(selectedInstructor.id);
      });
      this.closeDeleteSelected();
    },
    redirect() {
      this.$router.push;
    },
    close() {
      this.dialog = false;
      this.initialize();
    },
    closeDeleteSelected() {
      this.dialogDeleteSelected = false;
      this.initialize();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.studentToBeDeleted = null;
      this.initialize();
    },
    async switchStatus() {
      // console.log("selected instructors", this.selected);
      if (this.selected.length == 0) {
        alert("Please select instructors to change status");
      }
      this.selected.forEach((selectedInstructor) => {
        // console.log("did i get here?");
        db.collection("instructors").doc(selectedInstructor.id).update({
          registrationStatus: !selectedInstructor.registrationStatus,
        });
      });
      this.registrationStatusSelected = "All";
      this.initialize();
    },
    switchRegistrationStatus() {
      switch (this.registrationStatusSelected) {
        case "All":
          this.instructorsShowed = this.instructors;
          break;
        case "In Service":
          this.instructorsShowed = this.registeredInstructors;
          break;
        case "Resigned":
          this.instructorsShowed = this.unregisteredInstructors;
          break;
      }
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
  },
};
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Calendar from '../views/Calendar.vue'
import Dashboard from '../views/Dashboard.vue'
import Instructors from '../views/Instructors.vue'
import InstructorPage from '../views/InstructorPage.vue'
import Logs from '../views/Logs.vue'
import LogPage from '../views/LogPage.vue'
import Finance from '../views/Finance.vue'
import Profile from '../views/Profile.vue'
import Students from '../views/Students.vue'
import StudentPage from '../views/StudentPage.vue'
import Todos from '../views/Todos.vue'
import Classrooms from '../views/Classrooms.vue'
import Login from '../views/Login.vue'
import firebase from 'firebase/app'
import Settings from '../views/Settings.vue'
import { db } from "../plugins/firebase.js";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: false, instructorAuth: true, studentAuth: false,
    }
  },
  {
    path: '/students',
    name: 'students',
    component: Students,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: false, instructorAuth: false, studentAuth: true
    }
  },
  {
    path: '/students/studentpage',
    name: 'studentPage',
    component: StudentPage,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: false, instructorAuth: false, studentAuth: true,
    }
  },

  {
    path: '/instructors',
    name: 'instructors',
    component: Instructors,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: false, instructorAuth: true, studentAuth: false,
    }
  },
  {
    path: '/instructors/instructorpage',
    name: 'instructorPage',
    component: InstructorPage,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: false, instructorAuth: true, studentAuth: false,
    }
  },
  {
    path: '/classrooms',
    name: 'classrooms',
    component: Classrooms,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: true, instructorAuth: false, studentAuth: false,
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: false, instructorAuth: true, studentAuth: false,
    }
  },
  {
    path: '/logs',
    name: 'logs',
    component: Logs,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: false, instructorAuth: true, studentAuth: false,
    }
  },
  {
    path: '/logs/logpage',
    name: 'logPage',
    component: LogPage,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: false, instructorAuth: true, studentAuth: false,
    }
  },
  {
    path: '/finance',
    name: 'finance',
    component: Finance,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:true, conultantAuth: false, instructorAuth: false, studentAuth: false,
    }
  },
  {
    path: '/todos',
    name: 'todos',
    component: Todos,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: true, instructorAuth: false, studentAuth: false,
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true, adminAuth: false, managerAuth:false, conultantAuth: false, instructorAuth: false, studentAuth: false,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/settings',
    name: 'setting',
    component: Settings,
    meta: {
      requiresAuth: true, adminAuth: true, managerAuth:false, conultantAuth: false, instructorAuth: false, studentAuth: false,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) {
    next('login')
  }
  else if (to.meta.adminAuth) {
    let profile = db.collection("profiles").doc(currentUser.uid);
  let currentRole = "Undefined";
  await profile.get().then((doc) => {
        currentRole = doc.data().role;
      }); 
    if (currentRole == "Admin") {
      next();
    } else {
      next("/404");
    }
  } else if (to.meta.managerAuth) {
    let profile = db.collection("profiles").doc(currentUser.uid);
  let currentRole = "Undefined";
  await profile.get().then((doc) => {
        currentRole = doc.data().role;
      }); 
    if (currentRole == "Admin" || currentRole == "Manager") {
      next();
    } else {
      next("/404");
    }
  } else if (to.meta.conultantAuth) {
    let profile = db.collection("profiles").doc(currentUser.uid);
  let currentRole = "Undefined";
  await profile.get().then((doc) => {
        currentRole = doc.data().role;
      }); 
    if (currentRole == "Admin" || currentRole == "Manager" || currentRole == "Consultant") {
      next();
    } else {
      next("/404");
    }
  } else if (to.meta.instructorAuth) {
    let profile = db.collection("profiles").doc(currentUser.uid);
  let currentRole = "Undefined";
  await profile.get().then((doc) => {
        currentRole = doc.data().role;
      }); 
    if (currentRole == "Admin" || currentRole == "Manager" || currentRole == "Consultant" || currentRole == "Instructor") {
      next();
    } else {
      next("/404");
    }
  } else if (to.meta.studentAuth) {
    let profile = db.collection("profiles").doc(currentUser.uid);
  let currentRole = "Undefined";
  await profile.get().then((doc) => {
        currentRole = doc.data().role;
      }); 
    if (currentRole == "Admin" || currentRole == "Manager" || currentRole == "Consultant" || currentRole == "Student") {
      next();
    } else {
      next("/404");
    }
  }
  else {
    next();
  }
})

export default router

<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12" lg="3" sm="12">
        <v-row>
          <v-col cols="12" lg="12" md="3" sm="6">
            <div class="d-flex flex-column">
              <span class="text-h3 font-weight-medium">{{
                formatPrice(this.balance)
              }}</span>
              <span class="text-h7 font-weight-light">Balance</span>
            </div>
          </v-col>
          <v-col cols="12" lg="12" md="3" sm="6">
            <div class="d-flex flex-column">
              <span class="text-h3 font-weight-medium">{{
                formatPrice(this.revenue)
              }}</span>
              <span class="text-h7 font-weight-light">Revenue</span>
            </div>
          </v-col>
          <v-col cols="12" lg="12" md="3" sm="6">
            <div class="d-flex flex-column">
              <span
                class="text-h3 font-weight-medium"
                v-if="profile.role === 'Admin'"
                >{{ formatPrice(this.expense) }}</span
              >
              <span class="text-h3 font-weight-medium" v-else>{{
                formatPrice(0)
              }}</span>
              <span class="text-h7 font-weight-light">Expense</span>
            </div>
          </v-col>
          <v-col cols="12" lg="12" md="3" sm="6">
            <div class="d-flex flex-column">
              <span
                class="text-h3 font-weight-medium"
                v-if="profile.role === 'Admin'"
                >{{ formatPrice(this.profit) }}</span
              >
              <span class="text-h3 font-weight-medium" v-else>{{
                formatPrice(0)
              }}</span>
              <span class="text-h7 font-weight-light">Profit</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="9" sm="12">
        <!-- <div class="d-flex flex-row justify-end"> -->
          <v-row>
            <v-col lg="3" md="3" sm="3" xs="6" class="d-flex justify-center">
          <v-btn
            outlined
            dense
            color="primary"
            style="
              min-width: 110px;
              text-transform: none;
              font-size: 16px;
              height: 40px;
              font-weight: 400;
              letter-spacing: 0;
            "
            @click="filterByWeek"
          >
            Week
          </v-btn>
          </v-col>
          <v-col lg="3" md="3" sm="3" xs="6" class="d-flex justify-center">
          <v-select
            :items="months"
            v-model="selectedMonth"
            label="Month"
            outlined
            dense
            color="primary"
            style="min-width: 110px; max-width: 110px;"
            @change="setMonth"
          ></v-select>
          </v-col>
          <v-col lg="3" md="3" sm="3" xs="6" class="d-flex justify-center">
          <v-select
            :items="years"
            v-model="selectedYear"
            label="Year"
            outlined
            dense
            color="primary"
            style="min-width: 110px; max-width: 110px;"
            @change="setYear"
          ></v-select>
          </v-col>
          <v-col lg="3" md="3" sm="3" xs="6" class="d-flex justify-center">
          <v-btn
            outlined
            dense
            color="primary"
            @click="filter"
            style="
              min-width: 110px;
              text-transform: none;
              font-size: 16px;
              height: 40px;
              font-weight: 400;
              letter-spacing: 0;
            "
          >
            Get Data
          </v-btn>
          </v-col>
          </v-row>
        <!-- </div> -->
        <v-card elevation="0" min-height="300" id="chart-1">
          <v-card-title class="d-flex flex-row align-center">
            <!-- <span class="text-body1">Finance chart</span> -->
            <!-- <line-chart :data="datasets"></line-chart> -->
            <!-- <div id="chart-1" style="height: auto;"></div> -->
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" class="mt-10" v-if="profile.role === 'Admin'">
        <span class="text-h5 font-weight-light">Expense Table</span>
        <v-card
          elevation="0"
          max-height="500"
          style="border-top: #6c5dd3 2px solid"
          v-scroll.self="onScroll"
          class="overflow-y-auto"
        >
          <v-card-text>
            <v-expansion-panels focusable multiple>
              <v-expansion-panel
                v-for="(instructorBalanceRecord, j) in instructorBalanceRecords"
                :key="instructorBalanceRecord.docID + 'name' + j"
                class="mb-2"
              >
                <v-expansion-panel-header
                  >{{ instructorBalanceRecord.name }}
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-data-table
                    :headers="headers"
                    :items="instructorBalanceRecord.instructorBalanceRecords"
                    :items-per-page="5"
                    class="elevation-0"
                  ></v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" class="mt-5 mb-10">
        <span class="text-h5 font-weight-light">Revenue Table</span>
        <v-card
          elevation="0"
          max-height="500"
          style="border-top: #6c5dd3 2px solid"
          v-scroll.self="onScroll"
          class="overflow-y-auto"
        >
          <v-card-text>
            <v-expansion-panels focusable multiple>
              <v-expansion-panel
                v-for="(studentBalanceRecord, i) in studentBalanceRecords"
                :key="studentBalanceRecord.docID + 'name' + i"
                class="mb-2"
              >
                <v-expansion-panel-header
                  >{{ studentBalanceRecord.name }}
                  <template v-slot:actions>
                    <v-icon color="primary"> $expand </v-icon>
                  </template></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-data-table
                    :headers="headers"
                    :items="studentBalanceRecord.studentBalanceRecords"
                    :items-per-page="5"
                    class="elevation-0"
                  ></v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import firebase from "firebase/app";
import moment from "moment";
import { db } from "../plugins/firebase.js";
import ChartKick from "chartkick";
import "chartkick/chart.js";
export default {
  name: "Finance",
  data: () => ({
    balance: 0,
    revenue: 0,
    expense: 0,
    profit: 0,
    studentBalanceRecords: [],
    studentBalanceRecordsDefault: [],
    instructorBalanceRecords: [],
    instructorBalanceRecordsDefault: [],
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
    currentWeekDay: new Date().getUTCDay(),
    currentDate: new Date().getUTCDate(),
    headers: [
      { text: "Amount", value: "amount" },
      { text: "Create Time", value: "createTime" },
      { text: "Detail", value: "detail" },
    ],
    scrollInvoked: 0,
    selectedMonth: null,
    modal: false,
    modal1: false,
    selectedYear: null,
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    years: [
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
      "2023",
      "2025",
      "2026",
    ],
    datasets: [
      {
        name: "Revenue",
        data: {},
      },
      {
        name: "Expense",
        data: {},
      },
      {
        name: "Profit",
        data: {},
      },
    ],
    profile: {
      firstName: null,
      address: null,
      mobile: null,
      lastName: null,
      role: null,
      birthday: null,
      avatarURL: null,
    },
  }),
  created() {
    this.main();
  },
  methods: {
    async main() {
      this.getProfile();
      await this.getStudentBalanceRecords(this.getRevenue);
      await this.getInstructorBalanceRecords(this.getExpense);
      await this.getTotalBalance();
      // console.log("months", moment.monthsShort());
      this.buildChart();
      new ChartKick.LineChart("chart-1", this.datasets);
    },
    print() {
      let newMoment = moment(new Date());
      const startWeekDay = moment().subtract(this.currentWeekDay - 2, "days");
      const endWeekDay = moment().add(7 - this.currentWeekDay + 1, "days");
      newMoment.isBetween(startWeekDay, endWeekDay);
      // console.log("startWeek", startWeekDay);
      // console.log("endWeek", endWeekDay);
      // console.log("moment", newMoment);
      // console.log("ans", newMoment.isBetween(startWeekDay, endWeekDay));
      // // console.log("slectedMonthNum", this.months.indexOf(this.selectedMonth));
      // // console.log("selectedYear", this.currentYear);
    },
    initialize() {
      this.studentBalanceRecords = this.studentBalanceRecordsDefault;
      this.instructorBalanceRecords = this.instructorBalanceRecordsDefault;
    },
    setMonth() {
      this.currentMonth = this.months.indexOf(this.selectedMonth);
    },
    setYear() {
      this.currentYear = this.selectedYear;
    },
    filter() {
      this.initialize();
      if (this.selectedMonth == null && this.selectedYear != null) {
        this.filterByYear();
      } else if (this.selectedMonth != null && this.selectedYear != null) {
        this.filterByMonth();
      } else {
        alert("Please select both year and month!");
      }
    },
    filterByWeek() {
      this.initialize();
      this.revenue = 0;
      this.expense = 0;
      this.profit = 0;
      this.getStudentBalanceRecordsByWeek(this.getRevenue);
      this.getInstructorBalanceRecordsByWeek(this.getExpense);
    },
    filterByMonth() {
      this.revenue = 0;
      this.expense = 0;
      this.profit = 0;
      this.getStudentBalanceRecordsByMonth(this.getRevenue);
      this.getInstructorBalanceRecordsByMonth(this.getExpense);
    },
    filterByYear() {
      this.revenue = 0;
      this.expense = 0;
      this.profit = 0;
      this.getStudentBalanceRecordsByYear(this.getRevenue);
      this.getInstructorBalanceRecordsByYear(this.getExpense);
    },
    async getProfile() {
      const user = firebase.auth().currentUser;
      let docRef = await db.collection("profiles").doc(user.uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.profile = doc.data();
            // console.log("Document data:", doc.data());
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    async getStudentBalanceRecords(callback) {
      let snapshot = await db.collection("studentBalanceRecords").get();
      let studentBalanceRecordsArray = [];
      snapshot.forEach((doc) => {
        let studentBalanceRecord = {};
        studentBalanceRecord.docID = doc.id;
        if (doc.data().studentBalanceRecords) {
          if (doc.data().studentBalanceRecords.length > 0) {
            studentBalanceRecord.name = doc.data().studentBalanceRecords[0].studentName;
            studentBalanceRecord.studentBalanceRecords = doc.data().studentBalanceRecords;
            studentBalanceRecordsArray.push(studentBalanceRecord);
          }
        }
      });
      this.studentBalanceRecords = studentBalanceRecordsArray;
      this.studentBalanceRecordsDefault = studentBalanceRecordsArray;
      // console.log(
      //   "students balance records from db:",
      //   this.studentBalanceRecords
      // );
      callback();
    },
    async getInstructorBalanceRecords(callback) {
      let snapshot = await db.collection("instructorBalanceRecords").get();
      let instructorBalanceRecordsArray = [];
      snapshot.forEach((doc) => {
        let instructorBalanceRecord = {};
        instructorBalanceRecord.docID = doc.id;
        if (doc.data().instructorBalanceRecords) {
          if (doc.data().instructorBalanceRecords.length > 0) {
            instructorBalanceRecord.name = doc.data().instructorBalanceRecords[0].instructorName;
            instructorBalanceRecord.instructorBalanceRecords = doc.data().instructorBalanceRecords;
            instructorBalanceRecordsArray.push(instructorBalanceRecord);
          }
        }
      });
      this.instructorBalanceRecords = instructorBalanceRecordsArray;
      this.instructorBalanceRecordsDefault = instructorBalanceRecordsArray;
      // console.log(
      //   "instructor balance records from db:",
      //   this.instructorBalanceRecords
      // );
      callback();
    },
    onScroll() {
      this.scrollInvoked++;
    },
    async getTotalBalance() {
      let students = await db.collection("students").get();
      students.forEach((student) => {
        this.balance += student.data().balance;
      });
    },
    getRevenue() {
      // console.log("get here?", this.studentBalanceRecords.length);
      this.studentBalanceRecords.forEach((singleStudentBalanceRecordsObj) => {
        // console.log("get inside?");
        singleStudentBalanceRecordsObj.studentBalanceRecords.forEach(
          (singleStudentBalanceRecord) => {
            // console.log(
            //   "student amount is ",
            //   singleStudentBalanceRecord.amount
            // );
            if (singleStudentBalanceRecord.amount < 0) {
              this.revenue += singleStudentBalanceRecord.amount;
            }
          }
        );
      });
      this.revenue = Math.abs(this.revenue);
    },
    getExpense() {
      this.instructorBalanceRecords.forEach(
        (singleInstructorBalanceRecordsObj) => {
          // studntObjByMonth = {};
          singleInstructorBalanceRecordsObj.instructorBalanceRecords.forEach(
            (singleInstructorBalanceRecord) => {
              this.expense += singleInstructorBalanceRecord.amount;
            }
          );
        }
      );
      this.getProfit();
    },
    getProfit() {
      this.profit = this.revenue - this.expense;
    },
    async getInstructorBalanceRecordsByWeek(callback) {
      const startWeekDay = moment().subtract(this.currentWeekDay - 2, "days");
      const endWeekDay = moment().add(7 - this.currentWeekDay + 1, "days");
      let instructorBalanceRecordsByMonth = [];
      this.instructorBalanceRecords.forEach((singleInstructorObj) => {
        //// console.log("singleInstructorObj", singleInstructorObj);
        let newInstructorObj = {};
        newInstructorObj.docID = singleInstructorObj.docID;
        newInstructorObj.name = singleInstructorObj.name;
        newInstructorObj.instructorBalanceRecords = singleInstructorObj.instructorBalanceRecords.filter(
          (balanceRecord) => {
            let recordMoment = moment(new Date(balanceRecord.createTime));
            return recordMoment.isBetween(startWeekDay, endWeekDay);
          }
        );
        instructorBalanceRecordsByMonth.push(newInstructorObj);
      });
      this.instructorBalanceRecords = instructorBalanceRecordsByMonth;
      // console.log("month instructor records", this.instructorBalanceRecords);
      callback();
    },
    async getInstructorBalanceRecordsByMonth(callback) {
      let instructorBalanceRecordsByMonth = [];
      this.instructorBalanceRecords.forEach((singleInstructorObj) => {
        //// console.log("singleInstructorObj", singleInstructorObj);
        let newInstructorObj = {};
        newInstructorObj.docID = singleInstructorObj.docID;
        newInstructorObj.name = singleInstructorObj.name;
        newInstructorObj.instructorBalanceRecords = singleInstructorObj.instructorBalanceRecords.filter(
          (balanceRecord) => {
            //// console.log("balanceRecord", balanceRecord);
            let instructorMonth = new Date(balanceRecord.createTime).getMonth();
            //// console.log("balanceRecord Month", instructorMonth);
            let instructorYear = new Date(
              balanceRecord.createTime
            ).getFullYear();
            //// console.log("balanceRecord year", instructorYear);
            return (
              instructorMonth == this.currentMonth &&
              instructorYear == this.currentYear
            );
          }
        );
        instructorBalanceRecordsByMonth.push(newInstructorObj);
      });
      this.instructorBalanceRecords = instructorBalanceRecordsByMonth;
      // console.log("month instructor records", this.instructorBalanceRecords);
      callback();
    },
    async getInstructorBalanceRecordsByYear(callback) {
      let instructorBalanceRecordsByMonth = [];
      this.instructorBalanceRecords.forEach((singleInstructorObj) => {
        //// console.log("singleInstructorObj", singleInstructorObj);
        let newInstructorObj = {};
        newInstructorObj.docID = singleInstructorObj.docID;
        newInstructorObj.name = singleInstructorObj.name;
        newInstructorObj.instructorBalanceRecords = singleInstructorObj.instructorBalanceRecords.filter(
          (balanceRecord) => {
            //// console.log("balanceRecord", balanceRecord);
            //// console.log("balanceRecord Month", instructorMonth);
            let instructorYear = new Date(
              balanceRecord.createTime
            ).getFullYear();
            //// console.log("balanceRecord year", instructorYear);
            return instructorYear == this.currentYear;
          }
        );
        instructorBalanceRecordsByMonth.push(newInstructorObj);
      });
      this.instructorBalanceRecords = instructorBalanceRecordsByMonth;
      // console.log("month instructor records", this.instructorBalanceRecords);
      callback();
    },
    async getStudentBalanceRecordsByWeek(callback) {
      const startWeekDay = moment().subtract(this.currentWeekDay - 2, "days");
      const endWeekDay = moment().add(7 - this.currentWeekDay + 1, "days");
      let studentBalanceRecordsByMonth = [];
      this.studentBalanceRecords.forEach((singleStudentObj) => {
        //// console.log("singleStudentObj", singleStudentObj);
        let newStudentObj = {};
        newStudentObj.docID = singleStudentObj.docID;
        newStudentObj.name = singleStudentObj.name;
        newStudentObj.studentBalanceRecords = singleStudentObj.studentBalanceRecords.filter(
          (balanceRecord) => {
            let recordMoment = moment(new Date(balanceRecord.createTime));
            return recordMoment.isBetween(startWeekDay, endWeekDay);
          }
        );
        studentBalanceRecordsByMonth.push(newStudentObj);
      });
      this.studentBalanceRecords = studentBalanceRecordsByMonth;
      // console.log("month student records", this.studentBalanceRecords);
      callback();
    },
    async getStudentBalanceRecordsByMonth(callback) {
      let studentBalanceRecordsByMonth = [];
      this.studentBalanceRecords.forEach((singleStudentObj) => {
        //// console.log("singleStudentObj", singleStudentObj);
        let newStudentObj = {};
        newStudentObj.docID = singleStudentObj.docID;
        newStudentObj.name = singleStudentObj.name;
        newStudentObj.studentBalanceRecords = singleStudentObj.studentBalanceRecords.filter(
          (balanceRecord) => {
            //// console.log("balanceRecord", balanceRecord);
            let studentMonth = new Date(balanceRecord.createTime).getMonth();
            //// console.log("balanceRecord Month", studentMonth);
            let studentYear = new Date(balanceRecord.createTime).getFullYear();
            //// console.log("balanceRecord year", studentYear);
            return (
              studentMonth == this.currentMonth &&
              studentYear == this.currentYear
            );
          }
        );
        studentBalanceRecordsByMonth.push(newStudentObj);
      });
      this.studentBalanceRecords = studentBalanceRecordsByMonth;
      // console.log("month student records", this.studentBalanceRecords);
      callback();
    },
    async getStudentBalanceRecordsByYear(callback) {
      let studentBalanceRecordsByMonth = [];
      this.studentBalanceRecords.forEach((singleStudentObj) => {
        //// console.log("singleStudentObj", singleStudentObj);
        let newStudentObj = {};
        newStudentObj.docID = singleStudentObj.docID;
        newStudentObj.name = singleStudentObj.name;
        newStudentObj.studentBalanceRecords = singleStudentObj.studentBalanceRecords.filter(
          (balanceRecord) => {
            //// console.log("balanceRecord", balanceRecord);
            //// console.log("balanceRecord Month", studentMonth);
            let studentYear = new Date(balanceRecord.createTime).getFullYear();
            //// console.log("balanceRecord year", studentYear);
            return studentYear == this.currentYear;
          }
        );
        studentBalanceRecordsByMonth.push(newStudentObj);
      });
      this.studentBalanceRecords = studentBalanceRecordsByMonth;
      // console.log("month student records", this.studentBalanceRecords);
      callback();
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    buildChart() {
      let monthList = moment.monthsShort();
      let test = [];
      for (let i = 11; i >= 0; i--) {
        this.currentMonth = new Date().getMonth() - i;
        if (this.currentMonth < 0) {
          this.currentMonth = 12 + this.currentMonth;
          this.currentYear = new Date().getFullYear() - 1;
        } else {
          this.currentYear = new Date().getFullYear();
        }
        // console.log("index", this.currentMonth);
        this.filterByMonth();
        this.datasets[0].data[monthList[this.currentMonth]] = this.revenue;
        test.push(this.revenue);
        if (this.profile.role === "Admin") {
          this.datasets[1].data[monthList[this.currentMonth]] = this.expense;
          this.datasets[2].data[monthList[this.currentMonth]] = this.profit;
        }
        this.initialize();
      }
      this.reset();
    },

    reset() {
      this.currentMonth = new Date().getMonth();
      this.currentYear = new Date().getFullYear();
      this.studentBalanceRecords = this.studentBalanceRecordsDefault;
      this.instructorBalanceRecords = this.instructorBalanceRecordsDefault;
      this.getRevenue();
      this.getExpense();
    },
  },
};
</script>

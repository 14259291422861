<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <Sidebar v-if="!['login'].includes($route.name)" />
    <v-main class="ma-4">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
export default {
  name: "App",
  components: {
    Sidebar,
  },
};
</script>

<style>
</style>
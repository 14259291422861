<template>
  <v-card>
    <v-navigation-drawer
      permanent
      expand-on-hover
      app
      :style="{ background: $vuetify.theme.themes.light.background }"
    >
      <v-list-item class="mt-2">
        <v-list-item-icon>
          <img src="../assets/icon-logo.png" alt="" width="24" height="24" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>LEVEL UP</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list nav v-if="role == 'Student'">
        <v-list-item
          v-for="link in studentLinks"
          :key="link.title"
          router
          :to="link.route"
          active-class="primary white--text rounded-lg"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-on:click="signOut">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list nav v-if="role == 'Instructor'">
        <v-list-item
          v-for="link in instructorLinks"
          :key="link.title"
          router
          :to="link.route"
          active-class="primary white--text rounded-lg"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-on:click="signOut">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list
        nav
        v-if="role == 'Admin' || role == 'Manager' || role == 'Consultant'"
      >
        <v-list-item
          v-for="link in links"
          :key="link.title"
          router
          :to="link.route"
          active-class="primary white--text rounded-lg"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-on:click="signOut">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase/app";
import { db } from "../plugins/firebase.js";
export default {
  data() {
    return {
      drawer: true,
      links: [
        { title: "Dashboard", icon: "mdi-view-dashboard-outline", route: "/" },
        { title: "Students", icon: "mdi-face-man", route: "/students" },
        {
          title: "Instructors",
          icon: "mdi-school-outline",
          route: "/instructors",
        },
        {
          title: "Classrooms",
          icon: "mdi-account-multiple-plus",
          route: "/classrooms",
        },
        { title: "Calendar", icon: "mdi-calendar", route: "/calendar" },
        { title: "Logs", icon: "mdi-backup-restore", route: "/logs" },
        { title: "Finance", icon: "mdi-credit-card", route: "/finance" },
        { title: "Todos", icon: " mdi-clipboard-text", route: "/todos" },
        {
          title: "Profile",
          icon: "mdi-account-circle-outline",
          route: "/profile",
        },
        {
          title: "Settings",
          icon: "mdi-cog-outline",
          route: "/settings",
        },
      ],
      instructorLinks: [
        { title: "Dashboard", icon: "mdi-view-dashboard-outline", route: "/" },

        {
          title: "Instructors",
          icon: "mdi-school-outline",
          route: "/instructors",
        },

        { title: "Calendar", icon: "mdi-calendar", route: "/calendar" },
        { title: "Logs", icon: "mdi-backup-restore", route: "/logs" },

        {
          title: "Profile",
          icon: "mdi-account-circle-outline",
          route: "/profile",
        },
      ],
      studentLinks: [
        { title: "Students", icon: "mdi-face", route: "/students" },
        {
          title: "Profile",
          icon: "mdi-account-circle-outline",
          route: "/profile",
        },
      ],
      mini: true,
      role: null,
    };
  },
  created() {
    this.getRole();
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth"]),
  },
  methods: {
    ...mapActions(["signOutAction"]),
    signOut() {
      this.signOutAction();
      this.$router.push(this.$route.query.redirect || "/login");
    },

    async getRole() {
      const currentUser = firebase.auth().currentUser;
      let profile = db.collection("profiles").doc(currentUser.uid);
      await profile.get().then((doc) => {
        this.role = doc.data().role;
      });
    },
  },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pa-10">
        <v-card elevation="0" class="pa-2">
          <v-form @submit.prevent="updateLog">
            <v-card-title>
              <span class="headline text-h5 font-weight-medium"
                >Class Feedback</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="mt-4">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="log.student"
                      label="Student Name"
                      outlined
                      required
                      :readonly="!(role == 'Admin' || role == 'Instructor')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="log.instructor"
                      label="Instructor Name"
                      outlined
                      required
                      :readonly="!(role == 'Admin' || role == 'Instructor')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="log.className"
                      label="Class Name"
                      outlined
                      required
                      :readonly="!(role == 'Admin' || role == 'Instructor')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="classDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="log.classDate"
                          label="Date of Class"
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="log.classDate"
                        @input="classDatePicker = false"
                        :disabled="!(role == 'Admin' || role == 'Instructor')"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <template>
                      <v-textarea
                        v-model="log.lectureContent"
                        outlined
                        clearable
                        clear-icon="mdi-close-circle"
                        name="input-7-2"
                        label="Lecture Content*"
                        auto-grow
                        prepend-inner-icon="mdi-comment"
                        :readonly="!(role == 'Admin' || role == 'Instructor')"
                      ></v-textarea>
                    </template>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <template>
                      <v-textarea
                        v-model="log.studentPerformance"
                        outlined
                        clearable
                        clear-icon="mdi-close-circle"
                        name="input-7-2"
                        label="Student Performance*"
                        auto-grow
                        prepend-inner-icon="mdi-comment"
                        :readonly="!(role == 'Admin' || role == 'Instructor')"
                      ></v-textarea>
                    </template>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <template>
                      <v-textarea
                        v-model="log.assignmentFeedback"
                        outlined
                        clearable
                        clear-icon="mdi-close-circle"
                        name="input-7-2"
                        label="Assignment Feedback*"
                        auto-grow
                        prepend-inner-icon="mdi-comment"
                        :readonly="!(role == 'Admin' || role == 'Instructor')"
                      ></v-textarea>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <!-- <v-spacer></v-spacer> -->
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  class="d-flex justify-space-around"
                >
                  <v-btn
                    outlined
                    color="primary"
                    x-large
                    @click="generatePDF"
                    class="mr-2"
                    style="min-width: 274.69px"
                  >
                    Print Class Feedback
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      class="ml-2"
                      v-if="loading2"
                    ></v-progress-circular>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  sm="12"
                  class="d-flex justify-space-around"
                >
                  <v-btn
                    outlined
                    color="primary"
                    type="submit"
                    x-large
                    class="mr-2"
                    :disabled="!(role == 'Admin' || role == 'Instructor')"
                  >
                    Update Class Feedback
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      class="ml-2"
                      v-if="loading1"
                    ></v-progress-circular>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <template>
      <div>
        <v-card id="feedback" style="display: none">
          <v-card-title>
            <span class="headline text-h2 font-weight-medium"
              >Class Feedback</span
            >
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="3" md="3">
                  <span class="grey--text text-h4">Student Name</span>
                </v-col>
                <v-col cols="9" md="9">
                  <span class="indigo--text text--darken-4 text-h4">{{
                    log.student
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" md="3">
                  <span class="grey--text text-h4">Instructor</span>
                </v-col>
                <v-col cols="9" md="9">
                  <span class="indigo--text text--darken-4 text-h4">{{
                    log.instructor
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" md="3">
                  <span class="grey--text text-h4">Class Name</span>
                </v-col>
                <v-col cols="9" md="9">
                  <span class="indigo--text text--darken-4 text-h4">{{
                    log.className
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" md="3">
                  <span class="grey--text text-h4">Class Date</span>
                </v-col>
                <v-col cols="9" md="9">
                  <span class="indigo--text text--darken-4 text-h4">{{
                    log.classDate
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" md="3">
                  <span class="grey--text text-h4">Lecture Content</span>
                </v-col>
                <v-col cols="9" md="9">
                  <span class="indigo--text text--darken-4 text-h4">{{
                    log.lectureContent
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" md="3">
                  <span class="grey--text text-h4">Student Performance</span>
                </v-col>
                <v-col cols="9" md="9">
                  <span class="indigo--text text--darken-4 text-h4">{{
                    log.studentPerformance
                  }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" md="3">
                  <span class="grey--text text-h4">Assignment Feedback</span>
                </v-col>
                <v-col cols="9" md="9">
                  <span class="indigo--text text--darken-4 text-h4">{{
                    log.assignmentFeedback
                  }}</span>
                </v-col>
              </v-row>
              <v-row class="mt-10">
                <v-spacer></v-spacer>
                <v-col>
                  <img
                    src="../assets/logo-horizontal.png"
                    alt=""
                    width="300"
                    height="100"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-10">
                <v-spacer></v-spacer>
                <v-col class="d-flex flex-column flex-end justify-end">
                  <!-- <span class="text-subtitle-1 font-weight-light"
                >LEVEL UP Learning Centre</span
              > -->
                  <span class="text-subtitle-1 font-weight-light"
                    >Head Office: 2390-4000 No.3 Rd, Richmond, V6X0J8</span
                  >
                  <span class="text-subtitle-1 font-weight-light"
                    >Learning Centre: 5070-4000 No.3 Rd, Richmond V6X0J8</span
                  >
                  <span class="text-subtitle-1 font-weight-light"
                    >Phone: 778-737-7591</span
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
    </template>
  </v-container>
</template>

<script>
import { db } from "../plugins/firebase.js";
import firebase from "firebase/app";
import "firebase/storage";
import html2PDF from "jspdf-html2canvas";
export default {
  name: "Log",
  data: () => ({
    logDocID: "jQw8Kd4h9aS9gpLKCiQW",

    classDatePicker: false,
    role: null,

    log: {
      assignmentFeedback: "",
      className: "",
      classDate: "",
      instructor: "",
      lectureContent: "",
      student: "",
      studentPerformance: "",
      uid: "",
    },

    loading1: false,
    loading2: false,
  }),
  created() {
    // console.log("did i get the id here???", this.$route.query.id);
    this.logDocID = this.$route.query.id;
    this.getLog();
  },
  methods: {
    async getLog() {
      let currentUser = firebase.auth().currentUser;
      let profile = db.collection("profiles").doc(currentUser.uid);
      await profile.get().then((doc) => {
        this.role = doc.data().role;
      });
      const logDB = db.collection("logs").doc(this.logDocID);
      logDB.get().then((doc) => {
        this.log = doc.data();
        // console.log("log is", doc);
      });
    },

    async updateLog() {
      this.loading1 = true;
      await db.collection("logs").doc(this.logDocID).update(this.log);
      this.getLog();
      setTimeout(() => {
        this.loading1 = false;
      }, 1500);
    },
    async generatePDF() {
      this.loading2 = true;
      let hideFeedback = document.querySelector("#feedback");
      hideFeedback.style.display = "block";
      await html2PDF(hideFeedback, {
        jsPDF: {
          format: "a4",
        },
        html2canvas: {
          scrollX: 0,
          scrollY: -window.scrollY,
        },
        imageType: "image/jpeg",
        output: "feedback.pdf",
      });
      hideFeedback.style.display = "block";
      setTimeout(() => {
        this.loading2 = false;
      }, 1500);
    },
  },
};
</script>

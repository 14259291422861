<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3" md="4" sm="12" class="d-flex flex-column">
        <v-card elevation="0" class="pa-2">
          <v-card-title class="d-flex justify-space-around">
            <v-progress-circular
              :rotate="-90"
              :size="250"
              :width="20"
              :value="Math.abs(value)"
              v-if="value > 500"
              color="primary"
            >
              <span class="primary--text text-h4 font-weight-medium">{{
                "$" + formatPrice(value)
              }}</span>
            </v-progress-circular>
            <v-progress-circular
              :rotate="-90"
              :size="250"
              :width="20"
              :value="Math.abs(value)"
              v-else
              color="red"
            >
              <span class="primary--text text-h4 font-weight-medium">{{
                "$" + formatPrice(value)
              }}</span>
            </v-progress-circular>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <div class="d-flex justify-center mt-3">
              <v-btn
                outlined
                block
                color="primary"
                class="mt-4"
                @click="addBalance"
                v-if="profile.role == 'Admin' || profile.role == 'Manager'"
              >
                Add Balance
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="ml-2"
                  v-if="loading2"
                ></v-progress-circular>
              </v-btn>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                outlined
                block
                color="primary"
                class="mt-4"
                @click="chargeBalance"
                v-if="profile.role == 'Admin' || profile.role == 'Manager'"
              >
                Charge Balance
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="ml-2"
                  v-if="loading2"
                ></v-progress-circular>
              </v-btn>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                outlined
                block
                color="primary"
                class="mt-4"
                @click="dialog = true"
              >
                See Class Schedule
              </v-btn>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                outlined
                block
                color="primary"
                class="mt-4"
                @click="generatePDF"
              >
                Print Class Schedule
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="ml-2"
                  v-if="loading3"
                ></v-progress-circular>
              </v-btn>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                outlined
                block
                color="primary"
                class="mt-4"
                @click="dialog1 = true"
              >
                See Next Week Schedule
              </v-btn>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                outlined
                block
                color="primary"
                class="mt-4"
                @click="generateNextWeekPDF"
              >
                Print Next Week Schedule
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="ml-2"
                  v-if="loading4"
                ></v-progress-circular>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="9" md="8" sm="12">
        <v-tabs
          fixed-tabs
          color="primary"
          next-icon="mdi-arrow-right-bold-box-outline"
          show-arrows
        >
          <v-tab> Profile </v-tab>
          <v-tab> Class Records </v-tab>
          <v-tab> Balance Records </v-tab>
          <v-tab
            v-if="
              profile.role == 'Admin' ||
              profile.role == 'Manager' ||
              profile.role == 'Consultant'
            "
          >
            Applications
          </v-tab>
          <v-tab-item>
            <v-card elevation="0" class="pa-2 mt-2">
              <v-form @submit.prevent="updateStudentInfo">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          readonly
                          v-model="student.studentName"
                          label="Full Name"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="student.englishName"
                          label="English Name"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="student.phone"
                          label="Phone Number"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="birthDatePicker"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="student.birthday"
                              label="Date of Birth"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="student.birthday"
                            @input="birthDatePicker = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="student.address"
                          label="Address"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          readonly
                          v-model="student.email"
                          label="E-mail"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="student.emergency"
                          label="Emergency Contact Name"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="student.emergencyContact"
                          label="Emergency Contact Number"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="student.hourFee"
                          label="Default Hourly Rate"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="expDatePicker"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="student.studyPermitExpireDate"
                              label="Study Permit Expiry Date"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="student.studyPermitExpireDate"
                            @input="expDatePicker = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="student.grade"
                          outlined
                          :items="[
                            '1-8',
                            '9',
                            '10',
                            '11',
                            '12',
                            'Undergraduate',
                            'Graduate',
                          ]"
                          label="Grade"
                          required
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="student.services"
                          outlined
                          :items="[
                            'Online Course',
                            'K12 Course',
                            'IELTS',
                            'SSAT/SAT',
                            'ESL',
                            'University Application',
                            'Visa',
                          ]"
                          label="Services"
                          multiple
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    type="submit"
                    x-large
                    class="mr-5 mb-10"
                    v-if="
                      profile.role == 'Admin' ||
                      profile.role == 'Manager' ||
                      profile.role == 'Consultant'
                    "
                  >
                    Update Profile
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      class="ml-2"
                      v-if="loading1"
                    ></v-progress-circular>
                  </v-btn>
                </v-card-actions>
                <v-spacer></v-spacer>
              </v-form>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="pa-4">
              <v-card-title class="d-flex align-center justify-center">
                <v-row class="d-flex align-center">
                  <v-col cols="12" md="8" sm="8">
                    <v-btn
                      outlined
                      color="primary"
                      @click="generateClassRecordsPDF"
                    >
                      Print Monthly Class Records
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <v-text-field
                      v-model="searchClass"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      class="mt-0 pa-0"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="studentClassRecords"
                :search="searchClass"
              ></v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="pa-4">
              <v-card-title class="d-flex align-center justify-center">
                <v-row class="d-flex align-center">
                  <v-col cols="12" md="8" sm="9">
                    <v-btn
                      outlined
                      color="primary"
                      style="max-width: 290px"
                      @click="generateBalanceRecordsPDF"
                    >
                      Print Monthly Balance Records
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" sm="3">
                    <v-text-field
                      v-model="searchBalance"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      class="mt-0 pa-0"
                      style="max-width: 290px"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                :headers="balanceHeaders"
                :items="studentBalanceRecords"
                :search="searchBalance"
              ></v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row class="mt-3">
                    <v-col
                      cols="12"
                      lg="4"
                      md="5"
                      class="d-flex flex-column align-center"
                    >
                      <v-form
                        @submit.prevent="addUniversityApplication"
                        class="mb-6"
                      >
                        <v-text-field
                          v-model="sampleUniversityApplication.universityName"
                          label="University Name"
                          outlined
                          required
                          dense
                          style="max-width: 200.38px"
                        ></v-text-field>
                        <v-btn
                          outlined
                          color="primary"
                          type="submit"
                          dense
                          style="height: 40px"
                        >
                          Create application
                        </v-btn>
                      </v-form>
                      <v-divider
                        class="mb-6"
                        style="max-width: 200.38px"
                      ></v-divider>
                      <v-autocomplete
                        v-model="applicationName"
                        :items="studentUniversityApplications"
                        item-text="universityName"
                        item-value="universityName"
                        clearable
                        outlined
                        dense
                        label="University Name"
                        style="max-width: 200.38px"
                        @change="onSelectApplication(applicationName)"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="8"
                      md="7"
                      class="d-flex justify-space-around"
                    >
                      <v-card
                        v-if="selectedUniversityApplication"
                        elevation="1"
                      >
                        <v-btn
                          absolute
                          bottom
                          color="primary"
                          right
                          fab
                          @click="addStep"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-card-title>
                          {{ selectedUniversityApplication.universityName }}
                          <v-spacer></v-spacer>
                          <v-btn
                            class="ma-2"
                            color="red"
                            dark
                            @click="deleteUniversityApplicationDialogOpen"
                            >Delete
                            <v-icon dark right> mdi-cancel </v-icon></v-btn
                          >
                        </v-card-title>
                        <v-card-text>
                          <div class="font-weight-bold ml-8 mb-2">Start</div>
                          <v-timeline align-top dense>
                            <v-timeline-item
                              v-for="(
                                step, i
                              ) in selectedUniversityApplication.steps"
                              :key="step.step + i"
                              small
                              :color="step.status ? 'success' : 'red'"
                              :icon="step.status ? 'mdi-check' : 'mdi-close'"
                            >
                              <v-row
                                class="d-flex flex-row justify-space-between"
                              >
                                <v-col cols="7">
                                  <div class="font-weight-normal">
                                    <strong>{{ step.deadline }}</strong>
                                  </div>
                                  <div>{{ step.step }}</div>
                                </v-col>
                                <v-col cols="5">
                                  <v-btn
                                    icon
                                    color="green"
                                    @click="markFinish(i)"
                                  >
                                    <v-icon>mdi-check</v-icon>
                                  </v-btn>
                                  <v-btn
                                    icon
                                    @click="editStepDateDialogOpen(i)"
                                  >
                                    <v-icon>mdi-calendar</v-icon>
                                  </v-btn>
                                  <v-btn
                                    icon
                                    @click="deleteUniversityApplicationStep(i)"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-timeline-item>
                          </v-timeline>
                        </v-card-text>
                      </v-card>
                      <div v-else class="d-flex flex-column">
                        <span class="text-body-1"
                          >No application selected.</span
                        >
                        <span class="text-body-1"
                          >Please select one or create a new one.</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialogAddBalance"
          persistent
          max-width="400px"
          max-height="600px"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogAddBalance = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Add Balance</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="addBalanceConfirm">Save</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <template>
              <v-form v-model="addForm">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="amountAdd"
                        outlined
                        label="Total Amount to Add"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="detailAdd"
                        outlined
                        label="Detail"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-select
                        v-model="paymentMethod"
                        outlined
                        :items="[
                          'Debit Card',
                          'Credit Card',
                          'Cash',
                          'E-transfer',
                          'Wechat Pay',
                          'Alipay',
                          'Other',
                        ]"
                        label="Payment Method"
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialogAddStep"
          persistent
          max-width="400px"
          max-height="600px"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <template>
              <v-form @submit.prevent="addUniversityApplicationStep">
                <v-toolbar dark color="primary">
                  <v-btn icon dark @click="dialogAddStep = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Add Application Step</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn dark text type="submit">Save</v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-row class="pa-6 mt-3">
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="stepDetail"
                      dense
                      label="Step detail"
                      required
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="stepDeadline"
                          label="Deadline Date"
                          autocomplete="off"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="stepDeadline"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>
            </template>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialogChargeBalance"
          persistent
          max-width="400px"
          max-height="600px"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogChargeBalance = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Charge Balance</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="chargeBalanceConfirm">Save</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <template>
              <v-form v-model="chargeForm">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="amountCharge"
                        outlined
                        label="Total Amount to Charge"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="detailCahrge"
                        outlined
                        label="Detail"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Class Schedule - {{ today }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="blue darken-1"
            @click="dialog = false"
            class="mr-3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-sheet height="700">
              <v-calendar
                ref="calendar"
                :now="today"
                :value="today"
                :events="studentClassRecords"
                :event-color="getEventColor"
                color="primary"
                type="week"
                :weekdays="weekdays"
                :first-interval="8"
                :interval-count="16"
              ></v-calendar>
            </v-sheet>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog1" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >Class Schedule - {{ nextMondayStartDate }}</span
          >
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="blue darken-1"
            @click="dialog1 = false"
            class="mr-3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-sheet height="700">
              <v-calendar
                ref="calendar"
                :now="nextMondayStartDate"
                :value="nextMondayStartDate"
                :events="studentClassRecords"
                :event-color="getEventColor"
                color="primary"
                type="week"
                :weekdays="weekdays"
                :first-interval="8"
                :interval-count="16"
              ></v-calendar>
            </v-sheet>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card id="schedule" style="display: none">
      <v-card-title>
        <span class="headline text-h5 font-weight-medium"
          >Class Schedule - {{ this.student.studentName }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-calendar
                ref="calendar"
                :events="studentClassRecords"
                :event-color="getEventColor"
                color="primary"
                type="week"
                :weekdays="weekdays"
                :first-interval="8"
                :interval-count="14"
              >
              </v-calendar>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card id="schedule1" style="display: none">
      <v-card-title>
        <span class="headline text-h5 font-weight-medium"
          >Class Schedule - {{ this.student.studentName }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-calendar
                ref="calendar"
                :events="studentClassRecords"
                :event-color="getEventColor"
                color="primary"
                type="week"
                :weekdays="weekdays"
                :start="nextMondayStartDate"
                :first-interval="8"
                :interval-count="14"
              >
              </v-calendar>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <template>
      <div class="text-center">
        <v-dialog v-model="deleteUniAppDialog" width="500">
          <v-card>
            <v-card-title class="text-h5 red lighten-2">
              Please enter the passcode
            </v-card-title>
            <v-container
              ><v-text-field
                v-model="deletePasscode"
                label="Passcode"
                required
                outlined
              ></v-text-field
            ></v-container>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="deleteUniversityApplicationDialogClose"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="validateDeleteUniAppPasscode">
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="text-center">
        <v-dialog v-model="editStepDateDialog" width="300">
          <v-card>
            <v-card-title class="text-h5 primary lighten-2">
              Please Change the Dealine
            </v-card-title>
            <v-container
              ><v-date-picker v-model="newStepDeadline"></v-date-picker
            ></v-container>
            <v-card-actions>
              <v-btn color="primary" text @click="editStepDateDialogClose">
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="editStepDateConfirm">
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <v-card
      id="classRecordsAndBalanceRecords"
      style="display: none"
      min-height="1000"
    >
      <v-card-title class="d-flex flex-column align-start ml-4">
        <span class="headline text-subtitle-1 font-weight-light">{{
          heading.title
        }}</span>
        <span class="headline text-subtitle-1 font-weight-light">{{
          heading.studentName
        }}</span>
        <span class="headline text-subtitle-1 font-weight-light">{{
          heading.date
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="mb-10" style="min-height: 500px">
            <v-col cols="12">
              <v-data-table
                :headers="itemHeaders"
                :items="itemsToBePrinted"
                hide-default-footer
              ></v-data-table>
              <span
                class="headline text-h5 font-weight-light"
                v-if="monthlyBalanceTotal"
                >Total: {{ monthlyBalanceTotal }}</span
              >
            </v-col>
          </v-row>
          <v-row class="mt-10">
            <v-spacer></v-spacer>
            <v-col>
              <img
                src="../assets/logo-horizontal.png"
                alt=""
                width="200"
                height="75"
              />
            </v-col>
          </v-row>
          <v-row class="mt-10">
            <v-spacer></v-spacer>
            <v-col class="d-flex flex-column flex-end justify-end">
              <!-- <span class="text-subtitle-1 font-weight-light"
                >LEVEL UP Learning Centre</span
              > -->
              <span class="text-subtitle-1 font-weight-light"
                >Head Office: 2390-4000 No.3 Rd, Richmond, V6X0J8</span
              >
              <span class="text-subtitle-1 font-weight-light"
                >Learning Centre: 5070-4000 No.3 Rd, Richmond V6X0J8</span
              >
              <span class="text-subtitle-1 font-weight-light"
                >Phone: 778-737-7591</span
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import firebase from "firebase/app";
import { db } from "../plugins/firebase.js";
import "firebase/storage";
import html2PDF from "jspdf-html2canvas";
import moment from "moment";
export default {
  name: "StudentPage",
  data: () => ({
    studentDocID: null,
    expDatePicker: false,
    birthDatePicker: false,
    dialogAddBalance: false,
    dialogChargeBalance: false,
    searchClass: "",
    searchBalance: "",
    addForm: false,
    chargeForm: false,
    currentWeekDay: new Date().getUTCDay(),
    currentDate: new Date().getUTCDate(),
    deleteUniAppDialog: false,
    deletePasscode: null,
    defaultDeletePasscode: "levelup",
    editStepDateDialog: false,
    newStepDeadline: null,
    newStepDeadlineIndex: null,
    student: {
      address: null,
      balance: null,
      birthday: null,
      classRecords: [],
      email: null,
      emergency: null,
      emergencyContact: null,
      englishName: null,
      grade: null,
      hourFee: null,
      phone: null,
      services: [],
      studentName: null,
      studyPermitExpireDate: null,
    },
    sampleUniversityApplication: {
      universityName: null,
      steps: [
        {
          step: "Personal Information Sheet",
          deadline: "2021-06-01",
          status: false,
        },
        {
          step: "Account Set Up",
          deadline: "2021-06-02",
          status: false,
        },
        {
          step: "Personal Statement Draft(Student)",
          deadline: "2021-06-03",
          status: false,
        },
        {
          step: "Personal Statement Final",
          deadline: "2021-06-04",
          status: false,
        },
        {
          step: "IELTS Transcript",
          deadline: "2021-06-05",
          status: false,
        },
        {
          step: "Send Academic Transcript 1st",
          deadline: "2021-06-06",
          status: false,
        },
        {
          step: "Application Completion",
          deadline: "2021-06-06",
          status: false,
        },
        {
          step: "Self Report",
          deadline: "2021-06-06",
          status: false,
        },
        {
          step: "Send Academic Transcript 2nd",
          deadline: "2021-06-06",
          status: false,
        },
        {
          step: "Pay Deposit/Decline Offer",
          deadline: "2021-06-06",
          status: false,
        },
        {
          step: "Send Academic Transcript 3rd",
          deadline: "2021-06-06",
          status: false,
        },
        {
          step: "Register Courses",
          deadline: "2021-06-06",
          status: false,
        },
      ],
    },
    defaultUniversityApplication: {
      universityName: null,
      steps: [
        {
          step: "Personal Information Sheet",
          deadline: "2021-02-01",
          status: false,
        },
        {
          step: "Account Set Up",
          deadline: "2021-03-02",
          status: false,
        },
        {
          step: "Personal Statement Draft(Student)",
          deadline: "2021-04-03",
          status: false,
        },
        {
          step: "Personal Statement Final",
          deadline: "2021-05-04",
          status: false,
        },
        {
          step: "IELTS Transcript",
          deadline: "2021-06-05",
          status: false,
        },
        {
          step: "Send Academic Transcript 1st",
          deadline: "2021-07-06",
          status: false,
        },
        {
          step: "Application Completion",
          deadline: "2021-06-06",
          status: false,
        },
        {
          step: "Self Report",
          deadline: "2021-08-06",
          status: false,
        },
        {
          step: "Send Academic Transcript 2nd",
          deadline: "2021-09-06",
          status: false,
        },
        {
          step: "Pay Deposit/Decline Offer",
          deadline: "2021-10-06",
          status: false,
        },
        {
          step: "Send Academic Transcript 3rd",
          deadline: "2021-11-06",
          status: false,
        },
        {
          step: "Register Courses",
          deadline: "2021-12-06",
          status: false,
        },
      ],
    },
    studentClassRecords: [],
    studentClassRecordsByMonth: [],
    studentBalanceRecords: [],
    studentBalanceRecordsByMonth: [],
    amountAdd: "0.00",
    detailAdd: "",
    paymentMethod: "",
    amountCharge: "0.00",
    detailCahrge: "",
    chargeMethod: "N/A",
    creatorName: "unknown",
    headers: [
      {
        text: "Class Name",
        align: "start",
        value: "name",
      },
      { text: "Instructor", value: "instructor" },
      { text: "Start Time", value: "start" },
      { text: "End Time", value: "end" },
    ],
    balanceHeaders: [
      {
        text: "Amount",
        align: "start",
        value: "amount",
      },
      { text: "Date", value: "createTime" },
      { text: "Detail", value: "detail" },
      { text: "Payment Method", value: "paymentMethod" },
      { text: "Creator", value: "createdBy" },
    ],
    profile: {
      firstName: null,
      address: null,
      mobile: null,
      lastName: null,
      role: null,
      birthday: null,
      avatarURL: null,
    },
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    selectedFile: null,
    uploading: false,
    today: "",
    dialog: false,
    dialog1: false,
    dialogAddStep: false,
    colors: [
      "light-blue lighten-2",
      "deep-purple lighten-2",
      "pink lighten-2",
      "green lighten-2",
      "purple lighten-2",
      "deep-purple lighten-1",
      "indigo lighten-1",
      "indigo lighten-2",
      "blue lighten-1",
      "light-blue lighten-1",
      "light-blue lighten-2",
      "cyan lighten-1",
      "teal lighten-1",
      "teal lighten-4",
      "green lighten-1",
      "light-green lighten-1",
      "lime lighten-1",
      "yellow lighten-1",
      "orange lighten-2",
      "brown lighten-3",
      "blue-grey lighten-4",
      "grey lighten-2",
    ],
    studentUniversityApplications: [],
    applicationName: null,
    selectedUniversityApplication: null,
    stepDetail: null,
    stepDeadline: null,
    menu: false,
    interval: {},
    value: 0,
    heading: {
      title: null,
      studentName: null,
      date: null,
    },
    itemHeaders: [],
    itemsToBePrinted: [],
    monthlyBalanceTotal: null,
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    nextMondayStartDate: new Date().toISOString().substr(0, 10),
  }),
  created() {
    this.formatDate();
    this.studentDocID = this.$route.query.id;
    this.getStudent();
    this.getProfile();
    this.getUniversityApplications();
    this.getNextMon();
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value >= this.student.balance) {
        this.value = this.student.balance;
      } else {
        this.value += this.student.balance / 18;
      }
    }, 10);
  },
  methods: {
    async getProfile() {
      const user = firebase.auth().currentUser;
      let docRef = await db.collection("profiles").doc(user.uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.profile = doc.data();
            // console.log("Document data:", doc.data());
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    async getStudent() {
      const studentDB = db.collection("students").doc(this.studentDocID);
      studentDB
        .get()
        .then((student) => {
          if (student.exists) {
            this.student = student.data();
            let classRecordsArray = [];
            student.data().classRecords.forEach((classRecord) => {
              let classRecordInfo = {
                name: classRecord.className,
                instructor: classRecord.instructor,
                start: classRecord.startTime,
                end: classRecord.endTime,
                color: this.colors[this.rnd(0, this.colors.length - 1)],
              };
              classRecordsArray.push(classRecordInfo);
            });
            this.studentClassRecords = classRecordsArray;
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
      const studentBalanceRecordsDB = db
        .collection("studentBalanceRecords")
        .doc(this.studentDocID);
      studentBalanceRecordsDB
        .get()
        .then((balanceRecords) => {
          // console.log("exists");
          if (balanceRecords.data().studentBalanceRecords) {
            this.studentBalanceRecords =
              balanceRecords.data().studentBalanceRecords;
            this.studentBalanceRecords.forEach((singleStudentBalanceRecord) => {
              singleStudentBalanceRecord.createTime =
                singleStudentBalanceRecord.createTime.substring(0, 24);
            });
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    async getUniversityApplications() {
      let universityApplications = await db
        .collection("studentUniversityApplications")
        .doc(this.studentDocID)
        .get();
      if (universityApplications.data()) {
        this.studentUniversityApplications =
          universityApplications.data().studentUniversityApplications;
      }
      // this.studentUniversityApplications = universityApplications.data().studentUniversityApplications;
      // console.log(
      //   "studentUniversityApplications ",
      //   this.studentUniversityApplications
      // );
    },
    async updateStudentInfo() {
      this.loading1 = true;
      await db.collection("students").doc(this.studentDocID).update({
        address: this.student.address,
        birthday: this.student.birthday,
        email: this.student.email,
        emergency: this.student.emergency,
        emergencyContact: this.student.emergencyContact,
        englishName: this.student.englishName,
        grade: this.student.grade,
        hourFee: this.student.hourFee,
        phone: this.student.phone,
        services: this.student.services,
        studentName: this.student.studentName,
        studyPermitExpireDate: this.student.studyPermitExpireDate,
      });
      this.getStudent();
      setTimeout(() => {
        this.loading1 = false;
      }, 1500);
    },
    async addUniversityApplication() {
      // console.log("add university application");
      // console.log(
      //   "sampleUniversityApplication ",
      //   this.sampleUniversityApplication
      // );
      if (this.sampleUniversityApplication.universityName == null) {
        alert("Please enter the university name");
      } else {
        this.studentUniversityApplications.push(
          this.sampleUniversityApplication
        );
        await db
          .collection("studentUniversityApplications")
          .doc(this.studentDocID)
          .set({
            studentUniversityApplications: this.studentUniversityApplications,
          });
        this.sampleUniversityApplication = this.defaultUniversityApplication;
        this.getUniversityApplications();
      }
    },
    async deleteUniversityApplication() {
      // console.log("delete university application");
      let index = this.studentUniversityApplications.indexOf(
        this.selectedUniversityApplication
      );
      this.studentUniversityApplications.splice(index, 1);
      await db
        .collection("studentUniversityApplications")
        .doc(this.studentDocID)
        .set({
          studentUniversityApplications: this.studentUniversityApplications,
        });
      this.sampleUniversityApplication = this.defaultUniversityApplication;
      this.getUniversityApplications();
      this.onSelectApplication(null);
    },
    addBalance() {
      this.dialogAddBalance = true;
    },
    chargeBalance() {
      this.dialogChargeBalance = true;
    },
    async addBalanceConfirm() {
      const amountAdd = Number(this.amountAdd);
      const user = firebase.auth().currentUser;
      var uid = user.uid;
      const profileDB = db.collection("profiles").doc(uid);
      await profileDB
        .get()
        .then((profile) => {
          if (profile.exists) {
            this.creatorName =
              profile.data().firstName + " " + profile.data().lastName;
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
      let newBalanceRecord = {
        studentName: this.student.studentName,
        amount: amountAdd,
        detail: this.detailAdd,
        paymentMethod: this.paymentMethod,
        createdBy: this.creatorName,
        createTime: new Date().toString(),
        classDocID: "N/A",
      };
      this.studentBalanceRecords.push(newBalanceRecord);
      await db
        .collection("students")
        .doc(this.studentDocID)
        .update({
          balance: this.student.balance + amountAdd,
        });
      await db
        .collection("studentBalanceRecords")
        .doc(this.studentDocID)
        .update({
          studentBalanceRecords: this.studentBalanceRecords,
        });
      this.closeAddBalance();
    },
    async chargeBalanceConfirm() {
      const amountCharge = Number(this.amountCharge);
      const user = firebase.auth().currentUser;
      var uid = user.uid;
      const profileDB = db.collection("profiles").doc(uid);
      await profileDB
        .get()
        .then((profile) => {
          if (profile.exists) {
            this.creatorName =
              profile.data().firstName + " " + profile.data().lastName;
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
      let newBalanceRecord = {
        studentName: this.student.studentName,
        amount: -Math.abs(amountCharge),
        detail: this.detailCahrge,
        paymentMethod: this.chargeMethod,
        createdBy: this.creatorName,
        createTime: new Date().toString(),
        classDocID: "N/A",
      };
      this.studentBalanceRecords.push(newBalanceRecord);
      await db
        .collection("students")
        .doc(this.studentDocID)
        .update({
          balance: this.student.balance - amountCharge,
        });
      await db
        .collection("studentBalanceRecords")
        .doc(this.studentDocID)
        .update({
          studentBalanceRecords: this.studentBalanceRecords,
        });
      this.closeChargeBalance();
    },
    closeAddBalance() {
      this.dialogAddBalance = false;
      this.getStudent();
    },
    closeChargeBalance() {
      this.dialogChargeBalance = false;
      this.getStudent();
    },
    filterBalanceRecordsByMonth() {
      let currentMonth = new Date().getMonth();
      let studentBalanceRecordsByMonth = this.studentBalanceRecords.filter(
        (balanceRecord) => {
          let recordMonth = new Date(balanceRecord.createTime).getMonth();
          return currentMonth == recordMonth;
        }
      );
      this.studentBalanceRecordsByMonth = studentBalanceRecordsByMonth;
    },
    filterClassRecordsByMonth() {
      let currentMonth = new Date().getMonth();
      let studentClassRecordsByMonth = this.studentClassRecords.filter(
        (classRecord) => {
          let recordMonth = new Date(classRecord.start).getMonth();
          return currentMonth == recordMonth;
        }
      );
      this.studentClassRecordsByMonth = studentClassRecordsByMonth;
    },
    onFileSelected(event) {
      this.selectedFile = event;
      // console.log(this.selectedFile);
    },
    onUpload() {
      this.uploading = true;
      const storageRef = firebase
        .storage()
        .ref("/avatars/" + this.selectedFile.name)
        .put(this.selectedFile);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.profile.avatarURL = url;
            // console.log(this.profile.avatarURL);
            const user = firebase.auth().currentUser;
            db.collection("profiles").doc(user.uid).update({
              avatarURL: url,
            });
          });
        }
      );
      this.getProfile();
      this.selectedFile = null;
      this.uploading = false;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getEventColor(event) {
      return event.color;
    },
    formatDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      this.today = [year, month, day].join("-");
      // console.log("today ", this.today);
    },
    async generatePDF() {
      this.loading3 = true;
      let hideSchdule = document.querySelector("#schedule");
      hideSchdule.style.display = "block";
      await html2PDF(hideSchdule, {
        jsPDF: {
          format: "a4",
        },
        html2canvas: {
          scrollX: 0,
          scrollY: -window.scrollY,
        },
        imageType: "image/jpeg",
        output: "class-schedule.pdf",
      });
      hideSchdule.style.display = "none";
      setTimeout(() => {
        this.loading3 = false;
      }, 1500);
    },
    async generateNextWeekPDF() {
      this.loading4 = true;
      let hideSchdule = document.querySelector("#schedule1");
      hideSchdule.style.display = "block";
      await html2PDF(hideSchdule, {
        jsPDF: {
          format: "a4",
        },
        html2canvas: {
          scrollX: 0,
          scrollY: -window.scrollY,
        },
        imageType: "image/jpeg",
        output: "class-schedule.pdf",
      });
      hideSchdule.style.display = "none";
      setTimeout(() => {
        this.loading4 = false;
      }, 1500);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async generateClassRecordsPDF() {
      await this.renderMonthlyClassRecords();
      let classRecordsCard = document.querySelector(
        "#classRecordsAndBalanceRecords"
      );
      classRecordsCard.style.display = "block";
      await html2PDF(classRecordsCard, {
        jsPDF: {
          format: "a4",
        },
        html2canvas: {
          scrollX: 0,
          scrollY: -window.scrollY,
        },
        imageType: "image/jpeg",
        output: "monthly-student-class-schedule.pdf",
      });
      classRecordsCard.style.display = "none";
      this.heading = {
        title: null,
        studentName: null,
        date: null,
      };
      this.itemHeaders = [];
      this.itemsToBePrinted = [];
      this.monthlyBalanceTotal = null;
    },
    async renderMonthlyClassRecords() {
      this.filterClassRecordsByMonth();
      // console.log("month ", this.studentClassRecordsByMonth);
      this.itemsToBePrinted = this.studentClassRecordsByMonth;
      let date = new Date().toString().substr(0, 15);
      this.heading = {
        title: "Monthly Student Class Records",
        studentName: this.student.studentName,
        date: date,
      };
      this.itemHeaders = [
        {
          text: "Class Name",
          align: "start",
          value: "name",
          class: "primary white--text",
        },
        {
          text: "Instructor",
          value: "instructor",
          class: "primary white--text",
        },
        { text: "Start Time", value: "start", class: "primary white--text" },
        { text: "End Time", value: "end", class: "primary white--text" },
      ];
    },
    async renderMonthlyBalanceRecords() {
      this.filterBalanceRecordsByMonth();
      let total = 0;
      this.studentBalanceRecordsByMonth.forEach((studentBalanceRecord) => {
        total += studentBalanceRecord.amount;
      });
      this.monthlyBalanceTotal = total;
      this.itemsToBePrinted = this.studentBalanceRecordsByMonth;
      let date = new Date().toString().substr(0, 15);
      this.heading = {
        title: "Monthly Student Balance Records",
        studentName: this.student.studentName,
        date: date,
      };
      this.itemHeaders = [
        {
          text: "Amount",
          align: "start",
          value: "amount",
          class: "primary white--text",
        },
        { text: "Date", value: "createTime", class: "primary white--text" },
        { text: "Detail", value: "detail", class: "primary white--text" },
        {
          text: "Payment Method",
          value: "paymentMethod",
          class: "primary white--text",
        },
        { text: "Creator", value: "createdBy", class: "primary white--text" },
      ];
    },
    async generateBalanceRecordsPDF() {
      await this.renderMonthlyBalanceRecords();
      let balanceRecordsCard = document.querySelector(
        "#classRecordsAndBalanceRecords"
      );
      balanceRecordsCard.style.display = "block";
      await html2PDF(balanceRecordsCard, {
        jsPDF: {
          format: "a4",
        },
        html2canvas: {
          scrollX: 0,
          scrollY: -window.scrollY,
        },
        imageType: "image/jpeg",
        output: "monthly-student-balance-records.pdf",
      });
      balanceRecordsCard.style.display = "none";
      this.heading = {
        title: null,
        studentName: null,
        date: null,
      };
      this.itemHeaders = [];
      this.itemsToBePrinted = [];
      this.monthlyBalanceTotal = null;
    },
    onSelectApplication(universityName) {
      // console.log("name ", universityName);
      let application = this.studentUniversityApplications.find((app) => {
        return app.universityName === universityName;
      });
      this.selectedUniversityApplication = application;
    },
    async markFinish(index) {
      this.selectedUniversityApplication.steps[index].status =
        !this.selectedUniversityApplication.steps[index].status;
      // console.log("mark status as true");
      this.studentUniversityApplications.forEach((universityApplication) => {
        if (
          universityApplication.universityName ==
          this.selectedUniversityApplication.universityName
        ) {
          // console.log("i am supposed to get here!");
          universityApplication.steps[index].status =
            this.selectedUniversityApplication.steps[index].status;
        }
      });
      await db
        .collection("studentUniversityApplications")
        .doc(this.studentDocID)
        .set({
          studentUniversityApplications: this.studentUniversityApplications,
        });
      this.getUniversityApplications();
    },
    addStep() {
      this.dialogAddStep = true;
      // console.log("add More step");
    },
    async addUniversityApplicationStep() {
      let newStep = {
        deadline: this.stepDeadline,
        status: false,
        step: this.stepDetail,
      };
      this.stepDeadline = null;
      this.stepDetail = null;
      this.studentUniversityApplications.forEach((universityApplication) => {
        if (
          universityApplication.universityName ==
          this.selectedUniversityApplication.universityName
        ) {
          // console.log("i am supposed to get here!");
          universityApplication.steps.push(newStep);
          this.sortUniversityApplicationStepsByDate(
            universityApplication.steps
          );
        }
      });
      // console.log("all app after added", this.studentUniversityApplications);
      await db
        .collection("studentUniversityApplications")
        .doc(this.studentDocID)
        .set({
          studentUniversityApplications: this.studentUniversityApplications,
        });
      this.getUniversityApplications();
      this.reselectUniversityApplication();
      this.closeAddStep();
    },
    closeAddStep() {
      this.dialogAddStep = false;
    },
    async deleteUniversityApplicationStep(index) {
      // console.log("delete a specific step");
      this.studentUniversityApplications.forEach((universityApplication) => {
        if (universityApplication.universityName == this.applicationName) {
          // console.log("i am supposed to get here!");
          universityApplication.steps.splice(index, 1);
        }
      });
      await db
        .collection("studentUniversityApplications")
        .doc(this.studentDocID)
        .set({
          studentUniversityApplications: this.studentUniversityApplications,
        });
      this.getUniversityApplications();
      this.reselectUniversityApplication();
      this.closeAddStep();
    },
    reselectUniversityApplication() {
      this.onSelectApplication(
        this.selectedUniversityApplication.universityName
      );
    },
    validateDeleteUniAppPasscode() {
      if (this.deletePasscode == this.defaultDeletePasscode) {
        this.deleteUniversityApplication();
        this.resetPasscode();
        this.deleteUniversityApplicationDialogClose();
      } else {
        alert("The passcode entered does not match");
        this.resetPasscode();
      }
    },
    deleteUniversityApplicationDialogOpen() {
      this.deleteUniAppDialog = true;
    },
    deleteUniversityApplicationDialogClose() {
      this.deleteUniAppDialog = false;
      this.resetPasscode();
    },
    resetPasscode() {
      this.deletePasscode = null;
    },
    editStepDateDialogOpen(index) {
      this.newStepDeadlineIndex = index;
      this.editStepDateDialog = true;
    },
    async editStepDateConfirm() {
      let index = this.newStepDeadlineIndex;
      // console.log(
      //   "laaaaaaaaaa",
      //   this.selectedUniversityApplication.steps[index]
      // );
      this.selectedUniversityApplication.steps[index].deadline =
        this.newStepDeadline;
      this.studentUniversityApplications.forEach((universityApplication) => {
        if (
          universityApplication.universityName ==
          this.selectedUniversityApplication.universityName
        ) {
          universityApplication.steps[index].deadline = this.newStepDeadline;
          this.sortUniversityApplicationStepsByDate(
            universityApplication.steps
          );
        }
      });
      await db
        .collection("studentUniversityApplications")
        .doc(this.studentDocID)
        .set({
          studentUniversityApplications: this.studentUniversityApplications,
        });
      this.getUniversityApplications();
      this.editStepDateDialogClose();
    },
    editStepDateDialogClose() {
      this.newStepDeadline = null;
      this.newStepDeadlineIndex = null;
      this.editStepDateDialog = false;
    },

    sortUniversityApplicationStepsByDate(stepList) {
      stepList.sort(function (a, b) {
        return new Date(a.deadline) - new Date(b.deadline);
      });
    },
    getNextMon() {
      let currentDate = new Date();
      var nextMon = moment(currentDate)
        .day(1 + 7)
        .format("YYYY-MM-DD");
      // console.log("nextMon ", nextMon);
      this.nextMondayStartDate = nextMon;
    },
  },
};
</script>
<style scoped></style>

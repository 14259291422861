<template>
  <v-container>
      <v-row>
        <v-col cols="12" md="4" sm="6">
          <v-card elevation="0" min-height="708px" class="rounded-lg pl-3 pr-3">
            <v-card-title class="text-body-1 black--text font-weight-light"
              >To do</v-card-title
            >
            <div class="d-flex justify-center mb-4">
              <v-btn outlined color="primary" block @click="dialog = true">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
            <div justify="center">
              <v-expansion-panels multiple focusable>
                <draggable
                  v-model="todosIncompleted"
                  group="tasks"
                  class="drag-area"
                  @change="updateTodo"
                >
                  <v-expansion-panel
                    v-for="todo in todosIncompleted"
                    :key="todo.id"
                    :data-id="todo.id"
                    class="item"
                  >
                    <v-expansion-panel-header
                      v-if="todo.isUrgent"
                      disable-icon-rotate
                    >
                      <template v-slot:actions>
                        <v-btn @click="deleteTodo(todo.id)" icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ todo.name }}
                        <v-icon color="error" class="ml-2"
                          >mdi-clock-fast</v-icon
                        ></span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-header v-else disable-icon-rotate>
                      <template v-slot:actions>
                        <v-btn @click="deleteTodo(todo.id)" icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      {{ todo.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="mb-2">{{ todo.description }}</div>
                      <div v-if="todo.isUrgent">
                        <v-alert dense outlined type="error">
                          Finish by <strong>{{ todo.deadline }}</strong>
                        </v-alert>
                      </div>
                      <div v-else>
                        <v-alert dense outlined>
                          Finish by <strong>{{ todo.deadline }}</strong>
                        </v-alert>
                      </div>
                      <div v-if="todo.createdBy != undefined">
                        Created by: {{ todo.createdBy }}
                      </div>
                      <div v-if="todo.updatedBy != undefined">
                        Updated by: {{ todo.updatedBy }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </draggable>
              </v-expansion-panels>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-card
            elevation="0"
            min-height="708px"
            class="rounded-lg pl-3 pr-3 mb-3"
          >
            <v-card-title class="text-body-1 black--text font-weight-light"
              >In progress</v-card-title
            >
            <div justify="center">
              <v-expansion-panels multiple focusable>
                <draggable
                  v-model="todosInProgress"
                  group="tasks"
                  class="drag-area"
                  @change="updateTodo"
                >
                  <v-expansion-panel
                    v-for="todo in todosInProgress"
                    :key="todo.id"
                    :data-id="todo.id"
                    class="item"
                  >
                    <v-expansion-panel-header
                      v-if="todo.isUrgent"
                      disable-icon-rotate
                    >
                      <template v-slot:actions>
                        <v-btn @click="deleteTodo(todo.id)" icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ todo.name }}
                        <v-icon color="error" class="ml-2"
                          >mdi-clock-fast</v-icon
                        ></span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-header v-else disable-icon-rotate>
                      <template v-slot:actions>
                        <v-btn @click="deleteTodo(todo.id)" icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      {{ todo.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="mb-2">{{ todo.description }}</div>
                      <div v-if="todo.isUrgent">
                        <v-alert dense outlined type="error">
                          Finish by <strong>{{ todo.deadline }}</strong>
                        </v-alert>
                      </div>
                      <div v-else>
                        <v-alert dense outlined>
                          Finish by <strong>{{ todo.deadline }}</strong>
                        </v-alert>
                      </div>
                      <div v-if="todo.createdBy != undefined">
                        Created by: {{ todo.createdBy }}
                      </div>
                      <div v-if="todo.updatedBy != undefined">
                        Updated by: {{ todo.updatedBy }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </draggable>
              </v-expansion-panels>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="6">
          <v-card elevation="0" min-height="708px" class="rounded-lg pl-3 pr-3">
            <v-card-title class="text-body-1 black--text font-weight-light"
              >Completed</v-card-title
            >
            <div justify="center">
              <v-expansion-panels multiple focusable>
                <draggable
                  v-model="todosCompleted"
                  group="tasks"
                  class="drag-area"
                  @change="updateTodo"
                >
                  <v-expansion-panel
                    v-for="todo in todosCompleted"
                    :key="todo.id"
                    :data-id="todo.id"
                    class="item"
                  >
                    <v-expansion-panel-header
                      v-if="todo.isUrgent"
                      disable-icon-rotate
                    >
                      <template v-slot:actions>
                        <v-btn @click="deleteTodo(todo.id)" icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ todo.name }}
                        <v-icon color="error" class="ml-2"
                          >mdi-clock-fast</v-icon
                        ></span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-header v-else disable-icon-rotate>
                      <template v-slot:actions>
                        <v-btn @click="deleteTodo(todo.id)" icon>
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      {{ todo.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="mb-2">{{ todo.description }}</div>
                      <div v-if="todo.isUrgent">
                        <v-alert dense outlined type="error">
                          Finish by <strong>{{ todo.deadline }}</strong>
                        </v-alert>
                      </div>
                      <div v-else>
                        <v-alert dense outlined>
                          Finish by <strong>{{ todo.deadline }}</strong>
                        </v-alert>
                      </div>
                      <div v-if="todo.createdBy != undefined">
                        Created by: {{ todo.createdBy }}
                      </div>
                      <div v-if="todo.updatedBy != undefined">
                        Updated by: {{ todo.updatedBy }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </draggable>
              </v-expansion-panels>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" persistent max-width="600px"
        ><v-card>
          <v-form @submit.prevent="addTodo">
            <v-card-title>
              <span class="headline">New Todo</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="pa-4">
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-text-field
                      v-model="name"
                      label="Todo Title"
                      required
                      autocomplete="off"
                      outlined
                      dense
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <vs-checkbox v-model="isUrgent" danger>
                      Urgent Todo
                    </vs-checkbox>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-text-field
                      v-model="details"
                      label="Description"
                      required
                      autocomplete="off"
                      outlined
                      dense
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="deadlineDate"
                          label="Deadline Date"
                          prepend-icon="mdi-calendar"
                          autocomplete="off"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          :rules="nameRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="deadlineDate"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="menu"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="deadlineTime"
                          label="Deadline Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          :rules="nameRules"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu1"
                        v-model="deadlineTime"
                        full-width
                        @click:minute="$refs.menu.save(deadlineTime)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn type="submit" color="primary" text @click="dialog = false">
                Add Todo
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
  </v-container>
</template>

<script>
import { db } from "../plugins/firebase.js";
import firebase from "firebase/app";
import draggable from "vuedraggable";
export default {
  name: "Todos",
  components: {
    draggable,
  },
  data: () => ({
    todos: [],
    dialog: false,
    name: null,
    details: null,
    deadlineDate: null,
    deadlineTime: null,
    isUrgent: false,
    todosIncompleted: [],
    todosInProgress: [],
    todosCompleted: [],
    lengthIncompleted: null,
    lengthInprogress: null,
    lengthCompleted: null,
    profile: {
      firstName: null,
      address: null,
      mobile: null,
      lastName: null,
      role: null,
      birthday: null,
      avatarURL: null,
    },
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    menu1: false,
    time: null,
    nameRules: [(v) => !!v || "Required"],
  }),
  beforeMount() {
    this.getProfile();
    this.getTodos();
  },
  methods: {
    async getTodos() {
      let snapshot = await db.collection("todos").get();
      let todos = [];
      snapshot.forEach((doc) => {
        let todo = {};
        todo.id = doc.id;
        todo.name = doc.data().title;
        todo.description = doc.data().description;
        todo.isUrgent = doc.data().isUrgent;
        todo.status = doc.data().status;
        todo.deadline = doc.data().deadline;
        todo.createdBy = doc.data().createdBy;
        todo.updatedBy = doc.data().updatedBy;
        todos.push(todo);
      });
      this.todos = todos;
      this.todosIncompleted = this.todos.filter((t) => t.status == -1);
      this.todosInProgress = this.todos.filter((t) => t.status == 0);
      this.todosCompleted = this.todos.filter((t) => t.status == 1);
      this.lengthIncompleted = this.todosIncompleted.length;
      this.lengthInprogress = this.todosInProgress.length;
      this.lengthCompleted = this.todosCompleted.length;
      // console.log("lengthIncompleted ", this.lengthIncompleted);
      // console.log("lengthInprogress ", this.lengthInprogress);
      // console.log("lengthCompleted ", this.lengthCompleted);
    },
    async getProfile() {
      const user = firebase.auth().currentUser;
      let docRef = await db.collection("profiles").doc(user.uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.profile = doc.data();
            // console.log("Document data:", doc.data());
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    async deleteTodo(id) {
      // console.log("delete", id);
      await db.collection("todos").doc(id).delete();
      this.getTodos();
    },
    async addTodo() {
      // console.log("title: ", this.name);
      // console.log("details: ", this.details);
      // console.log("deadlineDate: ", this.deadlineDate);
      // console.log("deadlineTime: ", this.deadlineTime);
      // console.log("isUrgent: ", this.isUrgent);
      if (this.name && this.details && this.deadlineDate && this.deadlineTime) {
        await db.collection("todos").add({
          title: this.name,
          description: this.details,
          deadline: this.deadlineDate + " " + this.deadlineTime,
          isUrgent: this.isUrgent,
          createTime: new Date().toString(),
          createdBy: this.profile.firstName + " " + this.profile.lastName,
          status: -1,
        });
        this.getTodos();
        (this.name = ""),
          (this.details = ""),
          (this.deadlineDate = ""),
          (this.deadlineTime = ""),
          (this.isUrgent = false);
      } else {
        alert("You must enter todo name, details, and deadline");
      }
    },
    async updateTodo(evt) {
      let movedTodo = evt.removed && evt.removed.element;
      if (movedTodo) {
        // console.log("moved", movedTodo);
        // console.log("len incomp after", this.todosIncompleted.length);
        // console.log("len inpro after", this.todosInProgress.length);
        // console.log("len comp after", this.todosCompleted.length);
        if (this.todosIncompleted.length > this.lengthIncompleted) {
          await db
            .collection("todos")
            .doc(movedTodo.id)
            .update({
              status: -1,
              updatedBy: this.profile.firstName + " " + this.profile.lastName,
            });
        }
        if (this.todosInProgress.length > this.lengthInprogress) {
          await db
            .collection("todos")
            .doc(movedTodo.id)
            .update({
              status: 0,
              updatedBy: this.profile.firstName + " " + this.profile.lastName,
            });
        }
        if (this.todosCompleted.length > this.lengthCompleted) {
          await db
            .collection("todos")
            .doc(movedTodo.id)
            .update({
              status: 1,
              updatedBy: this.profile.firstName + " " + this.profile.lastName,
            });
        }
        this.getTodos();
      }
    },
  },
};
</script>

<style scoped>
.drag-area {
  width: 100%;
  min-height: 708px;
}
</style>

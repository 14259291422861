import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md'
    },
    theme: {
        themes: {
            light: {
                primary: '#6c5dd3',
                secondary: '#818392',
                accent: '#8c9eff',
                error: '#b71c1c',
                background: '#fbfbfc'
            },
        },
    },
});

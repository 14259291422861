<template>
  <v-container class="fill-height">
    <v-row class="justify-center align-center flex-column animate__animated animate__flipInX">
      <div>
        <img src="../assets/logo.png" alt="Level Up" />
      </div>
      <v-col cols="6" lg="6" md="6" sm="6" class="d-lg-flex flex-column">
        <form>
          <div class="mb-4 text-h5" id="email-container">
            <vs-input
              color="#6c5dd3"
              border
              v-model="email"
              placeholder="Email"
            >
              <template #icon>
                <i class="bx bx-mail-send"></i>
              </template>
              <template v-if="!validEmail && email !== ''" #message-danger>
                Email Invalid
              </template>
            </vs-input>
          </div>
          <div class="mb-10 text-h5" id="password-container">
            <vs-input
              color="#6c5dd3"
              border
              type="password"
              v-model="password"
              placeholder="Password"
              :visiblePassword="hasVisiblePassword"
              @click-icon="hasVisiblePassword = !hasVisiblePassword"
            >
              <template #icon>
                <i v-if="!hasVisiblePassword" class="bx bx-show-alt"></i>
                <i v-else class="bx bx-hide"></i>
              </template>
            </vs-input>
          </div>
          <v-btn
            color="primary"
            elevation="5"
            outlined
            raised
            block
            type="submit"
            class="font-weight-medium rounded-lg"
            @click.prevent="signIn()"
            >Sign In</v-btn
          >
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    email: "",
    password: "",
    hasVisiblePassword: false,
  }),
  computed: {
    validEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },
  },
  methods: {
    ...mapActions(["signInAction"]),
    signIn() {
      // console.log(this.email);
      this.signInAction({ email: this.email, password: this.password });
    },
  },
};
</script>
<style>
#email-container input {
  width: 100%;
}
#password-container input {
  width: 100%;
}
</style>>

<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>
        <v-row>
          <v-col cols="12" lg="4" md="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="pa-0"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            lg="2"
            md="3"
            sm="6"
            class="d-flex justify-center"
            v-if="role == 'Admin' || role == 'Manager' || role == 'Consultant'"
          >
            <v-select
              v-model="registrationStatusSelected"
              :items="registrationStatusOptions"
              color="primary"
              label="Status"
              style="max-width: 170.95px; height: 40px"
              dense
              outlined
              @change="switchRegistrationStatus"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="6" class="d-flex justify-center">
            <v-btn
              color="primary"
              @click="switchStatus"
              class="mb-2"
              style="width: 170.95px"
              v-if="
                role == 'Admin' || role == 'Manager' || role == 'Consultant'
              "
            >
              Change Status
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            lg="2"
            md="3"
            sm="6"
            class="d-flex justify-center"
            v-if="role == 'Admin' || role == 'Manager' || role == 'Consultant'"
          >
            <v-dialog v-model="dialog" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  style="width: 170.95px"
                >
                  New Student
                </v-btn>
              </template>
              <v-card>
                <v-card-title> Student Information </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="firstName"
                          label="First name*"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="englishName"
                          label="English name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="lastName"
                          label="Last name*"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateBirth"
                              label="Date of Birth*"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              :rules="nameRules"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateBirth"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="dateExp"
                              label="Study Permit Expiry Date"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateExp"
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="phoneNumber"
                          label="Phone Number*"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="email"
                          label="Email*"
                          :rules="nameRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="address"
                          label="Address"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="grade"
                          :items="[
                            '1-8',
                            '9',
                            '10',
                            '11',
                            '12',
                            'Undergraduate',
                            'Graduate',
                          ]"
                          label="Grade*"
                          required
                          :rules="nameRules"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="services"
                          :items="[
                            'Online Course',
                            'K12 Course',
                            'IELTS',
                            'SSAT/SAT',
                            'ESL',
                            'University Application',
                            'Visa',
                          ]"
                          label="Services"
                          multiple
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="addStudent">
                    Save
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      class="ml-2"
                      v-if="loading2"
                    ></v-progress-circular>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="2" md="3" sm="6" class="d-flex justify-center">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="deleteSelectedStudents"
              v-if="
                role == 'Admin' || role == 'Manager' || role == 'Consultant'
              "
            >
              Delete Selected
            </v-btn>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteStudentConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeleteSelected" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDeleteSelected"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteSelectedStudentsConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :search="search"
        :items="studentsShowed"
        item-key="id"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon>
            <router-link :to="{ name: 'studentPage', query: { id: item.id } }"
              ><v-icon small class="mr-2"> mdi-pencil </v-icon></router-link
            >
          </v-btn>
          <v-btn icon>
            <v-icon
              color="primary"
              small
              @click="deleteStudent(item)"
              v-if="
                role == 'Admin' || role == 'Manager' || role == 'Consultant'
              "
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-row>
      <v-col cols="12" md="4" sm="12" class="d-flex justify-end"> </v-col>
      <v-col cols="12" md="4" sm="12" class="d-flex justify-end">
        <v-btn
          color="primary"
          @click="uploadPDFAndStoreDB"
          class="mt-2 mr-2"
          v-if="role == 'Admin' || role == 'Manager' || role == 'Consultant'"
        >
          Send Weekly Class Schedule
          <v-progress-circular
            indeterminate
            color="white"
            class="ml-2"
            v-if="loading1"
          ></v-progress-circular>
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="d-flex justify-end">
        <v-btn
          color="primary"
          @click="uploadNextWeekPDFAndStoreDB"
          class="mt-2"
          v-if="role == 'Admin' || role == 'Manager' || role == 'Consultant'"
        >
          Send Next Week Class Schedule
          <v-progress-circular
            indeterminate
            color="white"
            class="ml-2"
            v-if="loading3"
          ></v-progress-circular>
        </v-btn>
      </v-col>
    </v-row>
    <v-card id="schedule" style="display: none">
      <v-card-title>
        <span class="headline text-h5 font-weight-medium"
          >LEVEL UP Learning Centre - Class Schedule</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-calendar
                ref="calendar"
                :events="studentClassRecords"
                color="primary"
                type="week"
                :weekdays="weekdays"
                :first-interval="8"
                :interval-count="14"
              >
              </v-calendar>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card id="schedule1" style="display: none">
      <v-card-title>
        <span class="headline text-h5 font-weight-medium"
          >LEVEL UP Learning Centre - Class Schedule</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-calendar
                ref="calendar"
                :events="studentClassRecords"
                color="primary"
                type="week"
                :weekdays="weekdays"
                :start="nextMondayStartDate"
                :first-interval="8"
                :interval-count="14"
              >
              </v-calendar>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { db } from "../plugins/firebase.js";
import html2PDF from "jspdf-html2canvas";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/storage";
import moment from "moment";
export default {
  data: () => ({
    search: "",
    attrs: "",
    on: "",
    selected: [],
    dialog: false,
    dialogDelete: false,
    dialogDeleteSelected: false,
    menu: false,
    modal: false,
    menu2: false,
    role: null,
    // fields of a student
    firstName: null,
    englishName: null,
    lastName: null,
    dateBirth: null,
    dateExp: null,
    phoneNumber: null,
    email: null,
    address: null,
    grade: null,
    services: null,
    id: null,
    newStudentID: null,
    return: {
      componentKey: 0,
    },
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone", filterable: false },
      { text: "Balance", value: "balance", filterable: false },
      { text: "Actions", value: "actions", filterable: false, sortable: false },
    ],
    registrationStatusSelected: "Registered",
    registrationStatusOptions: ["Registered", "Graduated", "All"],
    // varaibales
    studentToBeDeleted: null,
    student: null,
    item: null,
    studentsShowed: [],
    students: [],
    registeredStudents: [],
    unregisteredStudents: [],
    editedIndex: -1,
    editedStudent: {
      name: "",
      englishName: "",
      email: 0,
      phone: 0,
      balance: 0,
      id: "",
    },
    defaultStudent: {
      name: "",
      englishName: "",
      email: 0,
      phone: 0,
      balance: 0,
      id: "",
    },
    defaultPassword: process.env.VUE_APP_DEFAULT_PASSWORD_STUDENT,
    studentClassRecords: [],
    loading: false,
    loading1: false,
    loading2: false,
    loading3: false,
    receiver: null,
    receiverEmail: null,
    nameRules: [(v) => !!v || "Required"],
    nextMondayStartDate: new Date().toISOString().substr(0, 10),
    weekdays: [1, 2, 3, 4, 5, 6, 0],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    // this.scheduleSend();
    this.initialize();
  },

  methods: {
    initialize() {
      this.selected = [];
      this.getStudents();
      this.getNextMon();
    },
    // goStudentPage() {
    //   // console.log("test");
    // },

    async getStudents() {
      let currentUser = firebase.auth().currentUser;
      let profile = db.collection("profiles").doc(currentUser.uid);
      await profile.get().then((doc) => {
        this.role = doc.data().role;
      });
      let registeredStudents = [];
      let unregisteredStudents = [];
      let studentsDB = await db.collection("students").get();
      let studentsList = [];
      if (
        this.role == "Admin" ||
        this.role == "Manager" ||
        this.role == "Consultant"
      ) {
        studentsDB.forEach((doc) => {
          let student = {};
          let classRecordsArray = [];
          student.id = doc.id;
          if (doc.data().englishName) {
            student.name =
              doc.data().studentName + " (" + doc.data().englishName + ")";
          } else {
            student.name = doc.data().studentName;
          }
          // student.name = doc.data().studentName;
          student.englishName = doc.data().englishName;
          student.email = doc.data().email;
          student.balance = doc.data().balance;
          student.phone = this.formatPhoneNumber(doc.data().phone);
          student.registrationStatus = doc.data().registrationStatus;
          // console.log(student.registrationStatus);
          if (doc.data().registrationStatus) {
            registeredStudents.push(student);
          } else {
            unregisteredStudents.push(student);
          }
          doc.data().classRecords.forEach((classRecord) => {
            let classRecordInfo = {
              name: classRecord.className,
              instructor: classRecord.instructor,
              start: classRecord.startTime,
              end: classRecord.endTime,
            };
            classRecordsArray.push(classRecordInfo);
          });
          student.classRecords = classRecordsArray;
          studentsList.push(student);
        });
      } else if (this.role == "Student") {
        studentsDB.forEach((doc) => {
          if (doc.id == currentUser.uid) {
            let student = {};
            let classRecordsArray = [];
            student.id = doc.id;
            if (doc.data().englishName) {
              student.name =
                doc.data().studentName + " (" + doc.data().englishName + ")";
            } else {
              student.name = doc.data().studentName;
            }
            // student.name = doc.data().studentName;
            student.englishName = doc.data().englishName;
            student.email = doc.data().email;
            student.balance = doc.data().balance;
            student.phone = this.formatPhoneNumber(doc.data().phone);
            student.registrationStatus = doc.data().registrationStatus;
            // console.log(student.registrationStatus);
            if (doc.data().registrationStatus) {
              registeredStudents.push(student);
            } else {
              unregisteredStudents.push(student);
            }
            doc.data().classRecords.forEach((classRecord) => {
              let classRecordInfo = {
                name: classRecord.className,
                instructor: classRecord.instructor,
                start: classRecord.startTime,
                end: classRecord.endTime,
              };
              classRecordsArray.push(classRecordInfo);
            });
            student.classRecords = classRecordsArray;
            studentsList.push(student);
          }
        });
      }
      this.students = studentsList;
      this.registeredStudents = registeredStudents;
      this.unregisteredStudents = unregisteredStudents;
      this.studentsShowed = registeredStudents;
      // console.log("registered", this.registeredStudents);
      // console.log("graduated", this.unregisteredStudents);
      // console.log("students ", this.students);
    },

    async addStudent() {
      if (
        this.firstName &&
        this.lastName &&
        this.dateBirth &&
        this.email &&
        this.phoneNumber &&
        this.grade
      ) {
        this.loading2 = true;
        let studentUserID = null;
        // api
        let password = this.defaultPassword;
        var addUserSetRole = firebase
          .functions()
          .httpsCallable("createUserAndSetRole");
        var data = {
          email: this.email,
          password: password,
          firstName: this.firstName,
          lastName: this.lastName,
          role: "Student",
          address: this.address,
          mobile: this.phoneNumber,
          birthday: this.dateBirth,
        };
        await addUserSetRole(data)
          .then(function (result) {
            console.log(result.data);
            studentUserID = result.data;
            console.log(studentUserID);
          })
          .catch(function (error) {
            console.log(error);
          });
        // api
        let student = {
          studentName: this.firstName + " " + this.lastName,
          englishName: this.englishName,
          birthday: this.dateBirth,
          studyPermitExpireDate: this.dateExp,
          phone: this.phoneNumber,
          email: this.email,
          address: this.address,
          grade: this.grade,
          services: this.services,
          balance: 0,
          emergency: "",
          emergencyContact: "",
          classRecords: [],
          hourFee: 0,
          registrationStatus: true,
        };
        await db.collection("students").doc(studentUserID).set(student);
        await db.collection("studentBalanceRecords").doc(studentUserID).set({
          studentBalanceRecords: [],
        });
        await db
          .collection("studentUniversityApplications")
          .doc(studentUserID)
          .set({
            studentUniversityApplications: [],
          });
      } else {
        alert("You must enter all required fields.");
      }
      this.loading2 = false;
      this.close();
    },

    async switchStatus() {
      // console.log(this.selected);
      if (this.selected.length == 0) {
        alert("Please select students to change status");
      }
      this.selected.forEach(async (selectedStudent) => {
        await db.collection("students").doc(selectedStudent.id).update({
          registrationStatus: !selectedStudent.registrationStatus,
        });
      });
      this.registrationStatusSelected = "All";
      this.initialize();
    },

    deleteStudent(student) {
      this.studentToBeDeleted = student;
      this.dialogDelete = true;
    },

    async deleteStudentConfirm() {
      // console.log(this.studentToBeDeleted);
      await db.collection("students").doc(this.studentToBeDeleted.id).delete();
      // Delete student balance record after student is removed
      await db
        .collection("studentBalanceRecords")
        .doc(this.studentToBeDeleted.id)
        .delete();
      await db
        .collection("studentUniversityApplications")
        .doc(this.studentToBeDeleted.id)
        .delete();
      await db.collection("profiles").doc(this.studentToBeDeleted.id).delete();
      this.closeDelete();
    },

    deleteSelectedStudents() {
      if (this.selected.length == 0) {
        alert("Please select at least one student to delete");
      } else {
        // console.log(this.selected);
        this.dialogDeleteSelected = true;
      }
    },

    async deleteSelectedStudentsConfirm() {
      this.selected.forEach((selectedStudent) => {
        db.collection("students").doc(selectedStudent.id).delete();
        // Delete student balance record after student is removed
        db.collection("studentBalanceRecords").doc(selectedStudent.id).delete();
        db.collection("studentUniversityApplications")
          .doc(selectedStudent.id)
          .delete();
        db.collection("profiles").doc(selectedStudent.id).delete();
      });
      this.closeDeleteSelected();
    },

    close() {
      this.dialog = false;
      this.initialize();
    },

    closeDeleteSelected() {
      this.dialogDeleteSelected = false;
      this.initialize();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.studentToBeDeleted = null;
      this.initialize();
    },

    switchRegistrationStatus() {
      switch (this.registrationStatusSelected) {
        case "All":
          this.studentsShowed = this.students;
          break;
        case "Registered":
          this.studentsShowed = this.registeredStudents;
          break;
        case "Graduated":
          this.studentsShowed = this.unregisteredStudents;
          break;
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.students[this.editedIndex], this.editedItem);
      } else {
        this.students.push(this.editedItem);
      }
      this.close();
    },
    async generatePDF() {
      // console.log("generatePDF is called");
      this.loading = true;
      let hideSchdule = document.querySelector("#schedule");
      for (var i = 0; i < this.students.length; i++) {
        hideSchdule.style.display = "block";
        this.studentClassRecords = this.students[i].classRecords;
        let data = await html2PDF(hideSchdule, {
          jsPDF: {
            format: "a4",
          },
          html2canvas: {
            scrollX: 0,
            scrollY: -window.scrollY,
          },
          imageType: "image/jpeg",
          output: "class-schedule-" + this.students[i].name + ".pdf",
        });
        let file = data.output("blob");
        console.log("file ", file);
        hideSchdule.style.display = "none";
      }
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
    async uploadPDFAndStoreDB() {
      if (this.selected.length == 0) {
        alert("Please select students to send this week class schedule.");
      } else {
        // console.log("uploadPDFAndStoreDB is called");
        let hideSchdule = document.querySelector("#schedule");
        this.loading1 = true;
        // console.log("pdfData1");
        for (var i = 0; i < this.selected.length; i++) {
          // console.log("pdfData2");
          this.studentClassRecords = this.selected[i].classRecords;
          // console.log(this.students[i]);
          let fileName = "class-schedule-" + this.selected[i].name + " .pdf";
          let studentID = this.selected[i].id;
          let studentName = this.selected[i].name;
          let studentEmail = this.selected[i].email;
          // console.log("pdfData3");
          hideSchdule.style.display = "block";
          let data = await html2PDF(hideSchdule, {
            jsPDF: {
              format: "a4",
            },
            html2canvas: {
              scrollX: 0,
              scrollY: -window.scrollY,
            },
            imageType: "image/jpeg",
            output: fileName,
          });
          hideSchdule.style.display = "none";
          // console.log("pdfData4");
          let pdfData = await data.output("blob");
          // console.log("pdfData ", pdfData);
          const storageRef = firebase
            .storage()
            .ref("/class-schedule/" + fileName)
            .put(pdfData);
          storageRef.on(
            `state_changed`,
            (snapshot) => {
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  console.log("Upload is paused");
                  break;
                case firebase.storage.TaskState.RUNNING:
                  console.log("Upload is running");
                  break;
              }
            },
            (error) => {
              console.log(error.message);
            },
            () => {
              storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
                // console.log("url ", url);
                await db.collection("classSchedulePDFs").doc(studentID).set({
                  studentID: studentID,
                  name: studentName,
                  email: studentEmail,
                  pdfURL: url,
                  createTime: new Date().toString(),
                });
              });
            }
          );
        }
        // hideSchdule.style.display = "none";
        setTimeout(() => {
          this.loading1 = false;
        }, 1500);
      }
    },
    getNextMon() {
      let currentDate = new Date();
      var nextMon = moment(currentDate)
        .day(1 + 7)
        .format("YYYY-MM-DD");
      // console.log("nextMon ", nextMon);
      this.nextMondayStartDate = nextMon;
    },
    async uploadNextWeekPDFAndStoreDB() {
      // console.log(moment().isoWeekday());
      if (this.selected.length > 0) {
        // console.log("uploadPDFAndStoreDB is called");
        let hideSchdule = document.querySelector("#schedule1");
        this.loading3 = true;
        // console.log("pdfData1");
        for (var i = 0; i < this.selected.length; i++) {
          // console.log("pdfData2");
          this.studentClassRecords = this.selected[i].classRecords;
          // console.log(this.students[i]);
          let fileName = "class-schedule-" + this.selected[i].name + " .pdf";
          let studentID = this.selected[i].id;
          let studentName = this.selected[i].name;
          let studentEmail = this.selected[i].email;
          // console.log("pdfData3");
          hideSchdule.style.display = "block";
          let data = await html2PDF(hideSchdule, {
            jsPDF: {
              format: "a4",
            },
            html2canvas: {
              scrollX: 0,
              scrollY: -window.scrollY,
            },
            imageType: "image/jpeg",
            output: fileName,
          });
          hideSchdule.style.display = "none";
          // console.log("pdfData4");
          let pdfData = await data.output("blob");
          // console.log("pdfData ", pdfData);
          const storageRef = firebase
            .storage()
            .ref("/class-schedule/" + fileName)
            .put(pdfData);
          storageRef.on(
            `state_changed`,
            (snapshot) => {
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  console.log("Upload is paused");
                  break;
                case firebase.storage.TaskState.RUNNING:
                  console.log("Upload is running");
                  break;
              }
            },
            (error) => {
              console.log(error.message);
            },
            () => {
              storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
                // console.log("url ", url);
                await db.collection("classSchedulePDFs").doc(studentID).set({
                  studentID: studentID,
                  name: studentName,
                  email: studentEmail,
                  pdfURL: url,
                  createTime: new Date().toString(),
                });
              });
            }
          );
        }
        // hideSchdule.style.display = "none";
        setTimeout(() => {
          this.loading3 = false;
        }, 1500);
      } else {
        alert("Please select students to send next week class schedule.");
      }
    },
  },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs
          fixed-tabs
          color="primary"
          next-icon="mdi-arrow-right-bold-box-outline"
          show-arrows
        >
          <v-tab> Account Settings </v-tab>
          <v-tab> Other Settings </v-tab>
          <v-tab-item>
            <v-container class="d-flex justify-center mt-6 mb-6">
              <v-card elevation="2" class="pa-2 mt-2" max-width="500">
                <v-card-title>
                  <span class="text-h5 font-weight-light mb-2"
                    >Create an account</span
                  >
                </v-card-title>
                <v-card-text>
                  <v-form @submit.prevent="createUserAndSetRole" ref="form">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="email"
                          label="Email"
                          autocomplete="off"
                          :rules="emailRules"
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="firstName"
                          label="First Name"
                          :rules="nameRules"
                          autocomplete="off"
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="lastName"
                          label="Last Name"
                          :rules="nameRules"
                          autocomplete="off"
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          v-model="role"
                          :items="['Admin', 'Manager', 'Consultant']"
                          label="Role"
                          :rules="nameRules"
                          autocomplete="off"
                          required
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-btn outlined color="primary" type="submit">
                          Create
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            class="ml-2"
                            v-if="loading"
                          ></v-progress-circular>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-card elevation="0" class="pa-2 mt-2"> </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import firebase from "firebase/app";
import "firebase/functions";
export default {
  name: "Settings",
  data: () => ({
    email: null,
    role: null,
    firstName: null,
    lastName: null,
    nameRules: [(v) => !!v || "Required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    loading: false,
  }),
  methods: {
    async createUserAndSetRole() {
      let password = process.env.VUE_APP_DEFAULT_PASSWORD_STUDENT;
      if (this.email && this.role && this.firstName && this.lastName) {
        this.loading = true;
        var addUserSetRole = firebase
          .functions()
          .httpsCallable("createUserAndSetRole");
        var data = {
          email: this.email,
          password: password,
          firstName: this.firstName,
          lastName: this.lastName,
          role: this.role,
          address: "",
          mobile: "",
          birthday: "",
        };
        addUserSetRole(data)
          .then(function(result) {
            console.log(result);
          })
          .catch(function(error) {
            console.log(error);
          });
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      } else {
        alert("You must enter all required fields.");
      }
      this.email = null;
      this.firstName = null;
      this.lastName = null;
      this.role = null;
      this.$refs.form.resetValidation();
    },
  },
};
</script>

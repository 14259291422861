<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between">
        <span class="text-h5 font-weight-light">Message Board</span>
        <v-btn outlined color="primary" right @click="dialog = true">
          Create a message
        </v-btn>
        <v-dialog v-model="dialog" persistent max-width="600px"
          ><v-card>
            <v-form @submit.prevent="createMessage" id="class-form" ref="form">
              <v-card-title>
                <span class="headline">Create a message</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      accept="image/*"
                      show-size
                      label="Upload an image"
                      @change="onFileSelected"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="details"
                      label="Anything to share ?"
                      outlined
                      required
                      autocomplete="off"
                      clearable
                      clear-icon="mdi-close-circle"
                      dense
                      :rules="nameRules"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="blue darken-1"
                  @click="dialog = false"
                  class="mr-3"
                >
                  Close
                </v-btn>
                <v-btn
                  outlined
                  type="submit"
                  color="primary"
                  @click="dialog = false"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="replyDialog" persistent max-width="600px"
          ><v-card>
            <v-form @submit.prevent="replyMessage">
              <v-card-title>
                <span class="headline">Leave a comment</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="reply"
                      label="Leave anything you want ..."
                      outlined
                      required
                      autocomplete="off"
                      clearable
                      clear-icon="mdi-close-circle"
                      dense
                      :rules="nameRules"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="blue darken-1"
                  @click="replyDialog = false"
                  class="mr-3"
                >
                  Close
                </v-btn>
                <v-btn
                  outlined
                  type="submit"
                  color="primary"
                  @click="replyDialog = false"
                >
                  Reply
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col
        cols="12"
        v-for="(message, i) in messages"
        :key="message.id + 'name' + i"
      >
        <v-card class="rounded-lg" outlined>
          <v-row style="position: relative">
            <v-btn
              @click="deleteMessage(message.id)"
              icon
              style="position: absolute; top: 16px; right: 16px"
              v-if="message.profileID === profile.docID"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-col cols="12" lg="3" md="4" sm="5">
              <v-card-title>
                <v-avatar
                  color="primary"
                  v-if="message.avatarURL == ''"
                  size="70"
                >
                  <v-icon dark> mdi-account-circle </v-icon>
                </v-avatar>
                <v-avatar v-else size="70">
                  <img
                    :src="message.avatarURL"
                    alt=""
                    style="object-fit: cover"
                  />
                </v-avatar>
                <div class="d-flex flex-column ml-3">
                  <span class="text-body-1 font-weight-medium">{{
                    message.creator
                  }}</span>
                  <span class="text-body-2 font-weight-light"
                    >{{ message.comments.length }} Comments</span
                  >
                  <span class="text-body-2 font-weight-light"
                    >{{ message.likes }} Likes</span
                  >
                </div>
              </v-card-title>
            </v-col>
            <v-col cols="12" lg="9" md="8" sm="7">
              <v-card-text class="d-flex flex-column">
                <span class="text-subtitle-1">{{ message.details }}</span>
                <span class="text-body-2 font-weight-light mt-2"
                  >Updated at {{ message.updateTime }}</span
                >
                <img
                  :src="message.messageImgURL"
                  alt=""
                  v-if="message.messageImgURL != ''"
                  class="mt-4"
                  style="max-width: 300px; max-height: 300px; object-fit: cover"
                />
              </v-card-text>
              <v-card-actions class="mb-4">
                <v-btn
                  color="primary lighten-2"
                  outlined
                  class="ml-2 mr-4"
                  @click="replyBtnClicked(message)"
                >
                  Reply
                </v-btn>
                <v-btn icon @click="like(message)">
                  <v-icon v-if="message.likeClicked" color="pink"
                    >mdi-heart</v-icon
                  >
                  <v-icon v-else>mdi-heart</v-icon>
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn
                  icon
                  @click="message.show = !message.show"
                  v-if="message.comments.length > 0"
                >
                  <v-icon>{{
                    message.show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="message.show">
                  <div
                    v-for="(comment, j) in message.comments.slice().reverse()"
                    :key="message.id + 'name' + j"
                  >
                    <v-divider class="mr-3"></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          lg="3"
                          md="4"
                          sm="5"
                          class="d-flex flex-column"
                        >
                          <v-avatar
                            color="primary"
                            v-if="comment.avatarURL == ''"
                            size="35"
                          >
                            <v-icon dark> mdi-account-circle </v-icon>
                          </v-avatar>
                          <v-avatar v-else size="35">
                            <img
                              :src="comment.avatarURL"
                              alt=""
                              style="object-fit: cover"
                            />
                          </v-avatar>
                          <span class="text-body-2 font-weight-medium">{{
                            comment.replier
                          }}</span>
                        </v-col>
                        <v-col
                          cols="12"
                          lg="9"
                          md="8"
                          sm="7"
                          class="d-flex flex-column"
                        >
                          <span class="text-subtitle-1">{{
                            comment.reply
                          }}</span>
                          <span class="text-body-2 font-weight-light mt-2">{{
                            comment.createTime.substr(0, 15)
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                </div>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import { db } from "../plugins/firebase.js";
export default {
  name: "Dashboard",
  data: () => ({
    username: "Admin",
    profile: {
      firstName: null,
      address: null,
      mobile: null,
      lastName: null,
      role: null,
      birthday: null,
      avatarURL: null,
      docID: null,
    },
    messages: [],
    currentMessage: null,
    dialog: false,
    details: "",
    replyDialog: false,
    reply: "",
    nameRules: [(v) => !!v || "Required"],
    selectedFile: null,
  }),
  created() {
    this.getProfile();
    this.getMessages();
  },
  methods: {
    async getProfile() {
      const user = firebase.auth().currentUser;
      let docRef = await db.collection("profiles").doc(user.uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.profile = doc.data();
            this.profile.docID = doc.id;
            // console.log("profile data:", this.profile);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    async getMessages() {
      let docRef = await db
        .collection("messages")
        .orderBy("updateTime", "desc")
        .limit(10)
        .get();
      let messageList = [];
      docRef.forEach((doc) => {
        let message = {};
        message.id = doc.id;
        message.profileID = doc.data().profileID;
        message.creator = doc.data().creator;
        message.avatarURL = doc.data().avatarURL;
        message.details = doc.data().details;
        message.likes = doc.data().likes;
        message.show = false;
        message.likeClicked = false;
        if (doc.data().comments) {
          message.comments = doc.data().comments;
        } else {
          message.comments = [];
        }
        if (doc.data().messageImgURL) {
          message.messageImgURL = doc.data().messageImgURL;
        } else {
          message.messageImgURL = "";
        }
        message.createTime = doc.data().createTime.substr(0, 15);
        message.updateTime = doc.data().updateTime.substr(0, 15);
        messageList.push(message);
      });
      this.messages = messageList;
      // console.log("all messages: ", this.messages);
    },
    async like(message) {
      message.likeClicked = !message.likeClicked;
      if (message.likeClicked) {
        await db
          .collection("messages")
          .doc(message.id)
          .update({
            likes: message.likes + 1,
            updateTime: new Date().toString(),
          });
      } else {
        await db
          .collection("messages")
          .doc(message.id)
          .update({
            likes: message.likes - 1,
            updateTime: new Date().toString(),
          });
      }
      this.getMessages();
    },
    async onUpload(id) {
      const storageRef = firebase
        .storage()
        .ref("/messages/" + this.selectedFile.name)
        .put(this.selectedFile);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(async (url) => {
            await db.collection("messages").doc(id).update({
              messageImgURL: url,
            });
            this.getMessages();
          });
        }
      );
    },
    async createMessage() {
      let creator = this.profile.firstName + " " + this.profile.lastName;
      if (this.details) {
        let messageDoc = await db.collection("messages").add({
          creator: creator,
          comments: [],
          details: this.details,
          avatarURL: this.profile.avatarURL,
          createTime: new Date().toString(),
          likes: 0,
          profileID: this.profile.docID,
          updateTime: new Date().toString(),
          messageImgURL: "",
        });
        if (this.selectedFile) {
          await this.onUpload(messageDoc.id);
        }
      } else {
        alert("Message cannot be empty");
      }
      this.$refs.form.reset();
      this.getMessages();
    },
    replyBtnClicked(message) {
      this.replyDialog = true;
      this.currentMessage = message;
    },
    async replyMessage() {
      // console.log("message reply ", this.currentMessage);
      // console.log("reply ", this.reply);
      let commentsArray = this.currentMessage.comments;
      let replier = this.profile.firstName + " " + this.profile.lastName;
      if (this.reply) {
        let commentObj = {
          avatarURL: this.profile.avatarURL,
          replier: replier,
          createTime: new Date().toString(),
          reply: this.reply,
        };
        commentsArray.push(commentObj);
        await db.collection("messages").doc(this.currentMessage.id).update({
          comments: commentsArray,
          updateTime: new Date().toString(),
        });
      } else {
        alert("You cannot reply with empty message.");
      }
      this.currentMessage = null;
      this.reply = "";
      this.getMessages();
    },
    async deleteMessage(id) {
      // console.log("message id ", id);
      await db.collection("messages").doc(id).delete();
      // console.log(id, "deleted");
      this.getMessages();
    },
    onFileSelected(event) {
      this.selectedFile = event;
      // console.log(this.selectedFile);
    },
  },
};
</script>
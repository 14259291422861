<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3" md="4" sm="12" class="d-flex flex-column">
        <v-card elevation="0" class="pa-2">
          <v-card-title class="d-flex justify-space-around">
            <v-progress-circular
              :rotate="-90"
              :size="250"
              :width="20"
              :value="value"
              color="primary"
            >
              <span class="primary--text text-h4 font-weight-medium">{{
                "$" + formatPrice(value)
              }}</span>
            </v-progress-circular>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <div class="d-flex justify-center mt-3">
              <v-btn
                outlined
                block
                color="primary"
                class="mt-4"
                @click="addSalary"
                v-if="role == 'Admin'"
              >
                Add Salary
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="ml-2"
                  v-if="loading2"
                ></v-progress-circular>
              </v-btn>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                outlined
                block
                color="primary"
                class="mt-4"
                @click="chargeSalary"
                v-if="role == 'Admin'"
              >
                Charge Salary
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="ml-2"
                  v-if="loading2"
                ></v-progress-circular>
              </v-btn>
            </div>
            <div class="d-flex justify-center">
              <v-btn
                outlined
                block
                color="primary"
                class="mt-4"
                @click="resetSalary"
                v-if="role == 'Admin'"
              >
                Reset
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="9" md="8" sm="12">
        <v-tabs
          fixed-tabs
          color="primary"
          next-icon="mdi-arrow-right-bold-box-outline"
          show-arrows
        >
          <v-tab> Profile </v-tab>
          <v-tab> Class Records </v-tab>
          <v-tab v-if="role == 'Admin' || role == 'Instructor'">
            Balance Records
          </v-tab>
          <v-tab-item>
            <v-card elevation="0" class="pa-2 mt-2">
              <v-form @submit.prevent="updateInstructorInfo">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          readonly
                          v-model="instructor.instructorName"
                          label="Full Name"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="instructor.englishName"
                          label="English Name"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="instructor.phone"
                          label="Phone Number"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="birthDatePicker"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="instructor.birthday"
                              label="Date of Birth"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              dense
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="instructor.birthday"
                            @input="birthDatePicker = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        v-if="role == 'Admin' || role == 'Instructor'"
                      >
                        <v-text-field
                          v-model="instructor.address"
                          label="Address"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          readonly
                          v-model="instructor.email"
                          label="E-mail"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        v-if="role == 'Admin' || role == 'Instructor'"
                      >
                        <v-text-field
                          v-model="instructor.defaultRate"
                          label="Default Rate"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        v-if="role == 'Admin' || role == 'Instructor'"
                      >
                        <v-text-field
                          v-model="instructor.smallGroup"
                          label="Small Group"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        v-if="role == 'Admin' || role == 'Instructor'"
                      >
                        <v-text-field
                          v-model="instructor.largeGroup"
                          label="Large Group"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        v-if="role == 'Admin' || role == 'Instructor'"
                      >
                        <v-text-field
                          v-model="instructor.sin"
                          label="Social Insurance Number"
                          outlined
                          required
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-autocomplete
                          v-model="instructor.subjects"
                          outlined
                          :items="[
                            'Applied Design, Skills, and Technologies',
                            'Arts Education',
                            'Career Education',
                            'English Language Arts',
                            'Français langue première',
                            'French Immersion Language Arts',
                            'Mathematics',
                            'Physical and Health Education',
                            'Science',
                            'Social Studies',
                            'Languages',
                            'ESL',
                            'Other',
                          ]"
                          label="Subjects"
                          multiple
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    color="primary"
                    type="submit"
                    x-large
                    class="mr-5 mb-10"
                    :disabled="role != 'Admin'"
                  >
                    Update Profile
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      class="ml-2"
                      v-if="loading1"
                    ></v-progress-circular>
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="pa-4">
              <v-card-title class="d-flex align-center justify-center">
                <v-row class="d-flex align-center">
                  <v-col cols="12" md="8" sm="8">
                    <v-btn
                      outlined
                      color="primary"
                      @click="generateClassRecordsPDF"
                    >
                      Print Monthly Class Records
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" sm="4">
                    <v-text-field
                      v-model="searchClass"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      class="mt-0 pa-0"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                :headers="instructorClassHeaders"
                :items="instructorClassRecords"
                :search="searchClass"
                item-key="createTime"
              ></v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="pa-4">
              <v-card-title class="d-flex align-center justify-center">
                <v-row class="d-flex align-center">
                  <v-col cols="12" md="8" sm="9">
                    <v-btn
                      outlined
                      color="primary"
                      @click="generateBalanceRecordsPDF"
                      style="max-width: 290px"
                    >
                      Print Monthly Balance Records
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" sm="3">
                    <v-text-field
                      v-model="searchBalance"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      class="mt-0 pa-0"
                      style="max-width: 290px"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                :headers="instructorBalanceHeaders"
                :items="instructorBalanceRecords"
                :search="searchBalance"
                item-key="createTime"
              ></v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialogAddBalance"
          persistent
          max-width="400px"
          max-height="600px"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogAddBalance = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Add Salary</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="addSalaryConfirm">Save</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <template>
              <v-form v-model="addForm">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="amountAdd"
                        outlined
                        label="Total Amount to Add"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="detailAdd"
                        outlined
                        label="Detail"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-select
                        v-model="paymentMethod"
                        outlined
                        :items="['T4', 'T4A', 'Other']"
                        label="Payment Method"
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialogChargeBalance"
          persistent
          max-width="400px"
          max-height="600px"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogChargeBalance = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Charge Salary</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="chargeSalaryConfirm">Save</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <template>
              <v-form v-model="chargeForm">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="amountCharge"
                        outlined
                        label="Total Amount to Charge"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="detailCharge"
                        outlined
                        label="Detail"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialogResetBalance"
          persistent
          max-width="400px"
          max-height="600px"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="dialogResetBalance = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Reset Salary</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="resetSalaryConfirm">Save</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <template>
              <v-form v-model="resetForm">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-model="amountReset"
                        outlined
                        label="Total Amount to Reset"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-card
      id="classRecordsAndBalanceRecords"
      style="display: none;"
      min-height="1000"
    >
      <v-card-title class="d-flex flex-column align-start ml-4">
        <span class="headline text-subtitle-1 font-weight-light">{{
          heading.title
        }}</span>
        <span class="headline text-subtitle-1 font-weight-light">{{
          heading.studentName
        }}</span>
        <span class="headline text-subtitle-1 font-weight-light">{{
          heading.date
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="mb-10" style="min-height: 500px">
            <v-col cols="12">
              <v-data-table
                :headers="itemHeaders"
                :items="itemsToBePrinted"
                hide-default-footer
              ></v-data-table>
              <span
                class="headline text-h5 font-weight-light"
                v-if="monthlyBalanceTotal"
                >Total: {{ monthlyBalanceTotal }}</span
              >
            </v-col>
          </v-row>
          <v-row class="mt-10">
            <v-spacer></v-spacer>
            <v-col>
              <img
                src="../assets/logo-horizontal.png"
                alt=""
                width="200"
                height="75"
              />
            </v-col>
          </v-row>
          <v-row class="mt-10">
            <v-spacer></v-spacer>
            <v-col class="d-flex flex-column flex-end justify-end">
              <!-- <span class="text-subtitle-1 font-weight-light"
                >LEVEL UP Learning Centre</span
              > -->
              <span class="text-subtitle-1 font-weight-light"
                >Head Office: 2390-4000 No.3 Rd, Richmond, V6X0J8</span
              >
              <span class="text-subtitle-1 font-weight-light"
                >Learning Centre: 5070-4000 No.3 Rd, Richmond V6X0J8</span
              >
              <span class="text-subtitle-1 font-weight-light"
                >Phone: 778-737-7591</span
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import html2PDF from "jspdf-html2canvas";
import { db } from "../plugins/firebase.js";
import "firebase/storage";
export default {
  name: "InstructorPage",
  data: () => ({
    role: null,
    instructorDocID: null,
    expDatePicker: false,
    birthDatePicker: false,
    dialogAddBalance: false,
    dialogChargeBalance: false,
    dialogResetBalance: false,
    searchClass: "",
    searchBalance: "",
    addForm: false,
    chargeForm: false,
    resetForm: false,
    instructor: {
      address: "",
      birthday: "",
      degree: "",
      email: "",
      englishName: "",
      // hourFee: 0,
      defaultRate: 0,
      smallGroup: 0,
      largeGroup: 0,
      classRecords: [],
      instructorName: "",
      phone: "",
      salary: 0,
      school: "",
      sin: "",
      subjects: [],
    },

    instructorClassRecords: [],
    instructorClassRecordsByMonth: [],
    instructorBalanceRecords: [],
    instructorBalanceRecordsByMonth: [],
    amountAdd: "0.00",
    detailAdd: "",
    paymentMethod: "",
    amountCharge: "0.00",
    amountReset: "0.00",
    detailCharge: "",
    chargeMethod: "N/A",
    creatorName: "unknown",

    instructorClassHeaders: [
      {
        text: "Class Name",
        align: "start",
        value: "className",
      },
      { text: "Date", value: "startTime" },
      { text: "Hours", value: "duration" },
    ],
    balanceHeaders: [
      {
        text: "Amount",
        align: "start",
        value: "amount",
      },
      { text: "Date", value: "createdTime" },
      { text: "Detail", value: "detail" },
      { text: "Payment Method", value: "paymentMethod" },
      { text: "Creator", value: "createdBy" },
    ],
    instructorBalanceHeaders: [
      {
        text: "Amount",
        align: "start",
        value: "amount",
      },
      { text: "Date", value: "createTime" },
      { text: "Detail", value: "detail" },
      { text: "Creator", value: "createdBy" },
    ],
    profile: {
      firstName: null,
      address: null,
      mobile: null,
      lastName: null,
      role: null,
      birthday: null,
      avatarURL: null,
    },
    loading1: false,
    loading2: false,
    selectedFile: null,
    uploading: false,
    interval: {},
    value: 0,
    heading: {
      title: null,
      studentName: null,
      date: null,
    },
    itemHeaders: [],
    itemsToBePrinted: [],
    monthlyBalanceTotal: null,
  }),
  created() {
    this.instructorDocID = this.$route.query.id;
    this.getInstructor();
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value >= this.instructor.salary) {
        this.value = this.instructor.salary;
      } else {
        this.value += this.instructor.salary / 18;
      }
    }, 10);
  },
  methods: {
    async getInstructor() {
      let currentUser = firebase.auth().currentUser;
      let profile = db.collection("profiles").doc(currentUser.uid);
      await profile.get().then((doc) => {
        this.role = doc.data().role;
      });
      const instructorDB = db
        .collection("instructors")
        .doc(this.instructorDocID);
      instructorDB
        .get()
        .then((instructor) => {
          if (instructor.exists) {
            this.instructor = instructor.data();
            this.instructorClassRecords = instructor.data().classRecords;
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
      const instructorBalanceRecordsDB = db
        .collection("instructorBalanceRecords")
        .doc(this.instructorDocID);
      instructorBalanceRecordsDB
        .get()
        .then((balanceRecords) => {
          if (
            balanceRecords.exists &&
            balanceRecords.data().instructorBalanceRecords
          ) {
            this.instructorBalanceRecords = balanceRecords.data().instructorBalanceRecords;
            this.instructorBalanceRecords.forEach(
              (singleInstructorBalanceRecord) => {
                singleInstructorBalanceRecord.createTime = singleInstructorBalanceRecord.createTime.substring(
                  0,
                  24
                );
              }
            );
          } else {
            console.log("No Instructor Balance data!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },

    async updateInstructorInfo() {
      this.loading1 = true;
      await db
        .collection("instructors")
        .doc(this.instructorDocID)
        .update(this.instructor);
      let name = this.instructor.instructorName.split(" ");
      let instructorFirstname = name[0];
      let instructorLastname = name[1];
      await db
        .collection("profiles")
        .doc(this.instructorDocID)
        .update({
          firstName: instructorFirstname,
          address: this.instructor.address,
          mobile: this.instructor.phone,
          lastName: instructorLastname,
          birthday: this.instructor.birthday,
        });
      this.getInstructor();
      setTimeout(() => {
        this.loading1 = false;
      }, 1500);
    },

    addSalary() {
      this.dialogAddBalance = true;
    },

    chargeSalary() {
      this.dialogChargeBalance = true;
    },
    resetSalary() {
      this.dialogResetBalance = true;
    },

    async addSalaryConfirm() {
      const amountAdd = Number(this.amountAdd);
      const user = firebase.auth().currentUser;
      var uid = user.uid;
      const profileDB = db.collection("profiles").doc(uid);
      await profileDB
        .get()
        .then((profile) => {
          if (profile.exists) {
            this.creatorName =
              profile.data().firstName + " " + profile.data().lastName;
          } else {
            console.log("No such profile!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
      let newBalanceRecord = {
        instructorName: this.instructor.instructorName,
        amount: amountAdd,
        detail: this.detailAdd,
        paymentMethod: this.paymentMethod,
        createdBy: this.creatorName,
        createTime: new Date().toString(),
        classDocID: "N/A",
      };

      this.instructorBalanceRecords.push(newBalanceRecord);
      await db
        .collection("instructors")
        .doc(this.instructorDocID)
        .update({
          salary: this.instructor.salary + amountAdd,
        });
      await db
        .collection("instructorBalanceRecords")
        .doc(this.instructorDocID)
        .update({
          instructorBalanceRecords: this.instructorBalanceRecords,
        });
      this.closeAddBalance();
      this.getInstructor();
    },

    async chargeSalaryConfirm() {
      const amountCharge = Number(this.amountCharge);
      const user = firebase.auth().currentUser;
      var uid = user.uid;
      const profileDB = db.collection("profiles").doc(uid);
      await profileDB
        .get()
        .then((profile) => {
          if (profile.exists) {
            this.creatorName =
              profile.data().firstName + " " + profile.data().lastName;
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
      let newBalanceRecord = {
        instructorName: this.instructor.instructorName,
        amount: -Math.abs(amountCharge),
        detail: this.detailCharge,
        paymentMethod: this.chargeMethod,
        createdBy: this.creatorName,
        createTime: new Date().toString(),
        classDocID: "N/A",
      };

      this.instructorBalanceRecords.push(newBalanceRecord);
      await db
        .collection("instructors")
        .doc(this.instructorDocID)
        .update({
          salary: this.instructor.salary - amountCharge,
        });
      await db
        .collection("instructorBalanceRecords")
        .doc(this.instructorDocID)
        .update({
          instructorBalanceRecords: this.instructorBalanceRecords,
        });
      this.closeChargeBalance();
      this.getInstructor();
    },

    async resetSalaryConfirm() {
      const amountReset = Number(this.amountReset);
      await db
        .collection("instructors")
        .doc(this.instructorDocID)
        .update({
          salary: this.instructor.salary - amountReset,
        });
      this.closeResetBalance();
      this.getInstructor();
    },

    filterBalanceRecordsByMonth() {
      let currentMonth = new Date().getMonth();
      let instructorBalanceRecordsByMonth = this.instructorBalanceRecords.filter(
        (balanceRecord) => {
          let recordMonth = new Date(balanceRecord.createTime).getMonth();
          return currentMonth == recordMonth;
        }
      );
      this.instructorBalanceRecordsByMonth = instructorBalanceRecordsByMonth;
    },

    filterClassRecordsByMonth() {
      let currentMonth = new Date().getMonth();
      let instructorClassRecordsByMonth = this.instructorClassRecords.filter(
        (classRecord) => {
          let recordMonth = new Date(classRecord.startTime).getMonth();
          return currentMonth == recordMonth;
        }
      );
      this.instructorClassRecordsByMonth = instructorClassRecordsByMonth;
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    closeAddBalance() {
      this.dialogAddBalance = false;
      this.getInstructor();
    },
    closeChargeBalance() {
      this.dialogChargeBalance = false;
      this.getInstructor();
    },
    closeResetBalance() {
      this.dialogResetBalance = false;
      this.getInstructor();
    },
    onFileSelected(event) {
      this.selectedFile = event;
    },
    onUpload() {
      this.uploading = true;
      const storageRef = firebase
        .storage()
        .ref("/avatars/" + this.selectedFile.name)
        .put(this.selectedFile);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.profile.avatarURL = url;
            console.log(this.profile.avatarURL);
            const user = firebase.auth().currentUser;
            db.collection("profiles")
              .doc(user.uid)
              .update({
                avatarURL: url,
              });
          });
        }
      );
      this.getProfile();
      this.selectedFile = null;
      this.uploading = false;
    },
    async generateClassRecordsPDF() {
      await this.renderMonthlyClassRecords();
      let classRecordsCard = document.querySelector(
        "#classRecordsAndBalanceRecords"
      );
      classRecordsCard.style.display = "block";
      await html2PDF(classRecordsCard, {
        jsPDF: {
          format: "a4",
        },
        html2canvas: {
          scrollX: 0,
          scrollY: -window.scrollY,
        },
        imageType: "image/jpeg",
        output: "monthly-instructor-class-schedule.pdf",
      });
      classRecordsCard.style.display = "none";
      this.heading = {
        title: null,
        studentName: null,
        date: null,
      };
      this.itemHeaders = [];
      this.itemsToBePrinted = [];
      this.monthlyBalanceTotal = null;
    },
    async renderMonthlyClassRecords() {
      this.filterClassRecordsByMonth();
      // console.log("month ", this.instructorClassRecordsByMonth);
      this.itemsToBePrinted = this.instructorClassRecordsByMonth;
      let date = new Date().toString().substr(0, 15);
      this.heading = {
        title: "Monthly Instructor Class Records",
        studentName: this.instructor.instructorName,
        date: date,
      };
      this.itemHeaders = [
        {
          text: "Class Name",
          align: "start",
          value: "className",
          class: "primary white--text",
        },
        { text: "Date", value: "startTime", class: "primary white--text" },
        { text: "Hours", value: "duration", class: "primary white--text" },
      ];
    },
    async renderMonthlyBalanceRecords() {
      this.filterBalanceRecordsByMonth();
      let total = 0;
      this.instructorBalanceRecordsByMonth.forEach(
        (instructorBalanceRecord) => {
          total += instructorBalanceRecord.amount;
        }
      );
      this.monthlyBalanceTotal = total;
      this.itemsToBePrinted = this.instructorBalanceRecordsByMonth;
      let date = new Date().toString().substr(0, 15);
      this.heading = {
        title: "Monthly Instructor Balance Records",
        studentName: this.instructor.instructorName,
        date: date,
      };
      this.itemHeaders = [
        {
          text: "Amount",
          align: "start",
          value: "amount",
          class: "primary white--text",
        },
        { text: "Date", value: "createTime", class: "primary white--text" },
        { text: "Detail", value: "detail", class: "primary white--text" },
        {
          text: "Payment Method",
          value: "paymentMethod",
          class: "primary white--text",
        },
        { text: "Creator", value: "createdBy", class: "primary white--text" },
      ];
    },
    async generateBalanceRecordsPDF() {
      await this.renderMonthlyBalanceRecords();
      let balanceRecordsCard = document.querySelector(
        "#classRecordsAndBalanceRecords"
      );
      balanceRecordsCard.style.display = "block";
      await html2PDF(balanceRecordsCard, {
        jsPDF: {
          format: "a4",
        },
        html2canvas: {
          scrollX: 0,
          scrollY: -window.scrollY,
        },
        imageType: "image/jpeg",
        output: "monthly-instructor-balance-records.pdf",
      });
      balanceRecordsCard.style.display = "none";
      this.heading = {
        title: null,
        studentName: null,
        date: null,
      };
      this.itemHeaders = [];
      this.itemsToBePrinted = [];
      this.monthlyBalanceTotal = null;
    },
  },
};
</script>
<style scoped>
.vs-avatar img {
  height: 100%;
}
</style>

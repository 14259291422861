<template>
  <v-container>
    <v-row>
      <v-expansion-panels class="pa-2">
        <v-expansion-panel style="border-top: #6c5dd3 2px solid">
          <v-expansion-panel-header>
            <span class="text-h6 font-weight-medium">
              Unfold registered students table to assign them into groups
            </span>
            <template v-slot:actions>
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card elevation="0">
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search student"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="students"
                :single-select="singleSelect"
                :search="search"
                item-key="name"
                show-select
                class="elevation-0 mb-3"
                :items-per-page="5"
              >
                <template v-slot:top>
                  <v-switch
                    v-model="singleSelect"
                    label="Single select"
                    class="pa-3"
                  ></v-switch>
                </template>
              </v-data-table>
              <v-form
                ref="form"
                @submit.prevent="createGroup"
                class="d-flex flex-row justify-center pa-4 ma-4"
                v-if="selected.length != 0"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="groupName"
                  :rules="nameRules"
                  label="Group Name"
                  placeholder="Enter group name"
                  required
                  dense
                ></v-text-field>

                <v-btn outlined color="primary" class="ml-4" type="submit">
                  Create a group
                </v-btn>
              </v-form>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="12"
        v-for="(group, i) in groups"
        :key="group.docID + 'name' + i"
        class="mb-3"
      >
        <v-expansion-panels
          focusable
          v-model="panel"
          multiple
          class="classroom"
        >
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate>
              <template v-slot:actions>
                <v-btn @click="deleteGroup(groups[i].docID)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span class="text-h6">
                {{ group.name }}
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="mt-3 mb-3 overflow-y-auto"
              style="max-height: 160px"
              v-scroll.self="onScroll"
            >
              <div
                v-for="(student, j) in group.students"
                :key="student.docID + 'name' + j"
                class="d-flex flex-row align-center justify-space-between"
              >
                <v-col cols="6">
                  <span class="indigo--text text--darken-4">{{
                    group.students[j].name
                  }}</span>
                </v-col>
                <v-col cols="4" v-if="currentlyEditing !== group.docID">
                  <span class="indigo--text text--darken-4">{{
                    group.students[j].hourFee
                  }}</span>
                </v-col>
                <v-col cols="4" v-else>
                  <v-text-field
                    v-model="group.students[j].hourFee"
                    label="Hour Fee"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    @click="deleteStudent(group, group.students[j].docID)"
                    fab
                    outlined
                    x-small
                    elevation="2"
                    color="primary"
                    right
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
              </div>
              <div
                class="d-flex flex-row justify-space-between align-center mb-2"
              >
                <v-btn
                  color="primary"
                  class="mt-3 mb-3"
                  @click="addStudentsToGroup(group)"
                  v-if="selected.length != 0"
                >
                  Add students
                </v-btn>
                <v-btn
                  color="secondary"
                  v-if="currentlyEditing === group.docID"
                  @click="cancel()"
                >
                  Cancel
                </v-btn>
                <v-btn
                  v-if="currentlyEditing !== group.docID"
                  @click.prevent="editClassroom(group)"
                >
                  Edit
                </v-btn>
                <v-btn v-else @click.prevent="updateClassroom(group)">
                  Save
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "../plugins/firebase.js";
export default {
  name: "Classrooms",
  data() {
    return {
      search: "",
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "Student Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        // { text: "Doc ID", value: "docID" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone" },
        // { text: "Hour Fee", value: "hourFee" },
      ],
      students: [],
      valid: false,
      groupName: "",
      nameRules: [(v) => !!v || "Required"],
      groups: [],
      panel: [],
      offsetTop: 0,
      currentlyEditing: null,
    };
  },
  created() {
    this.getStudents();
    this.getClassrooms();
  },
  methods: {
    async getStudents() {
      let snapshot = await db.collection("students").get();
      let studentsFromDB = [];
      snapshot.forEach((doc) => {
        if (doc.data().registrationStatus) {
          let student = {};
          student.docID = doc.id;
          student.name = doc.data().studentName;
          student.email = doc.data().email;
          student.balance = doc.data().balance;
          student.phone = doc.data().phone;
          student.hourFee = doc.data().hourFee;
          studentsFromDB.push(student);
        }
      });
      this.students = studentsFromDB;
      // console.log("students:", this.students);
    },
    async createGroup() {
      this.$refs.form.validate();
      if (this.groupName != "") {
        // console.log(this.selected);
        await db.collection("classrooms").add({
          groupName: this.groupName,
          students: this.selected,
          createTime: new Date().toString(),
        });
      }
      this.$refs.form.reset();
      this.groupName = "";
      this.getClassrooms();
    },
    async getClassrooms() {
      let snapshot = await db.collection("classrooms").get();
      let groupsArray = [];
      let panelArray = [];
      let count = 0;
      snapshot.forEach((doc) => {
        let group = {};
        group.docID = doc.id;
        group.name = doc.data().groupName;
        // student.englishName = doc.data().englishName;
        group.students = doc.data().students;
        // student.balance = doc.data().balance;
        groupsArray.push(group);
        panelArray.push(count);
        count++;
        // this.groups.push(group);
      });
      // console.log("groupsArray", groupsArray);
      this.groups = groupsArray;
      this.panel = panelArray;
      // console.log(this.panel);
    },
    async deleteGroup(groupID) {
      await db.collection("classrooms").doc(groupID).delete();
      // console.log(groupID, "deleted");
      this.getClassrooms();
    },
    async deleteStudent(group, studentID) {
      // console.log(group);
      if (group) {
        let filteredStudents = group.students.filter((student) => {
          return student.docID != studentID;
        });
        // console.log(filteredStudents);
        await db.collection("classrooms").doc(group.docID).update({
          students: filteredStudents,
        });
      }
      this.getClassrooms();
    },
    async addStudentsToGroup(group) {
      // console.log("target group ", group);
      // console.log("selected student", this.selected);
      let targetStudents = group.students;
      if (this.selected.length != 0) {
        for (var i = 0; i < this.selected.length; i++) {
          var selectedStudent = this.selected[i];
          var targetStudent = targetStudents.find((obj) => {
            return obj.docID === selectedStudent.docID;
          });
          // console.log("target student ", targetStudent);
          if (!targetStudent) {
            targetStudents.push(selectedStudent);
            continue;
          } else {
            alert(
              targetStudent.name +
                " is already inside " +
                group.name +
                "\nSame student will not be added twice."
            );
            continue;
          }
        }
        // console.log("targetStudents ", targetStudents);
        await db.collection("classrooms").doc(group.docID).update({
          students: targetStudents,
        });
        this.selected = [];
        this.getClassrooms();
      }
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    cancel() {
      this.currentlyEditing = null;
    },
    editClassroom(classroom) {
      // console.log("edit ", classroom);
      this.currentlyEditing = classroom.docID;
    },
    async updateClassroom(classroom) {
      // console.log("ev ", classroom);
      // console.log("current ", this.currentlyEditing);
      let studentsArray = classroom.students;
      await db.collection("classrooms").doc(this.currentlyEditing).update({
        students: studentsArray,
      });
      this.currentlyEditing = null;
      this.getClassrooms();
    },
  },
};
</script>
<style scoped>
.v-btn--fab.v-size--x-small {
  width: 24px;
  height: 24px;
}
.classroom {
  border-top: #6c5dd3 2px solid;
  /* border: #6c5dd3 2px solid; */
}
</style>

import firebase from 'firebase/app'
import router from '../../router/index.js'

const state = {
    user: null
};

const getters = {
    getUser(state) {
        return state.user;
    },
    isUserAuth(state) {
        return !!state.user;
    }
};

const actions = {
    authAction({ commit }) {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                commit("setUser", user);
            } else {
                commit("setUser", null);
            }
        });
    },
    signUpAction({ commit }, payload) {
        firebase
            .auth()
            .createUserWithEmailAndPassword(payload.email, payload.password)
            .then(response => {
                commit("setUser", response.user);
            })
            .catch(error => {
                console.log(error);
            });
    },
    signInAction({ commit }, payload) {
        // console.log("email: ", payload.email)
        firebase
            .auth()
            .signInWithEmailAndPassword(payload.email, payload.password)
            .then(response => {
                commit("setUser", response.user);
                if (router.currentRoute.path === '/login') {
                    router.replace({name: "dashboard"})
                }
            })
            .catch(error => {
                console.log(error);
                alert(error.message);
            });
    },
    signOutAction({ commit }) {
        firebase
            .auth()
            .signOut()
            .then(() => {
                commit("setUser", null);
            })
            .catch(error => {
                console.log(error);
            });
    }
};
const mutations = {
    setUser(state, payload) {
        state.user = payload;
    }
};


export default {
    state,
    actions,
    getters,
    mutations
};
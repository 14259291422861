<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3" md="4" sm="12">
        <v-card elevation="0" class="pa-2">
          <v-card-title class="d-flex justify-space-around">
            <div class="d-flex justify-center mb-3" v-if="uploading">
              <vs-avatar circle loading size="120" v-if="this.profile.avatarURL == ''">
                <img src="../assets/profile_avatar.png" alt="" />
              </vs-avatar>
              <vs-avatar circle loading size="120" v-else>
                <img :src="profile.avatarURL" alt="" style="object-fit: cover;"/>
              </vs-avatar>
            </div>
            <div class="d-flex justify-center mb-3" v-else>
              <vs-avatar circle size="120" v-if="this.profile.avatarURL == ''">
                <img src="../assets/profile_avatar.png" alt="" />
              </vs-avatar>
              <vs-avatar circle size="120" v-else>
                <img :src="profile.avatarURL" alt="" style="object-fit: cover;"/>
              </vs-avatar>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="d-lg-flex flex-row justify-center align-center mb-3">
              <v-file-input
                accept="image/*"
                prepend-icon="mdi-camera"
                show-size
                label="Change avatar"
                placeholder="Pick an image"
                @change="onFileSelected"
              >
              </v-file-input>
              <div v-if="this.selectedFile != null">
                <v-btn
                  outlined
                  color="primary"
                  class="ml-2"
                  @click.prevent="onUpload"
                  v-if="this.selectedFile != null"
                >
                  Upload
                </v-btn>
              </div>
            </div>
            <div class="d-flex justify-space-around">
              <v-btn
                outlined
                x-large
                color="primary"
                class="mt-2"
                @click="resetPassword"
              >
                Reset password
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="ml-2"
                  v-if="loading2"
                ></v-progress-circular>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="9" md="8" sm="12">
        <v-card elevation="0" class="pa-2">
          <v-form @submit.prevent="updateProfile">
            <v-card-title>
              <span class="headline text-h5 font-weight-medium"
                >My Profile</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row class="mt-4">
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="profile.firstName"
                      label="First name"
                      outlined
                      required
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="profile.lastName"
                      label="Last name"
                      outlined
                      required
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="profile.mobile"
                      label="Phone Number"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="profile.birthday"
                      label="Birthday"
                      hint="YYYY-MM-DD format"
                      persistent-hint
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      v-model="profile.address"
                      label="Address"
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined color="primary" type="submit" x-large>
                Update Profile
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="ml-2"
                  v-if="loading1"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import { db } from "../plugins/firebase.js";
import "firebase/storage";
export default {
  name: "Profile",
  data: () => ({
    profile: {
      firstName: null,
      address: null,
      mobile: null,
      lastName: null,
      role: null,
      birthday: null,
      avatarURL: null,
    },
    loading1: false,
    loading2: false,
    selectedFile: null,
    uploading: false,
  }),
  created() {
    this.getProfile();
  },
  methods: {
    async getProfile() {
      const user = firebase.auth().currentUser;
      let docRef = await db.collection("profiles").doc(user.uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.profile = doc.data();
            // console.log("Document data:", doc.data());
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    async updateProfile() {
      this.loading1 = true;
      const user = firebase.auth().currentUser;
      await db.collection("profiles").doc(user.uid).update({
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        address: this.profile.address,
        mobile: this.profile.mobile,
        birthday: this.profile.birthday,
      });
      this.getProfile();
      setTimeout(() => {
        this.loading1 = false;
      }, 1500);
    },
    onFileSelected(event) {
      this.selectedFile = event;
      // console.log(this.selectedFile);
    },
    onUpload() {
      this.uploading = true;
      const storageRef = firebase
        .storage()
        .ref("/avatars/" + this.selectedFile.name)
        .put(this.selectedFile);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.profile.avatarURL = url;
            // console.log(this.profile.avatarURL);
            const user = firebase.auth().currentUser;
            db.collection("profiles").doc(user.uid).update({
              avatarURL: url,
            });
          });
        }
      );
      this.getProfile();
      this.selectedFile = null;
      this.uploading = false;
    },
    async resetPassword() {
      this.loading2 = true;
      const user = firebase.auth().currentUser;
      // console.log(user.email);
      await firebase
        .auth()
        .sendPasswordResetEmail(user.email)
        .then(() => {
          // console.log("email sent");
          alert("The request of password reset is sent to " + user.email);
        })
        .catch((error) => {
          console.log(error);
        });
      setTimeout(() => {
        this.loading2 = false;
      }, 1000);
    },
  },
};
</script>
<style scoped>
.vs-avatar img {
  height: 100%;
}
</style>
